<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      label-width="100px"
      :rules="rules"
      style="width:100%;"
    >
      <el-divider content-position="left">投放点基本信息</el-divider>
      <el-form-item label="投放点名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入投放点名称"></el-input>
      </el-form-item>

      <el-form-item label="投放点详细地址" prop="address" required>
        <el-select
          style="width: 100%"
          v-model="keywordResult"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          @change="keywordChange"
          :remote-method="remoteMethod"
          :loading="keywordLoading"
        >
          <el-option
            v-for="(value,key) in keywordAddress"
            :key="key"
            :label="value.title"
            :value="key"
          ></el-option>
        </el-select>
        <div v-if="keywordProvince!==''">{{keywordProvince}}</div>
      </el-form-item>
      <el-form-item label="主负责人" prop="realname">
        <el-input v-model="form.realname" placeholder="请输入主负责人"></el-input>
      </el-form-item>
      <el-form-item label="市场负责人" prop="market" v-if="groupID<=1">
        <el-input v-model="form.market" placeholder="请输入市场负责人"></el-input>
      </el-form-item>
      <el-divider content-position="left">投放点其他信息</el-divider>
      <el-form-item label="联系方式" prop="phone" required>
        <el-input placeholder="请输入投放点电话" v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="绑定企业" prop="group_id" required v-if="groupID===1">
        <el-select v-model="form.group_id" placeholder="绑定企业" filterable clearable>
          <el-option label="请绑定企业" :value="0">请绑定企业</el-option>
          <el-option
            v-for="item in groupList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代理商" prop="agent_id">
        <el-select v-model="form.agent_id" filterable placeholder="请选择代理商" clearable @clear="handleAgentClear">
          <el-option label="请选择代理商" :value="0">请选择代理商</el-option>
          <el-option
            v-for="(value,key) in agents_select"
            :key="value.id"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单位" prop="unit" required>
        <el-select v-model="form.unit" clearable filterable placeholder="请选择单位">
          <el-option
            v-for="item in unitList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单价" prop="unit_price" required>
        <el-input v-model="form.unit_price" placeholder="请输入单价" @change="priceChange()">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="日封顶" prop="day_top">
        <el-input v-model="form.day_top">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <!-- <el-form-item label="初始总配宝数" prop="unit_price">
        <el-input v-model="form.unit_price" type="number" placeholder="请输入初始总配宝数">
          <template slot="append">个</template>
        </el-input>
      </el-form-item> -->
      <el-form-item label="投放点大小" prop="store_size">
        <el-select v-model="form.store_size" clearable filterable placeholder="请选择投放点大小">
          <el-option
            v-for="item in storeSizeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投放点类型" prop="store_type">
        <el-select v-model="form.store_type" clearable filterable placeholder="请选择投放点类型">
          <el-option
            v-for="(value,key) in typeOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="铺设类型" prop="lay_type">
        <el-select v-model="form.lay_type" clearable filterable placeholder="请选择铺设类型">
          <el-option
            v-for="item in layTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="营业时间" required>
        <el-time-select
          placeholder="起始时间"
          v-model="form.business_start_time"
          :picker-options="{
            start: '00:00',
            step: '00:30',
            end: '24:00'
          }"
        >
        </el-time-select>
        <span style="margin: 0 10px;">至</span>
        <el-time-select
          placeholder="结束时间"
          v-model="form.business_end_time"
          :picker-options="{
            start: '00:00',
            step: '00:30',
            end: '24:00'
          }"
        >
        </el-time-select>
      </el-form-item>

      <el-form-item label="上传投放点图片">
        <upload-qiniu @success="handlePicSuccess" @remove="handlePicRemove" @preview="handlePreview" :multiple="true" path="store" :num="9" :images="form.store_images"></upload-qiniu>
      </el-form-item>
      
      <el-form-item label="信用代码" prop="credit_code">
        <el-input v-model="form.credit_code" placeholder="请输入信用代码"></el-input>
      </el-form-item>
      <el-form-item label="上传投放点营业执照">
        <upload-qiniu @success="handleLicenseSuccess" @remove="handleLicenseRemove" :multiple="false" :images="form.license_image"></upload-qiniu>
      </el-form-item>

      
      <!-- <el-divider content-position="left">投放点服务信息</el-divider>
      <el-form-item>
        <el-button @click="innerVisible = true">添加服务信息</el-button>
        <div v-for="(value,key) in form.service_data" :key="key">
          <el-tag closable @close="removeServiceData(key)">{{value.service_ids_name[0]}} {{value.service_ids.length>1 ? '+'+value.service_ids.length : ''}} {{value.service_province_str+value.service_city_str+value.service_area_str}}</el-tag>
        </div>
      </el-form-item> -->
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="editData('form')" :plain="true">立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>

  let _this;
  import {getTypeSelect,getListById,editData} from "@/api/store.js";
  import {tencentAddressKeyword,areaDetailCode} from "@/api/public.js";
  import { getGroups} from "@/api/system.js";
  import {getAgentListSelect} from "@/api/agent.js"
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  import DistrictsCascader from "@/components/tool/lib/districts-cascader/Index.vue";

  import Picture from '@/components/tool/lib/upload/index.vue';

  export default {
    props: {
      id: Number
    },
    components: {DistrictsCascader, UploadQiniu, Picture},
    data() {
      const validatePhone = (rule, value, callback) => {
        const regExp = /^(0|86|17951)?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/
        if (!regExp.test(value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      }
      return {
        typeOptions: [],
        storeSizeOptions: [{
          value: 0,
          label: '普通'
        }, {
          value: 1,
          label: '中型'
        }, {
          value: 2,
          label: '大型'
        }, {
          value: 3,
          label: '超大型'
        }],
        groupList:[],
        layTypeOptions: [{
          value: 0,
          label: '独家'
        }, {
          value: 1,
          label: '共存'
        }],
        unitList: [{
          value: 0,
          label: '每半小时'
        }, {
          value: 1,
          label: '每一小时'
        }],
        toolsConfig: ["districts"],
        keywordAddress: [],
        keywordLoading: false,
        keywordResult: "",
        keywordProvince:'',
        form: {
          name: "",
          phone: "",
          province_id: 0,
          agent_id:0,
          city_id:0,
          area_id:0,
          group_id:'',
          lat:0,
          lng:0,
          address:'',
          license: '',
          license_image:'',
          address:"",
          start_time:'',
          end_time:'',
          store_type:'',
          store_size:'',
          lay_type:'',
          unit:'',
          unit_price:'',
          market:'',
          day_top:''
        },
        imageUrl: [],
        storeImageUrl:[],
        value: [],
        time: '',
        serviceCascader: [],
        serviceCascaderProps: {
          multiple: true,
          value:'id',
          label:'label'
        },
        serviceCascaderValue:[],
        service_data:{
          service_ids:[],
          service_ids_name:[],
          service_province:'',
          service_city:'',
          service_area:'',
          service_province_str:'',
          service_city_str:'',
          service_area_str:''
        },
        show: true,
        placeholder: "",
        rules: {
          "name": [
            {required: true, message: "请输入投放点名称", trigger: "blur"},
          ],
          "phone": [
            {required: true, message: "请输入手机号", trigger: "blur"},
            {
              validator: validatePhone,
              trigger: 'change',
            }
          ],
          "address": [
            {required: true, message: "请输入公司地址", trigger: "change"},
          ],
          "unit_price": [
            {required: true, message: "请输入单价", trigger: "blur"},
          ],
          "unit": [
            {required: true, message: "请选择单位", trigger: "change"},
          ],
          "business_start_time": [
            {required: true, message: "请选择营业时间", trigger: "change"},
          ],
          "business_end_time": [
            {required: true, message: "请选择营业时间", trigger: "change"},
          ]
        },
        innerVisible:false
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.groupID = _this.$store.state.user.userData.group_id
      _this.init();

    },

    methods: {
      // 初始化数据
      init() {
        console.log(this.id)
        getListById(this.id).then(res=>{
          if(res.code === 200){
            this.form = res.data
            // console.log(res.data)
            this.keywordResult = res.data.address
            console.log(this.form.store_images)
          }
        })
        getAgentListSelect().then(res=>{
          if(res.code === 200){
            this.agents_select = res.data
          }
        })
        getTypeSelect().then(res=>{
          if(res.code === 200){
            this.typeOptions = res.data
            // console.log(this.typeOptions)
          }
        })
        getGroups().then(res=>{
          if(res.code === 200){
            this.groupList = res.data
          }
        })
      },
      priceChange(){
        if(this.form.unit==0){
          this.form.day_top = this.form.unit_price * 10 * 2
        }else{
          this.form.day_top = this.form.unit_price * 10
        }
      },
      remoteMethod(query) {
        if (query !== "") {
          this.keywordLoading = true;
          tencentAddressKeyword({keyword: query}).then(res => {
            this.keywordLoading = false;
            if (res.code === 200) {
              this.keywordAddress = res.data;
            }
          });
        } else {
          this.options = [];
        }
      },

      keywordChange() {
        let data = this.keywordAddress[this.keywordResult];
        this.keywordProvince = data['address']+data['title']
        this.form.lat = data["location"]["lat"];
        this.form.lng = data["location"]["lng"];
        this.form.address = data['address']+data['title']
        areaDetailCode({area_id: data.adcode}).then(res => {
          if (res.code === 200) {
            this.form.province_id = res.data.province_id
            this.form.city_id = res.data.city_id
            this.form.area_id = res.data.area_id
          }
        })
      },

      serviceCascaderChange(e){
        let ids = []
        let names = []
        let object = this.$refs["service_cascader"].getCheckedNodes()
        Object.keys(object).map((key) => [object[key], key]).forEach(([value,key]) => {
          //循环操作
          if(!value.hasChildren){
            ids.push(value.value)
            names.push(value.label)
          }
        })
        this.service_data.service_ids = ids
        this.service_data.service_ids_name = names
      },

      close() {
        _this.$emit("close");
      },

      editData(form) {
        _this.form.devided = parseFloat(_this.form.devided)*100
        this.$refs[form].validate(valid => {
          if (valid) {
            editData(_this.id, _this.form).then((res) => {
              if (res.code === 200) {
                _this.$message({
                  message: "修改成功",
                  type: "success"
                });
                setTimeout(() => {
                  _this.$emit("success");
                }, 1000);
              }
            })
          }
          else{
            console.log("error submit!!");
            return false;
          }
        })
      },
      addServiceData(){
        this.form.service_data.push(JSON.parse(JSON.stringify(this.service_data)));
        this.serviceCascaderValue = []
        this.innerVisible = false
      },
      // 选择省市区
      handleDistrictsChoose(e) {
        _this.form.province_id = e.province_code;
        _this.form.city_id = e.city_code;
        _this.form.area_id = e.area_code;
      },
      handleAreaDistrictsChoose(e){
        this.service_data.service_province = e.province_code
        this.service_data.service_province_str = e.province
        this.service_data.service_city = e.city_code
        this.service_data.service_city_str = e.city
        this.service_data.service_area = e.area_code
        this.service_data.service_area_str = e.area
      },
      // 上传营业执照删除、缓存、预览
      handleLicenseSuccess(e) {
        console.log(e)
        this.form.license_image = e[0];
      },
      handleLicenseRemove(e) {
        this.form.license_image = "";
      },
      // 上传图片删除、缓存、预览
      handlePicSuccess(e) {
        console.log(e)
        this.form.store_images = e;
      },
      handlePicRemove(e) {
        console.log(e)
        this.form.store_images = "";
      },
      handlePreview(e){
        console.log(e)
      },
      removeServiceData(key){
        console.log(key)
        let object = JSON.parse(JSON.stringify(this.form.service_data))
        console.log(object)
        if(object.length ===1){
          object = []
        }else{
          object.splice(key,1)
        }
        this.form.service_data = object
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }
</style>
