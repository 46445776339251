<template>
  <section>
    <div class="container">
    <!-- 用户列表 -->
    <el-table :data="list" style="width: 100%" class="table" fit>
        <!-- 数据展示区 -->
        <el-table-column
          label="分类"
          prop="name"
          align="left"
          width="100px"
        ></el-table-column>
        <el-table-column
          label="字典"
          prop="status"
          align="left"
        >
          <template slot-scope="scope">
            <div style="width:100%" class="data-box">
              <div class="data-tag" v-for="(value,key) in scope.row.data" :key="key">
                {{value.name}}
                <i class="el-icon-circle-close" @click="handleRemove(scope.row,value.id)"></i>
              </div>
              <el-input
                class="input-new-tag"
                v-if="scope.row.add"
                v-model="scope.row.add_name"
                :ref="'saveTagInput_'+scope.row.id"
                size="mini"
                @keyup.enter.native="handleInputConfirm(scope.row)"
                @blur="handleInputConfirm(scope.row)"
              ></el-input>
              <el-button v-else class="button-new-tag" size="mini" @click="showInput(scope.row)">+ 添加</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </section>
</template>
<script>
let page_name = 'customer_tag_list'
import {
  getTypeList,
  getStatusList,
  getOriginList,
  getMaturityList,
  getSectorList,
  getTagList,
  getWealthList,
  getCharacterList,
  addType,
  addStatus,
  addMaturity,
  addOrigin,
  addSector,
  addTag,
  addWealth,
  addCharacter,
  deleteType,
  deleteStatus,
  deleteOrigin,
  deleteMaturity,
  deleteSector,
  deleteTag,
  deleteCharacter,
  deleteWealth
} from "@/api/customer.js"
export default {
  name: "App",

  data() {
      return {
          list:[
            {
              id:'type',
              name:'类别',
              data:[],
              add:false,
              add_name:''
            },
            {
              id:'status',
              name:'状态',
              data:[],
              add:false,
              add_name:''
            },
            {
              id:'origin',
              name:'来源',
              data:[],
              add:false,
              add_name:''
            },
            {
              id:'maturity',
              name:'合作意愿',
              data:[],
              add:false,
              add_name:''
            },
            {
              id:'sector',
              name:'行业',
              data:[],
              add:false,
              add_name:''
            },
            {
              id:'tag',
              name:'标签',
              data:[],
              add:false,
              add_name:''
            },
            {
              id:'wealth',
              name:'财富',
              data:[],
              add:false,
              add_name:''
            },
            {
              id:'character',
              name:'性格',
              data:[],
              add:false,
              add_name:''
            }
          ],
      };
  },

  mounted(){
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    this.init()
  },

  methods: {
    init(){
      this.getTypes()
      this.getStatus()
      this.getOrigin()
      this.getMaturity()
      this.getSector()
      this.getTag()
      this.getWealth()
      this.getCharacter()
    },
    getTypes(){
      getTypeList().then(res=>{
        if(res.code === 200){
          this.list[0].data = res.data
          this.$forceUpdate()
        }
      })
    },
    getStatus(){
      getStatusList().then(res=>{
        if(res.code === 200){
          this.list[1].data = res.data
          this.$forceUpdate()
        }
      })
    },
    getOrigin(){
      getOriginList().then(res=>{
        if(res.code === 200){
          this.list[2].data = res.data
          this.$forceUpdate()
        }
      })
    },
    getMaturity(){
      getMaturityList().then(res=>{
        if(res.code === 200){
          this.list[3].data = res.data
          this.$forceUpdate()
        }
      })
    },
    getSector(){
      getSectorList().then(res=>{
        if(res.code === 200){
          this.list[4].data = res.data
          this.$forceUpdate()
        }
      })
    },
    getTag(){
      getTagList().then(res=>{
        if(res.code === 200){
          this.list[5].data = res.data
          this.$forceUpdate()
        }
      })
    },
    getWealth(){
      getWealthList().then(res=>{
        if(res.code === 200){
          this.list[6].data = res.data
          this.$forceUpdate()
        }
      })
    },
    getCharacter(){
      getCharacterList().then(res=>{
        if(res.code === 200){
          this.list[7].data = res.data
          this.$forceUpdate()
        }
      })
    },

    showInput(e){
      let _this = this
      if(e.id == 'type'){
        this.list[0].add = true
        this.$nextTick(_ => {
          this.$refs.saveTagInput_type.$refs.input.focus();
        });
      }
      if(e.id == 'status'){
        this.list[1].add = true
        this.$nextTick(_ => {
          this.$refs.saveTagInput_status.$refs.input.focus();
        });
      }
      if(e.id == 'origin'){
        this.list[2].add = true
        this.$nextTick(_ => {
          this.$refs.saveTagInput_origin.$refs.input.focus();
        });
      }
      if(e.id == 'maturity'){
        this.list[3].add = true
        this.$nextTick(_ => {
          this.$refs.saveTagInput_maturity.$refs.input.focus();
        });
      }
      if(e.id == 'sector'){
        this.list[4].add = true
        this.$nextTick(_ => {
          this.$refs.saveTagInput_maturity.$refs.input.focus();
        });
      }
      if(e.id == 'tag'){
        this.list[5].add = true
        this.$nextTick(_ => {
          this.$refs.saveTagInput_tag.$refs.input.focus();
        });
      }
      if(e.id == 'wealth'){
        this.list[6].add = true
        this.$nextTick(_ => {
          this.$refs.saveTagInput_wealth.$refs.input.focus();
        });
      }
      if(e.id == 'character'){
        this.list[7].add = true
        this.$nextTick(_ => {
          this.$refs.saveTagInput_character.$refs.input.focus();
        });
      }
      this.$forceUpdate()
    },
    handleInputConfirm(e){
      let _this = this
      if(e.id == 'type'){
        if(e.add_name!==''){
          addType({'name':e.add_name}).then(res=>{
            if(res.code === 200){
              _this.getTypes()
              _this.list[0].add_name = ''
            }
          })
        }
        this.list[0].add = false
      }
      if(e.id == 'status'){
        if(e.add_name!==''){
          addStatus({'name':e.add_name}).then(res=>{
            if(res.code === 200){
              _this.getStatus()
              _this.list[1].add_name = ''
            }
          })
        }
        this.list[1].add = false
      }
      if(e.id == 'origin'){
        if(e.add_name!==''){
          addOrigin({'name':e.add_name}).then(res=>{
            if(res.code === 200){
              _this.getOrigin()
              _this.list[2].add_name = ''
            }
          })
        }
        this.list[2].add = false
      }
      if(e.id == 'maturity'){
        if(e.add_name!==''){
          addMaturity({'name':e.add_name}).then(res=>{
            if(res.code === 200){
              _this.getMaturity()
              _this.list[3].add_name = ''
            }
          })
        }
        this.list[3].add = false
      }
      if(e.id == 'sector'){
        if(e.add_name!==''){
          addSector({'name':e.add_name}).then(res=>{
            if(res.code === 200){
              _this.getSector()
              _this.list[4].add_name = ''
            }
          })
        }
        this.list[4].add = false
      }

      if(e.id == 'tag'){
        if(e.add_name!==''){
          addTag({'name':e.add_name}).then(res=>{
            if(res.code === 200){
              _this.getTag()
              _this.list[5].add_name = ''
            }
          })
        }
        this.list[5].add = false
      }

      if(e.id == 'wealth'){
        if(e.add_name!==''){
          addWealth({'name':e.add_name}).then(res=>{
            if(res.code === 200){
              _this.getWealth()
              _this.list[6].add_name = ''
            }
          })
        }
        this.list[6].add = false
      }

      if(e.id == 'character'){
        if(e.add_name!==''){
          addCharacter({'name':e.add_name}).then(res=>{
            if(res.code === 200){
              _this.getCharacter()
              _this.list[7].add_name = ''
            }
          })
        }
        this.list[7].add = false
      }
      this.$forceUpdate()
    },
    handleRemove(e,id){
      let _this = this
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if(e.id == 'type'){
          deleteType(id).then(res=>{
            if(res.code === 200){
              _this.getTypes()
              _this.$forceUpdate()
              _this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          })
        }
        if(e.id == 'status'){
          deleteStatus(id).then(res=>{
            if(res.code === 200){
              _this.getStatus()
              _this.$forceUpdate()
              _this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          })
        }
        if(e.id == 'origin'){
          deleteOrigin(id).then(res=>{
            if(res.code === 200){
              _this.getOrigin()
              _this.$forceUpdate()
              _this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          })
        }
        if(e.id == 'maturity'){
          deleteMaturity(id).then(res=>{
            if(res.code === 200){
              _this.getMaturity()
              _this.$forceUpdate()
              _this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          })
        }
        if(e.id == 'sector'){
          deleteSector(id).then(res=>{
            if(res.code === 200){
              _this.getSector()
              _this.$forceUpdate()
              _this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          })
        }
        if(e.id == 'tag'){
          deleteTag(id).then(res=>{
            if(res.code === 200){
              _this.getTag()
              _this.$forceUpdate()
              _this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          })
        }
        if(e.id == 'wealth'){
          deleteWealth(id).then(res=>{
            if(res.code === 200){
              _this.getWealth()
              _this.$forceUpdate()
              _this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          })
        }
        if(e.id == 'character'){
          deleteCharacter(id).then(res=>{
            if(res.code === 200){
              _this.getCharacter()
              _this.$forceUpdate()
              _this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          })
        }
      }).catch(() => {
        
      });
      
    }
  }
};
</script>

<style scoped lang="scss">
    @import "@/static/default";
    .el-drawer {
        overflow: auto !important;
    }

    .container {
        background-color: #fff !important;
        padding: 0 32px;
        .table {
        }
        .toolbar {
            background: #fff;
            width: calc(100% + 64px);
            padding: 16px 32px 26px 0;
            margin-left: -32px;
        }
    }

    .data-box{
      width: 100%;
      display:flex;
      flex-wrap:wrap;
      .data-tag{
        position: relative;
        box-sizing:border-box;
        padding: 0 10px;
        padding-right: 20px;
        height: 32px;
        font-size:12px;
        text-align:center;
        line-height:30px;
        border-collapse: separate;
        white-space: nowrap;
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        margin-right: 10px;
        border-color:rgba(0,0,0,0);
        .el-icon-circle-close{
          display:none;
          font-size: 14px;
        }
      }
      .data-tag:hover{
        background-color: white;
        border-color: #DCDFE6;
        .el-icon-circle-close{
          position: absolute;
          display:block;
          right: 4px;
          top: 9px;
        }
      }
    }

    .el-tag {
      margin-right: 10px;
    }
    .button-new-tag {
      margin-right: 10px;
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .input-new-tag {
      width: 90px;
      margin-right: 10px;
      vertical-align: bottom;
    }
</style>