<template>
    <div>
        <el-descriptions direction="vertical" :column="4" border>
            <el-descriptions-item label="手机号">{{userData.phone}}</el-descriptions-item>
            <el-descriptions-item label="姓名">{{userData.real_name}}</el-descriptions-item>
            <el-descriptions-item label="生日">{{userData.birth}}</el-descriptions-item>
            <el-descriptions-item label="生日类别">
                <div v-if="userData.birth_type == 0">阳历</div>
                <div v-if="userData.birth_type == 1">阴历</div></el-descriptions-item>
            <el-descriptions-item label="性别">
                <div v-if="userData.gender == 0">男</div>
                <div v-if="userData.gender == 1">女</div>
            </el-descriptions-item>
            <el-descriptions-item label="寺院">{{templeData[0].name}}</el-descriptions-item>
            <el-descriptions-item label="注册时间">{{userData.created_at}}</el-descriptions-item>
        </el-descriptions>
        <div style="margin-top:30px;margin-bottom:20px;margin-left:20px;font-size:16px;">订阅记录</div>
        <div style="margin-left:30px;font-size:14px;color:#788;" v-if="subscribeData.length===0">暂无订阅</div>
        <el-table :data="subscribeData" style="margin:0 auto;width:90%;" border v-else>
            <el-table-column prop="name" label="订阅" align="center">
                <template slot-scope="scope">
                {{scope.row.bless.name}}
                </template>
            </el-table-column>
            <el-table-column prop="time" label="剩余时长" align="center">
                <template slot-scope="scope">
                {{scope.row.time_left}}
                </template>
            </el-table-column>
            <el-table-column prop="time" label="订阅时长" width="170" align="center">
                <template slot-scope="scope">
                {{scope.row.plan_time}}
                </template>
            </el-table-column>
            <el-table-column prop="time" label="开始时间" width="170" align="center">
                <template slot-scope="scope">
                {{scope.row.created_at}}
                </template>
            </el-table-column>
            <el-table-column prop="time" label="结束时间" width="170" align="center">
                <template slot-scope="scope">
                {{scope.row.end_date}}
                </template>
            </el-table-column>
        </el-table>
    </div>

  </template>
  
  <script>
    let _this;
    import {getDataById,getSubscribeList} from "@/api/users.js";
    export default {
        props:['id'],
        data(){
            return{
                templeData:[],
                userData:[],
                subscribeData:[]
            }
        },
        mounted(){
            this.init()
            
        },
        methods:{
            // 初始化数据
            init() {
                getDataById(this.id).then(res=>{
                    if (res.code === 200){
                        this.userData = res.data.info
                        this.templeData = res.data.temple
                    }
                })
                getSubscribeList(this.id).then(res=>{
                    if (res.code === 200){
                        this.subscribeData = res.data.data
                        console.log(this.subscribeData)
                    }
                })
            },
        }
    };
  </script>
  <style lang="scss" scoped>
  .title{
    
  }
  </style>
  