<template>
  <section style="height: 100%">
    <div class="container">
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>场景字典列表</h1></div>
          <div class="control-box" style="margin-top: 10px;">
            <el-input class="control-box-input" v-model="params.name" placeholder="请输入名称"></el-input>
            <el-button icon="el-icon-search" @click="getList" style="margin-left: 10px;">搜索</el-button>
            <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd">添加</el-button>
          </div>
        </div>
      <!-- 用户列表 -->
        <el-table :data="list" style="width: 100%" class="table" fit>
          <!-- 数据展示区 -->
          <el-table-column
            label="名称"
            prop="name"
            align="center"
            width="100px"
          ></el-table-column>
          <el-table-column
            label="字典状态"
            prop="status"
            align="center"
            width="100px"
          >
            <template slot-scope="scope">
              <el-tag type="info" effect="light" v-if="parseInt(scope.row.status)===0">禁用</el-tag>
              <el-tag effect="light" v-if="parseInt(scope.row.status)===1">可用</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="备注"
            prop="command"
            align="left"
          ></el-table-column>
          <el-table-column label="操作" fixed="right" align="center" width="200px">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" v-if="admin_permission.indexOf('update')>-1">更改</el-button>
              <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)" v-if="admin_permission.indexOf('deleted')>-1">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!--分页-->
        <el-col :span="24" class="toolbar">
          <el-pagination
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            @prev-click="prevpage"
            @next-click="nextpage"
            :page-size="10"
            :total="total"
            style="float: right"
          ></el-pagination>
        </el-col>
      </el-main>
    </div>
    <el-drawer
      title="更改场景字典"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <edit-comp v-if="editDrawer" @close="handleClose" @success="handleDrawerSuccess" :id="editID" ref="newForm"></edit-comp>
    </el-drawer>

    <!-- 添加规则 -->
    <el-drawer
      title="添加场景字典"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <add-comp
        @close="handleClose"
        @success="handleDrawerSuccess"
        @clear="clearContent"
        ref="newForm"
        v-if="addDrawer"
      ></add-comp>
    </el-drawer>
  </section>
</template>
<script>
import {getHallList,changeHallStatus,deleteHallData} from '@/api/dictionaries.js'
import addComp from "./components/add.vue";
import editComp from "./components/edit.vue";
let page_name = 'hall_dictionaries'
export default {
  name: "App",
  components:{addComp,editComp},
  data() {
      return {
          params: {
              page:1,
              name:''
          },
          list: [],
          show: false,
          showBack: false,
          total: 0,
          id: 0,
          editID:0,
          dialogFormVisible: false,
          editDrawer: false,
          addDrawer: false,
          direction: "rtl",
          drawer: false,
          options: [],
          row: "",
      };
  },
  methods: {
    // 获取分类列表
    getList(){
      console.log(this.params)
      getHallList(this.params).then((res)=>{
          if(res.code===200){
            console.log(res.data.data)  
              this.list=res.data.data;
              this.total=res.data.total;
              this.params.page = res.data.current_page
          }
      })
    },
    handleGetTag(e){
      this.params.name=e
    },

    // 删除分类
    handleDelete(index, row) {
      this.$confirm('确认删除？').then(_ => {
        deleteHallData(row.id)
          .then(( data ) => {
              if(data.code === 200){
                this.getList()
              }
          }).catch((error)=>{});
      }).catch(_ => {});
    },

    // 修改用户
    handleEdit(index, row) {
      this.editID = row.id;
      this.editDrawer = true;
      this.row = row;
    },
    // 新增
    handleOpenAdd() {
      this.addDrawer = true;
    },
    // 关闭弹窗
    handleClose() {
      this.$confirm("确认关闭？").then((_) => {
          this.editDrawer = false;
          this.addDrawer = false;
          this.$refs.newForm.$refs.form.resetFields(); //清空子组件的内容
      }).catch((_) => {});
    },

    // 修改成功
    handleDrawerSuccess(form) {
      this.getList();
      this.handleClose();
      this.$refs.newForm.$refs.form.resetFields();// 清空表单内容
    },
    // 返回
    handleBack(){
      this.showBack=false;
      this.getList();
      this.params={};
    },

    // 分页
    prevpage(){
      this.params.page -=1;
      this.getList()
    },
    nextpage(){
      this.params.page +=1;
      this.getList()
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getList()
    },
  },
  mounted(){
    // getProvince().then(res=>{
    //   console.log(res)
    // })
    this.getList();
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
  },
};
</script>

<style scoped lang="scss">
    @import "@/static/default";
    .el-drawer {
        overflow: auto !important;
    }

    .container {
      width: 100%;
      min-height: 100%;
      background-color: #fff !important;
      padding: 20px 32px;
      padding-bottom:0;
      display: inline-flex;
      .table {
      }
      .toolbar {
          background: #fff;
          width: calc(100% + 64px);
          padding: 16px 32px 26px 0;
          margin-left: -32px;
      }
    }

    .control-box{
      width: 100%;
      display:flex;
      flex-wrap: wrap;
      &-input{
        width: 150px;
      }
    }
    .demo-table-expand {
        font-size: 0;
    }
    .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }
    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }
</style>
