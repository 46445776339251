<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-divider content-position="left">投放点基本信息</el-divider>
      <el-form-item label="投放点名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入投放点名称"></el-input>
      </el-form-item>
      <el-form-item label="投放点详细地址" prop="address" required>
        <el-select
          style="width: 100%"
          v-model="keywordResult"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          @change="keywordChange"
          :remote-method="remoteMethod"
          :loading="keywordLoading"
        >
          <el-option
            v-for="(value,key) in keywordAddress"
            :key="key"
            :label="value.title"
            :value="key"
          >
          <p style="float: left;width:100%;font-weight:bold;">{{ value.title }}</p>
          <span style="float: right; color: #8492a6; font-size: 12px;margin-top:-15px;">{{ value.address }}</span>
        </el-option>
        </el-select>
        <div v-if="keywordProvince!==''" style="line-height:16px;color: #8492a6; font-size: 12px; margin-top:4px;">{{keywordProvince}}</div>
      </el-form-item>
      <el-divider content-position="left">投放点其他信息</el-divider>
      <el-form-item label="联系方式" prop="phone" required>
        <el-input placeholder="请输入投放点电话" v-model="form.phone"></el-input>
      </el-form-item>

      <el-form-item label="上传投放点图片" required>
        <upload-qiniu @success="handlePicSuccess" @remove="handlePicRemove" :num="6" path="temple" :multiple="true" :images="storeImageUrl"></upload-qiniu>
      </el-form-item>
      
      <el-form-item label="信用代码" prop="credit_code">
        <el-input v-model="form.license_no" placeholder="请输入信用代码"></el-input>
      </el-form-item>
      <el-form-item label="上传投放点营业执照">
        <upload-qiniu @success="handleLicenseSuccess" @remove="handleLicenseRemove" :multiple="false" :images="imageUrl"></upload-qiniu>
      </el-form-item>
      
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="addData('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>

  let _this;
  let id = 0;
  import {getTypeSelect} from "@/api/store.js";
  import {addData} from "@/api/temple.js";
  import {tencentAddressKeyword,areaDetailCode} from "@/api/public.js";
  import { getGroups} from "@/api/system.js";
  import {getAgentListSelect} from "@/api/agent.js"
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  import DistrictsCascader from "@/components/tool/lib/districts-cascader/Index.vue";

  import Picture from '@/components/tool/lib/upload/index.vue';

  export default {
    props: {
      row: Object,
      editId: Number
    },
    components: {DistrictsCascader, UploadQiniu, Picture},
    data() {
      const validatePhone = (rule, value, callback) => {
        const regExp = /^(0|86|17951)?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/
        if (!regExp.test(value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      }
      return {
        typeOptions: [],
        storeSizeOptions: [{
          value: 0,
          label: '普通'
        }, {
          value: 1,
          label: '中型'
        }, {
          value: 2,
          label: '大型'
        }, {
          value: 3,
          label: '超大型'
        }],
        layTypeOptions: [{
          value: 0,
          label: '独家'
        }, {
          value: 1,
          label: '共存'
        }],
        unitList: [{
          value: 0,
          label: '每半小时'
        }, {
          value: 1,
          label: '每一小时'
        }],
        toolsConfig: ["districts"],
        keywordAddress: [],
        keywordLoading: false,
        keywordResult: "",
        keywordProvince:'',
        form: {
          name: "",
          phone: "",
          province_id: "",
          city_id: "",
          area_id: "",
          address: "",
          lat:'',
          lng:'',
          license_no: '',
          license:'',
          store_images:''
        },
        groupList:[],
        imageUrl: [],
        storeImageUrl:[],
        value: [],
        time: '',
        serviceCascader: [],
        serviceCascaderProps: {
          multiple: true,
          value:'id',
          label:'label'
        },
        serviceCascaderValue:[],
        service_data:{
          service_ids:[],
          service_ids_name:[],
          service_province:'',
          service_city:'',
          service_area:'',
          service_province_str:'',
          service_city_str:'',
          service_area_str:''
        },
        show: true,
        placeholder: "",
        id: "",
        rules: {
          "name": [
            {required: true, message: "请输入投放点名称", trigger: "blur"},
          ],
          "phone": [
            {required: true, message: "请输入手机号", trigger: "blur"},
            {
              validator: validatePhone,
              trigger: 'change',
            }
          ],
          "address": [
            {required: true, message: "请输入公司地址", trigger: "change"},
          ],
        },
        innerVisible:false
      };
    },

    computed: {
    },

    mounted() {
      _this = this;
      _this.groupID = _this.$store.state.user.userData.group_id
      _this.init();

    },

    methods: {
      // 初始化数据
      init() {
        getTypeSelect().then(res=>{
          if(res.code === 200){
            this.typeOptions = res.data
            console.log(this.typeOptions)
          }
        })
        getAgentListSelect().then(res=>{
          if(res.code === 200){
            this.agents_select = res.data
          }
        })
        getGroups().then(res=>{
          if(res.code === 200){
            this.groupList = res.data
          }
        })
      },
      priceChange(){
        if(this.form.unit==0){
          this.form.day_top = this.form.unit_price * 10 * 2
        }else{
          this.form.day_top = this.form.unit_price * 10
        }
      },
      remoteMethod(query) {
        if (query !== "") {
          this.keywordLoading = true;
          tencentAddressKeyword({keyword: query}).then(res => {
            this.keywordLoading = false;
            if (res.code === 200) {
              this.keywordAddress = res.data;
            }
          });
        } else {
          this.options = [];
        }
      },

      keywordChange() {
        let data = this.keywordAddress[this.keywordResult];
        this.keywordProvince = data['address']
        this.form.address = data['address']
        this.form.lat = data["location"]["lat"];
        this.form.lng = data["location"]["lng"];
        areaDetailCode({area_id: data.adcode}).then(res => {
          if (res.code === 200) {
            this.form.province_id = res.data.province_id
            this.form.city_id = res.data.city_id
            this.form.area_id = res.data.area_id
          }
        })
      },

      serviceCascaderChange(e){
        let ids = []
        let names = []
        let object = this.$refs["service_cascader"].getCheckedNodes()
        Object.keys(object).map((key) => [object[key], key]).forEach(([value,key]) => {
          //循环操作
          if(!value.hasChildren){
            ids.push(value.value)
            names.push(value.label)
          }
        })
        this.service_data.service_ids = ids
        this.service_data.service_ids_name = names
      },

      close() {
        _this.$emit("close");
      },

      addData(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            if(_this.form.store_images.length===0){
              _this.$message({
                message: '请上传投放点图片',
                type: 'warning'
              });
            }else{
              addData( _this.form).then((res) => {
                if (res.code === 200) {
                  _this.$message({
                    message: "添加成功",
                    type: "success"
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                  }, 1000);
                }
              });
              this.form = form
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      },
      addServiceData(){
        this.form.service_data.push(JSON.parse(JSON.stringify(this.service_data)));
        this.serviceCascaderValue = []
        this.innerVisible = false
      },
      // 选择省市区
      handleDistrictsChoose(e) {
        _this.form.province_id = e.province_code;
        _this.form.city_id = e.city_code;
        _this.form.area_id = e.area_code;
      },
      handleAreaDistrictsChoose(e){
        this.service_data.service_province = e.province_code
        this.service_data.service_province_str = e.province
        this.service_data.service_city = e.city_code
        this.service_data.service_city_str = e.city
        this.service_data.service_area = e.area_code
        this.service_data.service_area_str = e.area
      },
      // 上传营业执照删除、缓存、预览
      handleLicenseSuccess(e) {
        console.log(e)
        this.form.license_image = e[0];
      },
      handleLicenseRemove(e) {
        this.form.license_image = "";
      },
      // 上传图片删除、缓存、预览
      handlePicSuccess(e) {
        console.log(e)
        this.form.store_images = e;
      },
      handlePicRemove(e) {
        this.form.store_images = [];
      },

      removeServiceData(key){
        console.log(key)
        let object = JSON.parse(JSON.stringify(this.form.service_data))
        console.log(object)
        if(object.length ===1){
          object = []
        }else{
          object.splice(key,1)
        }
        this.form.service_data = object
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }

  .el-select-dropdown__item{
    height:auto !important;
  }
</style>
