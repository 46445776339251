<template>
    <div class="calendar-box">
        <div class="calendar-info" v-if="size==='big'">
            <div class="calendar-month">{{currentMonth}}</div>
            <div class="calendar-year">{{currentYear}}</div>
            <div class="calendar-lunar">{{currentLunarYear}}年[{{currentLunarZodiac}}]</div>
        </div>
        <div class="calendar-content">
            <div class="calendar-control">
                <div v-if="size!=='big'" class="calendar-date">
                    <div class="calendar-control-today">
                        <span class="calendar-month">{{currentYear}}.{{currentMonth}}</span>
                        <span class="calendar-lunar">
                            {{currentLunarYear}}年[{{currentLunarZodiac}}] {{currentLunarMonth}} {{currentLunarDay}}
                        </span>
                    </div>
                    <el-button size="mini" class="calendar-go-info" type="primary" @click="goCalendar">前往日历</el-button>
                </div>
                <el-date-picker v-else
                    v-model="searchDate"
                    type="month"
                    placeholder="请搜索月份"
                    align="center"
                    :clearable="true"
                    format="yyyy年MM月"
                    @change="changeCalendar"
                >
                </el-date-picker>
            </div>
            <div class="calendar-day">
                <div class="calendar-day-list" v-for="(value,key) in week">
                    <div class="calendar-day-list-week" v-if="size==='big'">
                        <div v-if="value.num === 0 || value.num === 6" class="rest">{{value.name}}<span class="yao">/ {{value.yao}}</span></div>
                        <div v-else>{{value.name}}<span class="yao">/ {{value.yao}}</span></div>
                    </div>
                    <div class="calendar-day-list-week-small" v-else>
                        <div v-if="value.num === 0 || value.num === 6" class="rest">{{value.small}}</div>
                        <div v-else>{{value.small}}</div>
                    </div>
                </div>
                <div class="calendar-day-list" v-for="(value,key) in date" :key="key">
                    <div class="festival-icon" v-if="size!=='big' && value.festival.length>0">
                        <img src="https://cdn.cxc-group.com/admin/festival-icon.png"/>
                    </div>
                    <div class="calendar-day-list-info" :class="{'not-current' :parseInt(currentMonth)!==parseInt(value.month)}" @click="lookDetail(key)">
                        <div class="calendar-day-list-info-date">
                            <block v-if="(value.year+'-'+value.month+'-'+value.day) === today">
                                <span class="today">
                                    {{value.day}}
                                </span>
                            </block>
                            <block v-else>
                                <span v-if="parseInt(value.week) === 0 || parseInt(value.week) === 6" class="rest">
                                {{value.day}}
                                </span>
                                <span v-else>
                                    {{value.day}}
                                </span>
                            </block>
                        </div>
                        <div class="calendar-day-list-info-lunar">
                            <span v-if="size!=='mini'">{{value.lunar[1]}} {{value.lunar[2]}}</span>
                            <span v-else>{{value.lunar[2]}}</span>
                        </div>
                        <div class="calendar-day-list-info-festival" v-if="size!=='mini'">
                            <div class="calendar-day-list-info-festival-list" v-for="(v,k) in value.festival" :key="k">
                                <el-tag
                                    class="calendar-day-list-info-festival-list-tag normal-icon"
                                    effect="plain"
                                    size="mini"
                                    v-if="parseInt(v.type)===0"
                                >
                                    <el-image class="calendar-day-list-info-festival-list-icon" fit="contain" src="https://cdn.cxc-group.com/manage/normal-festival-icon.png"></el-image>
                                    {{v.name}}
                                </el-tag>
                                <el-tag
                                    class="calendar-day-list-info-festival-list-tag fo-icon"
                                    effect="plain"
                                    size="mini"
                                    v-if="parseInt(v.type)===1"
                                >
                                    <el-image class="calendar-day-list-info-festival-list-icon" fit="contain" src="https://cdn.cxc-group.com/manage/fo-festival-icon.png"></el-image>
                                    {{v.name}}
                                </el-tag>
                                <el-tag
                                    class="calendar-day-list-info-festival-list-tag dao-icon"
                                    effect="plain"
                                    size="mini"
                                    v-if="parseInt(v.type)===2"
                                >
                                    <el-image class="calendar-day-list-info-festival-list-icon" fit="contain" src="https://cdn.cxc-group.com/manage/dao-festival-icon.png"></el-image>
                                    {{v.name}}
                                </el-tag>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="calendar-festival" v-if="size!=='big'">
                <div class="calendar-detail-empty" style="font-size:12px;text-align:center;color:rgba(0,0,0,0.2)" v-if="festival.length === 0">
                    暂无节日
                </div>
                <div class="calendar-detail-festival" v-else>
                    <div class="calendar-detail-festival-list" v-for="(value,key) in festival" :key="key">
                        <div class="calendar-detail-festival-list-left">
                            <el-image v-if="parseInt(value.type) === 0" class="calendar-detail-festival-list-icon" fit="contain" src="https://cdn.cxc-group.com/manage/normal-festival-icon.png"></el-image>
                            <el-image v-if="parseInt(value.type) === 1" class="calendar-detail-festival-list-icon" fit="contain" src="https://cdn.cxc-group.com/manage/fo-festival-icon.png"></el-image>
                            <el-image v-if="parseInt(value.type) === 2" class="calendar-detail-festival-list-icon" fit="contain" src="https://cdn.cxc-group.com/manage/dao-festival-icon.png"></el-image>
                        </div>
                        <div class="calendar-detail-festival-list-content">{{value.name}}</div>
                        <div class="calendar-detail-festival-list-right">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <el-drawer
            :visible.sync="lookDrawer"
            :with-header="false"
            size="30%"
        >
            <div class="calendar-look-title">
                <div>
                    <div class="calendar-look-title-date">{{look.day}}</div>
                    <div class="calendar-look-title-lunar">
                        <div class="calendar-look-title-lunar-text">{{look.lunar[3]}}{{look.lunar[6]}}年{{look.lunar[1]}}{{look.lunar[2]}}</div>
                    </div>
                </div>
            </div>
            <div class="calendar-detail">
                <div class="calendar-detail-empty" v-if="look.festival.length === 0">
                    <el-empty :image-size="200" description="暂无节日"></el-empty>
                </div>
                <div class="calendar-detail-festival" v-else>
                    <div class="calendar-detail-festival-list" v-for="(value,key) in look.festival" :key="key">
                        <div class="calendar-detail-festival-list-left">
                            <el-image v-if="parseInt(value.type) === 0" class="calendar-detail-festival-list-icon" fit="contain" src="https://cdn.cxc-group.com/manage/normal-festival-icon.png"></el-image>
                            <el-image v-if="parseInt(value.type) === 1" class="calendar-detail-festival-list-icon" fit="contain" src="https://cdn.cxc-group.com/manage/fo-festival-icon.png"></el-image>
                            <el-image v-if="parseInt(value.type) === 2" class="calendar-detail-festival-list-icon" fit="contain" src="https://cdn.cxc-group.com/manage/dao-festival-icon.png"></el-image>
                        </div>
                        <div class="calendar-detail-festival-list-content">{{value.name}}</div>
                        <div class="calendar-detail-festival-list-right">
                            <i class="el-icon-reading iconfont"></i>
                            <i class="el-icon-delete iconfont" @click="deleteFestival(value.id)"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="calendar-festival-add" @click="addFestivalHandler">
                <i class="el-icon-plus"></i> 添加节日
            </div>
        </el-drawer>

        <el-dialog
            width="40%"
            title="添加节日"
            :visible.sync="addFestival"
            append-to-body
        >
            <div class="add-festival-form">
                <div class="add-festival-form-list">
                    <div class="add-festival-form-label">日期</div>
                    <div class="add-festival-form-input">
                        <div class="form-span">{{form.month}}-{{form.day}}</div>
                        <el-radio-group v-model="form.is_lunar" size="small" @change="addFestivalChangeLunar">
                            <el-radio-button :label="0">阳历</el-radio-button>
                            <el-radio-button :label="1">阴历</el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <div class="add-festival-form-list">
                    <div class="add-festival-form-label">名称</div>
                    <div class="add-festival-form-input">
                        <el-input v-model="form.name" placeholder="请输入节日名称"></el-input>
                    </div>
                </div>
                <div class="add-festival-form-list">
                    <div class="add-festival-form-label">类型</div>
                    <div class="add-festival-form-input">
                        <el-select v-model="form.type" placeholder="请选择类型">
                        <el-option
                            v-for="(value,key) in typeList"
                            :key="key"
                            :label="value.name"
                            :value="value.type">
                            <div class="form-select-list" style="width:100;display:inline-flex;align-items:center;">
                                <el-image style="width:20px;height: 20px;" fit="contain" :src="value.image"></el-image>
                                <span style="margin-left: 10px;">{{value.name}}</span>
                            </div>
                        </el-option>
                    </el-select>
                    </div>
                </div>
                <div class="add-festival-form-list">
                    <div class="add-festival-form-label">详情</div>
                    <div class="add-festival-form-input">
                        <el-input type="textarea":rows="3" v-model="form.info" placeholder="请输入节日介绍"></el-input>
                    </div>
                </div>
                <div class="add-festival-form-list">
                    <div class="add-festival-form-label"></div>
                    <div class="add-festival-form-input">
                        <el-button type="primary" @click="addFestivalSubmit">确认添加</el-button>
                        <el-button @click="cancelFestival">取消添加</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {calendar} from "@/api/public.js"
import {addFestival,deleteFestival} from "@/api/temple.js"
export default {
    props:{
        size:{
            default: "big"
        }
    },
    data() {
        return {
            today:"",
            searchDate:"",
            currentYear:'',
            currentMonth:'',
            currentLunarYear:'',
            currentLunarMonth:'',
            currentLunarDay:'',
            currentLunarZodiac:'',
            week:[
                {'name':'星期日','yao':'日曜日','num':0,'small':'日'},
                {'name':'星期一','yao':'月曜日','num':1,'small':'一'},
                {'name':'星期二','yao':'火曜日','num':2,'small':'二'},
                {'name':'星期三','yao':'水曜日','num':3,'small':'三'},
                {'name':'星期四','yao':'木曜日','num':4,'small':'四'},
                {'name':'星期五','yao':'金曜日','num':5,'small':'五'},
                {'name':'星期六','yao':'土曜日','num':6,'small':'六'},
            ],
            typeList:[
                {'type':0,'name':'法定节日','image':"https://cdn.cxc-group.com/manage/normal-festival-icon.png"},
                {'type':1,'name':'佛门节日','image':"https://cdn.cxc-group.com/manage/fo-festival-icon.png"},
                {'type':2,'name':'道门节日','image':"https://cdn.cxc-group.com/manage/dao-festival-icon.png"},
            ],
            date: [],
            lookDrawer:false,
            look:{
                day:'',
                lunar:[],
                festival:[],
            },
            festival:[],
            addFestival:false,
            form:{
                month:'',
                day:'',
                name:'',
                is_lunar:0,
                type:0,
                info:'',
            },
            params:{
                day:''
            }
        };
    },

    mounted(){
        var currentDate = new Date();
        this.currentYear = currentDate.getFullYear();
        let year = this.currentYear;
        var month = currentDate.getMonth() + 1;
        if (month >= 1 && month <= 9) month = '0' + month
        var day = currentDate.getDate();
        if (day >= 1 && day <= 9) day = '0' + day
        this.today = year +'-'+month +'-' + day
        this.currentMonth = month;

        this.getCalendar()
    },

    methods: {
        goCalendar(){
            this.$router.push({
                name:'calendar'
            })
        },
        getCalendar() {
            calendar(this.params).then(res=>{
                if(res.code === 200){
                    this.date = res.data
                    res.data.forEach((value,key)=>{
                        if(this.currentLunarYear==='' && parseInt(value.month) === parseInt(this.currentMonth)){
                            this.currentLunarYear = value.lunar[3]
                            this.currentLunarMonth = value.lunar[1]
                            this.currentLunarDay = value.lunar[2]
                            this.currentLunarZodiac = value.lunar[6]
                        }
                        if(`${value.year}-${value.month}-${value.day}` === this.today){
                            this.festival = value.festival
                        }
                    })
                }
            })
        },
        changeCalendar(){
            if(this.searchDate===null){
                var currentDate = new Date();
                this.currentYear = currentDate.getFullYear();
                var month = currentDate.getMonth() + 1;
                if (month >= 1 && month <= 9) month = '0' + month
                this.currentMonth = month
                this.params.day = ''
                this.currentLunarYear=''
            }else{
                this.currentYear = this.searchDate.getFullYear();
                var month = this.searchDate.getMonth() + 1;
                if (month >= 1 && month <= 9) month = '0' + month
                this.currentMonth = month;
                console.log(this.currentMonth)

                this.params.day = this.currentYear+'-'+month
                this.currentLunarYear=''
            }
            this.getCalendar()
        },
        lookDetail(e){
            this.lookDrawer = true;
            this.look.day = this.date[e].year+'.'+this.date[e].month+'.'+this.date[e].day
            this.look.lunar = this.date[e].lunar
            this.look.festival = this.date[e].festival
            console.log(this.look)
            this.$forceUpdate()
        },
        addFestivalHandler(){
            this.addFestival = true
            let day = this.look.day.split(".")
            this.form.month = day[1]
            this.form.day = day[2]
        },
        addFestivalChangeLunar(e){
            if(e){
                console.log(this.look.lunar)
                
                this.form.month = this.look.lunar[4] < 10 ? '0'+this.look.lunar[4] : this.look.lunar[4]
                this.form.day = this.look.lunar[5] < 10 ? '0'+this.look.lunar[5] : this.look.lunar[5]
            }else{
                let day = this.look.day.split(".")
                this.form.month = day[1]
                this.form.day = day[2]
            }
        },
        addFestivalSubmit(){
            addFestival(this.form).then(res=>{
                if(res.code === 200){
                    this.getCalendar()
                    this.addFestival = false
                    this.lookDrawer = false
                    this.form = {
                        month:'',
                        day:'',
                        name:'',
                        is_lunar:0,
                        type:0,
                        info:'',
                    }
                }
            })
        },
        cancelFestival(){
            this.addFestival = false
            this.form = {
                month:'',
                day:'',
                name:'',
                is_lunar:0,
                type:0,
                info:'',
            }
        },
        deleteFestival(id){
            deleteFestival(id).then(res=>{
                if(res.code === 200){
                    this.lookDrawer = false
                    this.getCalendar()
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/static/default";
    .calendar-box{
        width:100%;
        display:inline-flex;
        justify-content:space-between;
        .calendar-info{
            width:200px;
            flex-shrink:0;
            text-align:right;
            box-sizing:border-box;
            padding: 20px;
            margin-right: 20px;

            .calendar-month{
                width:100%;
                font-size:150px;
                color:rgba(255, 193, 75, 1);
            }
            .calendar-year{
                width:100%;
                font-size:48px;
            }
            .calendar-lunar{
                color:rgba(0,0,0, 0.2);
                font-weight:bold;
                font-size:21px;
                width:100%;
            }
        }
        .calendar-content{
            width:100%;
            flex-shrink:1;
            .calendar-control{
                width:100%;
                display:inline-flex;
                justify-content:center;
                margin-bottom: 30px;
                .calendar-date{
                    width:100%;
                    display:inline-flex;
                    justify-content:space-between;
                    .calendar-control-today{
                        .calendar-month{
                            color:rgba(255, 193, 75, 1);
                            font-size: 20px;
                            font-weight:bold;
                        }
                        .calendar-lunar{
                            color:rgba(0,0,0,0.45);
                            font-size: 14px;
                            margin-left: 10px;
                        }
                    }
                }
                /deep/ .el-input__inner{
                    border:none;
                    text-align:center;
                    border-radius:0;
                    box-sizing:border-box;
                    padding: 30px;
                    font-size: 20px;
                    border-bottom:2px solid rgba(255, 193, 75, 1);
                }
                .el-input__inner:hover{
                    border-bottom:2px solid rgba(255, 193, 75, 1);
                }
            }
            .calendar-day{
                width:100%;
                display:flex;
                flex-wrap:wrap;
                justify-content:space-around;
                &-list{
                    width:14.28%;
                    margin-bottom: 20px;
                    position:relative;
                    .festival-icon{
                        position:absolute;
                        right:0;
                        top:0;
                        img{
                            width:15px;
                            height: 15px;
                        }
                    }
                    &-week{
                        width:100%;
                        font-size:20px;
                        font-weight:bold;
                        text-align:right;
                        .yao{
                            font-weight:400;
                            font-size: 14px;
                            color:rgba(0,0,0, 0.4);
                            margin-left: 10px;
                        }
                    }
                    &-week-small{
                        width:100%;
                        font-size:16px;
                        font-weight:bold;
                        text-align:center;
                        
                    }
                    &-info:hover{
                        .calendar-day-list-info-date{
                            color:rgba(195, 39, 43,.8) !important;
                            .rest{
                                color:rgba(195, 39, 43,.8) !important;
                            }
                        }
                    }
                    &-info{
                        width:100%;
                        box-sizing:border-box;
                        padding: 10px;

                        &-date{
                            width:100%;
                            text-align:right;
                            font-size: 24px;
                            font-weight:bold;
                            cursor:default;
                        }
                        &-lunar{
                            width:100%;
                            text-align:right;
                            font-size: 14px;
                            margin-top:5px;
                            cursor:default;
                        }
                        &-festival{
                            width:100%;
                            margin-top: 5px;
                            cursor:default;
                            &-list{
                                width:100%;
                                display:inline-flex;
                                align-items:center;
                                justify-content:flex-end;
                                margin-bottom: 5px;

                                &-tag{
                                    display:inline-flex;
                                    align-items:center;
                                    font-weight:bold;
                                }
                                &-icon{
                                    width:20px;
                                    height: 20px;
                                    margin-right:5px;
                                }
                                &-name{
                                    font-size:14px;
                                }
                            }
                        }
                    }
                    .rest{
                        color:rgba(255, 193, 75, 1);
                    }
                    .today{
                        color:#c3272b;
                    }
                }
                .not-current{
                    opacity: 0.2;
                }
            }
        }
        .fo-icon{
            color: #D9BC94;
        }
        .dao-icon{
            color: #000000;
        }
        .normal-icon{
            color: #c3272b;
        }
    }
    .calendar-detail{
        width:100%;
        position: relative;
    }
    .calendar-festival-add{
        width:calc(100% - 40px);
        box-sizing: border-box;
        padding: 10px 20px;
        position: absolute;
        bottom: 40px;
        left: 20px;
        border-radius: 10px;
        font-size: 16px;
        text-align:center;
        background-color:rgba(255, 193, 75, 1);
        color:white;
        cursor:default;
    }
    .calendar-look-title{
        border-top: 10px solid rgba(255, 193, 75, 1);
        width:100%;
        height: 200px;
        background-color:rgb(71,69,70);
        display:flex;
        align-items:center;
        justify-content:center;
        &-date{
            color:white;
            font-size:48px;
            font-weight:bold;
        }
        &-lunar{
            width: 274px;
            height: 2px;
            position: relative;
            background-color:rgba(255, 193, 75, 1);
            margin-top: 20px;
            &-text{
                position:absolute;
                background-color:rgb(71,69,70);
                padding-left:20px;
                color: rgba(255, 193, 75, 1);
                font-size:16px;
                right: 0;
                top: -11px;
            }
        }
    }
    .calendar-detail-festival{
        width:100%;
        box-sizing:border-box;
        padding: 20px;
        &-list{
            width:100%;
            display:inline-flex;
            justify-contents:space-between;
            align-items:center;
            box-sizing:border-box;
            padding: 10px 20px;
            border-radius: 10px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
            margin-bottom: 10px;

            &-left{
                width:50px;
                height: 50px;
                flex-shrink:0;
                box-sizing:border-box;
                padding: 5px;
                border:1px solid #D9BC94;
                border-radius:60px;
                margin-right: 10px;
            }
            &-content{
                width:100%;
                flex-shrink:1;
                font-size: 16px;
                font-weight:bold;
                letter-spacing:1px;
            }
            &-right{
                flex-shrink:0;
                .iconfont{
                    font-size:20px;
                    margin-left: 10px;
                }
            }
            &-icon{
                width:40px;
                height: 40px;
            }
        }
    }
    .add-festival-form{
        width:100%;
        &-list{
            width:100%;
            display: inline-flex;
            justify-content:space-between;
            align-items:center;
            margin-bottom:20px;
        }
        &-label{
            width:100px;
            flex-shrink:0;
        }
        &-input{
            width:100%;
            flex-shrink:1;
            display: inline-flex;
            align-items:center;
            .form-span{
                margin-right:20px;
            }
        }
    }
</style>
<style>
    
    .el-card__body{
        width: 100%;
    }
</style>