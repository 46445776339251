<template>
  <section style="height: 100%">
    <div class="container">
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>客户列表</h1></div>
          <div class="control-box" style="margin-top: 10px;">
            <div>
              <el-input class="control-box-input" style="width:217px;margin-left:10px;margin-top:10px;" v-model="params.keyword" placeholder="请输入客户名称/手机号" clearable></el-input>
              <el-select v-model="params.type" placeholder="类别" clearable style="margin-left:10px;width:150px;margin-top:10px;">
                <el-option
                  v-for="item in typeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.status" placeholder="状态" clearable style="margin-left:10px;width:150px;margin-top:10px;">
                <el-option
                  v-for="item in statusOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-input class="control-box-input" style="width:117px;margin-left:10px;margin-top:10px;" v-model="params.level" placeholder="请输入等级" clearable></el-input>
              <el-select v-model="params.origin" placeholder="客户来源" filterable clearable style="margin-left:10px;margin-top:10px;" v-if="groupID===1">
                <el-option
                  v-for="item in originOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.maturity" placeholder="合作倾向" style="margin-left:10px;width:150px;margin-top:10px;" clearable @change="provinceClick()">
                <el-option
                  v-for="item in maturityOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.sector" placeholder="行业" style="margin-left:10px;width:150px;margin-top:10px;" clearable @change="provinceClick()">
                <el-option
                  v-for="item in sectorOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.province_id" placeholder="请选择省" style="margin-left:10px;width:150px;margin-top:10px;" clearable @change="provinceClick()">
                <el-option
                  v-for="item in provinceList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.city_id" placeholder="请选择市" clearable style="margin-left:10px;width:150px;margin-top:10px;" @change="cityClick()">
                <el-option
                  v-for="item in cityList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.area_id" placeholder="请选择地区" clearable style="margin-left:10px;width:150px;margin-top:10px;" @change="areaClick()">
                <el-option
                  v-for="item in areaList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-button icon="el-icon-search" @click="searchData" style="margin-left:10px;margin-top:10px;">搜索</el-button>
              <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd" style="margin-top:10px;">添加客户</el-button>
            </div>
          </div>
        </div>
        <el-table
          :data="table"
          style="width: 100%;"
          tooltip-effect="dark"
          empty-text="暂无数据"
          class="table"
        >
          <el-table-column label="客户名称" prop="name" align="left"></el-table-column>
          <el-table-column label="联系方式" prop="phone" align="center" width="120"></el-table-column>
          <el-table-column label="类别" prop="type" align="center" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.type === 0">-</span>
              <span v-else>{{scope.row.type}}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="status" align="center" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.status === ''">-</span>
              <span v-else>{{scope.row.status}}</span>
            </template>
          </el-table-column>
          <el-table-column label="公司" prop="company" align="center" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.company === ''">-</span>
              <span v-else>{{scope.row.company}}</span>
            </template>
          </el-table-column>
          <el-table-column label="等级" prop="level" align="center" width="120"></el-table-column>
          <el-table-column label="人物标签" prop="tag" align="center" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.tag === 0">-</span>
              <span v-else>{{scope.row.tag}}</span>
            </template>
          </el-table-column>
          <el-table-column label="财富" prop="wealth" align="center" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.wealth === 0">-</span>
              <span v-else>{{scope.row.wealth}}</span>
            </template>
          </el-table-column>
          <el-table-column label="性格" prop="character" align="center" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.character === 0">-</span>
              <span v-else>{{scope.row.character}}</span>
            </template>
          </el-table-column>
          <el-table-column label="客户来源" prop="origin" align="center" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.origin === 0">-</span>
              <span v-else>{{scope.row.origin}}</span>
            </template>
          </el-table-column>
          <el-table-column label="合作倾向" prop="maturity" align="center" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.maturity === 0">-</span>
              <span v-else>{{scope.row.maturity}}</span>
            </template>
          </el-table-column>
          <el-table-column label="行业" prop="sector" align="center" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.sector === 0">-</span>
              <span v-else>{{scope.row.sector}}</span>
            </template>
          </el-table-column>
          <el-table-column label="省份" prop="province" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.province_id === 0">-</span>
              <span v-else>{{scope.row.province}}</span>
            </template>
          </el-table-column>
          <el-table-column label="城市" prop="city" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.city_id === 0">-</span>
              <span v-else>{{scope.row.city}}</span>
            </template>
          </el-table-column>
          <el-table-column label="地区" prop="area" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.area_id === 0">-</span>
              <span v-else>{{scope.row.area}}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="info" align="center" width="120">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <p> {{ scope.row.info }}</p>
                <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                  <span>{{scope.row.info}}</span>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="created_at" align="center" width="170" ></el-table-column>
          <!-- <el-table-column label="信用代码" prop="credit_code" align="center" width="170"></el-table-column> -->

          <el-table-column label="操作" width="" align="center" fixed="right">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: space-between;
                "
              >
                <el-link style="color: #409eff;" v-if="(scope.row.status !==2 || scope.row.status !==3)" @click="handleDetail(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="查看详情" placement="top-start">
                    <i class="el-icon-view" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;margin-left:10px;margin-right:10px;" @click="editTable(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-dropdown @command="command($event,scope.row)">
                  <span class="el-dropdown-link" style="color: #FFC14B">
                    <el-tooltip class="item" effect="dark" content="更多操作" placement="top-start">
                      <i class="el-icon-arrow-down" style="font-size:16px;"></i>
                    </el-tooltip>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="delete" v-if="admin_permission.indexOf('deleted')>-1">删除客户</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next,jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </div>
    <el-drawer
      title="更改客户信息"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <edit-comp v-if="editDrawer" @close="handleClose" @success="handleDrawerSuccess" :id="editID" ref="newForm"></edit-comp>
    </el-drawer>

    <!-- 添加规则 -->
    <el-drawer
      title="添加客户"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <add-comp
        @close="handleClose"
        @success="handleDrawerSuccess"
        @clear="clearContent"
        ref="newForm"
        v-if="addDrawer"
      ></add-comp>
    </el-drawer>
    
    <el-drawer
      title="客户详情"
      :visible.sync="detailDrawer"
      :direction="direction"
      :before-close="handleClose"
      size="90%"
    >
      <detail-comp v-if="detailDrawer" @close="handleClose" :id="editID"></detail-comp>
    </el-drawer>
  </section>
</template>

<script>
  import { getList, addData,editData,getTypeList,getStatusList,getOriginList,getMaturityList,getSectorList,deleteData} from "@/api/customer.js";
  import { getProvince,getCity,getArea} from "@/api/public.js";
  import { getGroups} from "@/api/system.js";

  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  import addComp from "./components/add.vue";
  import editComp from "./components/edit.vue";
  import detailComp from "./components/detail.vue"

  let page_name = 'place_list'
export default {
  name: "App",
  components:{addComp,editComp,detailComp,UploadQiniu},

  data() {
    return {
      admin_permission:[],
      statusOptions: [],//状态
      typeOptions:[],//类别
      originOptions:[],//客户来源
      maturityOptions:[],//合作倾向
      sectorOptions:[],//行业
      provinceList:[],
      cityList:[],
      areaList:[],
      province_id:"",
      city_id:"",
      params: {
        page: 1,
        page_count: 10,
        keyword:'',
        gender:'',
        mobile:'',
      },
      groupList:[],
      groupID:'',
      table:[],
      editID: '',
      total: 0,
      addDrawer: false,
      editDrawer: false,
      detailDrawer: false,
      direction: "rtl",
      addTradeParams: {
        name: "",
        parent_id: 0
      },
      visible: false,
      scopeForm:{
        id:'',
        sort: ''
      }
    };
  },

  mounted() {
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    this.getTableData();
    this.searchType();
    
    this.groupID = this.$store.state.user.userData.group_id
    getGroups().then(res=>{
      if(res.code === 200){
        this.groupList = res.data
      }
    })
    getProvince().then(res=>{
      if(res.code === 200){
        this.provinceList = res.data
      }
    })
    //状态数组
    getStatusList().then(res=>{
      if(res.code === 200){
        this.statusOptions = res.data
        console.log(this.statusOptions)
      }
    })
    //客户来源数组
    getOriginList().then(res=>{
      if(res.code === 200){
        this.originOptions = res.data
        console.log(this.originOptions)
      }
    })
    //合作倾向数组
    getMaturityList().then(res=>{
      if(res.code === 200){
        this.maturityOptions = res.data
        console.log(this.maturityOptions)
      }
    })
    //行业数组
    getSectorList().then(res=>{
      if(res.code === 200){
        this.sectorOptions = res.data
        console.log(this.sectorOptions)
      }
    })
  },

  methods: {
    getTableData() {
      getList(this.params).then(res=>{
        if(res.code === 200){
          this.table = res.data.data
          console.log(this.table)
          this.params.page = res.data.current_page
          this.params.page_count = res.data.per_page
          this.total = res.data.total
        }
      })
    },
    searchData(){
      this.params.page=1
      this.getTableData()
    },
    searchType(){
      getTypeList().then(res=>{
        if(res.code === 200){
          this.typeOptions = res.data
          console.log(this.typeOptions)
        }
      })
    },
    provinceClick(){
      let that = this
      this.provinceList.forEach(item => {
        if(item.id == that.params.province_id ){
          that.province_id = item.province_id
          console.log(that.province_id)
        }
      });
      getCity(that.province_id).then(res=>{
        if(res.code === 200){
          console.log(res.data)
          this.cityList = res.data
        }
      })
    },
    cityClick(){
      let that = this
      this.cityList.forEach(item => {
        if(item.id == that.params.city_id ){
          that.city_id = item.city_id
          console.log(that.city_id)
        }
      });
      getArea(that.city_id).then(res=>{
        if(res.code === 200){
          console.log(res.data)
          this.areaList = res.data
        }
      })
    },
    command(e,item){
      if(e === 'delete'){
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
        .then(_ => {
          deleteData(item.id).then(res=>{
            this.getTableData()
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
            });
          })
        })
        .catch(_ => {});
      }
    },

    handleDetail(e){
      console.log(e)
      this.editID=e
      this.detailDrawer = true
    },
    // 打开添加
    handleOpenAdd() {
      this.addDrawer = true;
    },
    // 关闭添加/修改
    handleClose() {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.addDrawer = false;
          this.editDrawer = false;
          this.detailDrawer = false;
          this.dialogPerVisible=false
          this.dialogDeductionVisible = false
          this.dialogMediaVisible = false
        })
        .catch((_) => {});
    },

    editTable(id){
      this.editID = id
      console.log(this.editID)
      this.editDrawer = true
    },
    // 添加/修改成功
    handleDrawerSuccess() {
      this.getTableData()
      this.addDrawer = false;
      this.editDrawer = false;
      // this.searchSet()
      this.$refs.newForm.$refs.form.resetFields();
    },
    // 清空子组件
    clearContent() {
      this.$refs.newForm.$refs.form.resetFields();
    },
    
    // 分页
    prevpage() {
      this.params.page -= 1;
      this.getTableData();
    },
    nextpage() {
      this.params.page += 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getTableData();
    },
  }
};
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;

    .table {
      margin-top: 24px !important;
    }

    .toolbar {
      background: #fff;
      width: 100%;
      padding: 16px 32px 26px 0;
    }
  }

  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    padding-right: 8px;
  }
  .el-tree-node__expand-icon.is-leaf{
    color: #c0c4cc;
  }
</style>