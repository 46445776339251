<template>
  <section>
    <div class="container">
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <!-- <div class="search-box"><h1>日历</h1></div> -->
          <!-- <div class="control-box" style="margin-top: 10px;"></div> -->
        </div>
        <calendar-component></calendar-component>
      </el-main>
    </div>
  </section>
</template>

<script>
let page_name="calendar"
import {calendar} from "@/api/public.js"
import calendarComponent from "@/views/components/calendar.vue"
export default {
  name: "calendar",
  components:{calendarComponent},

  data() {
    return {
      date: [],
      params:{
        'day':''
      }
    };
  },

  mounted(){
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    this.getCalendar()
  },

  methods: {
    getCalendar() {
      calendar(this.params).then(res=>{
        if(res.code === 200){
          this.date = res.data
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/static/default";
.el-drawer {
  overflow: auto !important;
}
.users {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.container {
  background-color: #fff !important;
  padding: 0 32px;
  .table {
    margin-top: 24px !important;
  }
  .toolbar {
    background: #fff;
    width: calc(100% + 64px);
    padding: 16px 32px 26px 0;
    margin-left: -32px;
  }
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>