import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = ``

export function getList(data = {}) {
  return axios.request({
    url: `${url}/temples`,
    method: "get",
    params: data,
  })
}
export function getListById(id,data = {}) {
  return axios.request({
    url: `${url}/temple/${id}`,
    method: "get",
    params: data,
  })
}

export function getListSelect(data = {}) {
  return axios.request({
    url: `${url}/temples/select`,
    method: "get",
    params: data,
  })
}

export function deleteData(id,data) {
  return axios.request({
    url: `${url}/temple/${id}`,
    method: "delete",
    params:data,
  })
}

export function addData(data) {
  return axios.request({
    url: `${url}/temple`,
    method: "post",
    data: data,
  })
}

export function editData(id, data) {
  return axios.request({
    url: `${url}/temple/${id}`,
    method: "put",
    data: data,
  })
}


export function storeAppointment(id, data) {
  return axios.request({
    url: `${url}/temple/appointment/${id}`,
    method: "put",
    data: data,
  })
}
export function storeRefuse(id, data) {
  return axios.request({
    url: `${url}/temple/refuse/${id}`,
    method: "put",
    data: data,
  })
}

export function addFestival(data) {
  return axios.request({
    url: `${url}/festival`,
    method: "post",
    data: data,
  })
}

export function deleteFestival(id,data={}) {
  return axios.request({
    url: `${url}/festival/${id}`,
    method: "delete",
    data: data,
  })
}
