const device='manage'

let fetch = ({event, data})=>{
  return JSON.stringify({
    event,
    device,
    data:{
      api_token:localStorage.getItem('token'),
      data
    }
  })
}

export function ping(data = {}) {
  return fetch({
    event:'ping',
    data
  })
}

export function QueryTheInventory(data){
  console.log(data)
  return fetch({
    event:'device_hardware',
    data
  })
}

