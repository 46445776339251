<template>
  <section>
    <div class="container">
      <div class="worker-tooltips" style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start">
        <div class="search-box"><h1>订阅</h1></div>
        <div class="control-box">
          <el-select v-model="params.type" placeholder="请选择状态" clearable style="margin-left:10px;width:150px;margin-top: 10px;">
            <el-option
              v-for="item in orderType"
              :key="item.type"
              :label="item.name"
              :value="item.type">
            </el-option>
          </el-select>
          <el-button icon="el-icon-search" @click="getList" style="margin-left:10px;margin-top: 10px;">搜索</el-button>
          <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd" style="margin-right:10px;margin-top: 10px;" v-if="admin_permission.indexOf('add')>-1">添加内容</el-button>
        </div>
      </div>
      <!-- 用户列表 -->
      <el-table :data="list" style="width: 100%" class="table" fit>
        <!-- 数据展示区 -->
        <el-table-column
          label="订阅名称"
          prop="name"
          align="center"
          width="100px"
        ></el-table-column>
        <el-table-column
          label="订阅类别"
          prop="status"
          align="center"
          width="100px"
        >
          <template slot-scope="scope">
            <el-tag type="primary" effect="light" v-if="parseInt(scope.row.type)===0">{{scope.row.type_name}}</el-tag>
            <el-tag type="success" effect="light" v-if="parseInt(scope.row.type)===1">{{scope.row.type_name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="订阅内容"
          prop="command"
          align="left"
          width="130px"
        >
          <template slot-scope="scope">
            <div v-if="parseInt(scope.row.type)===0">连续 {{scope.row.number}}{{scope.row.unit_name}}</div>
            <div v-if="parseInt(scope.row.type)===1">{{scope.row.serial}}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="备注"
          prop="command"
          align="left"
        ></el-table-column>
        <el-table-column label="操作" width="180" align="center" fixed="right"  v-if="admin_permission.indexOf('update')>-1 || admin_permission.indexOf('deleted')>-1 || admin_permission.indexOf('examine')>-1">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" v-if="admin_permission.indexOf('update')>-1">更改</el-button>
              <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)" v-if="admin_permission.indexOf('deleted')>-1">删除</el-button>
            </template>
          </el-table-column>
      </el-table>

      <!--分页-->
      <el-col :span="24" class="toolbar">
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page.sync="params.page"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float: right"
        ></el-pagination>
      </el-col>

      <!-- 添加内容 -->
      <el-drawer
        title="添加内容"
        :visible.sync="addDrawer"
        :direction="direction"
        :before-close="handleClose"
        
      >
        <add
          @close="handleClose"
          @success="handleDrawerSuccess"
          ref="newForm"
        ></add>
      </el-drawer>

      <!-- 修改内容 -->
      <el-drawer
        title="更改内容"
        :visible.sync="editDrawer"
        :direction="direction"
        :before-close="handleClose"
      >
        <edit
          ref="newForm"
          v-if="editDrawer"
          @close="handleClose"
          @success="handleDrawerSuccess"
          :id="editParam.id"
        ></edit>
      </el-drawer>
    </div>
  </section>
</template>
<script>
import {getSubscribes,changeHallStatus,deleteSubscribeData} from '@/api/dictionaries.js'
import edit from "./components/edit.vue";
import add from "./components/add.vue";
let page_name = 'subscribe_list'
export default {
  name: "subscribe_list",
  components: {edit,add},
  data() {
      return {
        admin_permission:[],
        params: {
            page:1,
            type:''
        },
        orderType:[
          {type:0, name:'产品订阅'},
          {type:1, name:'消息订阅'},
        ],
        list: [],
        total: 0,
        editParam:{
          id: 0,
        },
        editDrawer: false,
        addDrawer: false,
        direction: "rtl",
      };
  },

  mounted(){
    this.getList();
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
  },

  methods: {
    // 获取分类列表
    getList(){
      console.log(this.params)
      getSubscribes(this.params).then((res)=>{
          if(res.code===200){
            console.log(res.data.data)
              this.list=res.data.data;
              this.total=res.data.total;
              this.params.page = res.data.current_page
          }
      })
    },
    handleGetTag(e){
      this.params.name=e
    },

    // 删除分类
    handleDelete(index, row) {
      this.$confirm('确认删除吗？').then(_ => {
        deleteSubscribeData(row.id)
          .then(( data ) => {
              if(data.code === 200){
              this.getList();
              }
          }).catch((error)=>{});
      }).catch(_ => {});
    },

    // 修改用户
    handleEdit(index, row) {
      console.log(row)
      this.editParam.id = row.id;
      this.editDrawer = true;
    },
    // 新增
    handleOpenAdd() {
      this.addDrawer = true;
    },
    // 关闭弹窗
    handleClose() {
      this.$confirm("确认关闭？").then((_) => {
          this.editDrawer = false;
          this.addDrawer = false;
          this.$refs.newForm.$refs.form.resetFields(); //清空子组件的内容
      }).catch((_) => {});
    },

    // 修改成功
    handleDrawerSuccess(form) {
      this.getList();
      this.handleClose();
      this.$refs.newForm.$refs.form.resetFields();// 清空表单内容
    },
    // 返回
    handleBack(){
      this.getList();
    },

    // 分页
    prevpage(){
      this.params.page -=1;
      this.getList()
    },
    nextpage(){
      this.params.page +=1;
      this.getList()
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getList()
    },
  }
};
</script>

<style scoped lang="scss">
    @import "@/static/default";
    .el-drawer {
        overflow: auto !important;
    }

    .container {
      background-color: #fff !important;
      padding: 0 32px;
      .table {
        margin-top: 24px !important;
      }
      .toolbar {
        background: #fff;
        width: calc(100% + 64px);
        padding: 16px 32px 26px 0;
        margin-left: -32px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .word{
          margin-right:50px;
          font-size:14px;
          span{
            font-size:18px;
          }
        }
      }
      .search-box{
        margin-top:20px;
        padding-left:20px;
      }
      .control-box{
        width: 100%;
        display:flex;
        flex-wrap: wrap;
        padding-left:20px;
        padding-top:10px;
        &-input{
          width: 150px;
        }
      }
    }

    .demo-table-expand {
        font-size: 0;
    }
    .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }
    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }
</style>