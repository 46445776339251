<template>
  <section style="height: 100%">
    <div class="container">
      <el-aside style="padding-right: 20px;border-right:1px solid #e9eaf2;width: 150px;">
        <h1>组织</h1>
        <el-tree
          style="margin-top: 10px;margin-bottom:10px;"
          :data="status"
          node-key="id"
          icon-class="el-icon-s-flag"
          default-expand-all
          :expand-on-click-node="false"
          :highlight-current="true"
          :current-node-key="treeCurrentNode"
        >
          <span
            class="custom-tree-node"
            slot-scope="{ node, data }"
            @click="showTreeWorker(data.id, data.pid)"
          >
            <span>
              {{ data.label }}
            </span>
          </span>
        </el-tree>
      </el-aside>
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>企业列表</h1></div>
          <div class="control-box" style="margin-top: 10px;">
            <el-input class="control-box-input" v-model="params.name" style="margin-right:10px;margin-top:10px;" placeholder="请输入搜索名字" clearable></el-input>
            <el-select v-model="params.province_id" placeholder="请选择省份" clearable style="margin-right:10px;margin-top:10px;" @change="provinceClick()">
              <el-option
                v-for="item in provinceList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.city_id" placeholder="请选择市" clearable style="margin-right:10px;margin-top:10px;" @change="cityClick()">
              <el-option
                v-for="item in cityList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.area_id" placeholder="请选择地区" clearable style="margin-right:10px;margin-top:10px;" @change="areaClick()">
              <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-button icon="el-icon-search" @click="getTableData" style="margin-right:10px;margin-top:10px;">搜索</el-button>
            <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd" style="margin-right:10px;margin-top:10px;" v-if="admin_permission.indexOf('add')>-1">添加企业</el-button>
          </div>
        </div>
        <el-table
          :data="table"
          style="width: 100%;"
          tooltip-effect="dark"
          empty-text="暂无数据"
          class="table"
          :highlight-current-row="true"
          v-if="height>0"
          @sort-change="sortChange"
          :max-height="height"
        >
          <el-table-column label="创建时间" prop="created_at" align="left" width="170px"></el-table-column>
          <el-table-column label="企业名称" prop="name" align="left" width="240px"></el-table-column>
          <el-table-column label="省份" prop="province_name" align="left" width="100px">
            <template slot-scope="scope">
              <el-popover placement="top-start"
                          trigger="hover"
                          :content="scope.row.province_name">
                  <div slot="reference" class="oneLineCls">{{scope.row.province_name}}</div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="服务费" prop="service_fee" align="left" width="120px"></el-table-column>
          <el-table-column label="公司地址" prop="address" align="left" width="120px">
            <template slot-scope="scope">
              <el-popover placement="top-start"
                          trigger="hover"
                          :content="scope.row.address">
                  <div slot="reference" class="oneLineCls">{{scope.row.address}}</div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="占股比例" prop="share_proportion" align="left" width="100px">
            <template slot-scope="scope">
              <el-popover placement="top-start"
                          trigger="hover"
                          :content="scope.row.share_proportion+'%'">
                  <div slot="reference" class="oneLineCls">{{scope.row.share_proportion}}%</div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="注册资金" prop="registered_capital" align="left" width="120px">
            <template slot-scope="scope">
              <el-popover placement="top-start"
                          trigger="hover"
                          :content="scope.row.registered_capital+'万元'">
                  <div slot="reference" class="oneLineCls">{{scope.row.registered_capital}}万元</div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="工商注册时间" prop="registion_at" align="left" width="120px">
            <template slot-scope="scope">
              <el-popover placement="top-start"
                          trigger="hover"
                          :content="scope.row.registion_at">
                  <div slot="reference" class="oneLineCls">{{scope.row.registion_at}}</div>
              </el-popover>
            </template>
          </el-table-column>

          <!-- <el-table-column label="人员总数" prop="personal" align="left" width="100px">
            <template slot-scope="scope">
              <el-popover placement="top-start"
                          trigger="hover"
                          :content="scope.row.personal+'人'">
                  <div slot="reference" class="oneLineCls">{{scope.row.personal}}人</div>
              </el-popover>
            </template>
          </el-table-column> -->

          <!-- <el-table-column label="覆盖区域" prop="areas_list" align="left" width="160px">
            <template slot-scope="scope">
              <el-popover placement="top-start"
                          trigger="hover"
                          :content="scope.row.areas_list">
                  <div slot="reference" class="oneLineCls">{{scope.row.areas_list}}</div>
              </el-popover>
            </template>
          </el-table-column> -->

          <el-table-column label="月度评分" prop="month_score" align="left" width="180px" sortable="custom" >
            <template slot-scope="scope">
              <el-rate
                v-model="scope.row.month_score"
                disabled
                :colors="rateColors"
              >
              </el-rate>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="70" align="center" fixed="right">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: space-between;
                "
              >
                <el-link style="color: #409eff;" v-if="(scope.row.status !==2 || scope.row.status !==3) && admin_permission.indexOf('update')>-1 " @click="editTable(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;margin-left:10px;" @click="handleDelete(scope.row.id)"  v-if="admin_permission.indexOf('deleted')>-1 && scope.row.role_id!=='admin'">
                  <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
                  <i class="el-icon-delete" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-dropdown @command="command($event,scope.row)" v-if="groupID===1">
                  <span class="el-dropdown-link" style="color: #409eff">
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="score" v-if="admin_permission.indexOf('update')>-1">修改月度评分</el-dropdown-item>
                  <el-dropdown-item command="totalScore" v-if="admin_permission.indexOf('update')>-1">修改总评分</el-dropdown-item>
                  <el-dropdown-item command="qrcode">推广码</el-dropdown-item>
                  <el-dropdown-item command="deduction" v-if="groupID===1">扣量</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next,jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </div>
    <el-dialog
      title="修改月度评分"
      :visible.sync="dialogScoreVisible"
      width="30%"
      :before-close="handleClose">
      <div style="display: flex;justify-content: flex-start;align-items: center;">
        <div style="width:100px;">月度评分：</div>
        <el-select v-model="month_score" placeholder="请选择">
          <el-option
            v-for="item in monthOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改总评分"
      :visible.sync="dialogTotalScoreVisible"
      width="30%"
      :before-close="handleClose">
      <div style="display: flex;justify-content: flex-start;align-items: center;margin-top:20px;">
        <div style="width:100px;">总评分：</div>
        <el-select v-model="comprehensive_score" placeholder="请选择">
          <el-option
            v-for="item in monthOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="totalConfirm()">确 定</el-button>
      </span>
    </el-dialog>
    
    <el-dialog
      title="代理商小程序推广码"
      :visible.sync="dialogCodeVisible"
      width="30%"
      :before-close="handleClose">
      <el-image class="img" fit="contain" :src="code"></el-image>
    </el-dialog>
    <el-dialog
      title="扣量操作"
      :visible.sync="dialogDeductionVisible"
      width="30%"
      :before-close="handleClose">
      <div>
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-top:20px;">
          <div style="width:120px;">扣量保护期限 ：</div>
          <el-switch
            v-model="deductionShow"
            active-color="#13ce66"
            inactive-color="#ccc"
            @change="deductionChange">
          </el-switch>
        </div>
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-top:20px;" v-if="deduction === 1">
          <div style="width:120px;">扣量保护期限 ：</div>
          <el-input style="width:200px;" v-model="deduction_protect" placeholder="请输入扣量保护期限"></el-input>
        </div>
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-top:20px;" v-if="deduction === 1">
          <div style="width:120px;">扣量比例(%) ：</div>
          <el-input style="width:200px;" v-model="deduction_percent" placeholder="请输入扣量比例">
            <template slot="append">%</template>
          </el-input>
        </div>
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-top:20px;" v-if="deduction === 1">
          <div style="width:120px;">扣量起订金额 ：</div>
          <el-input style="width:200px;" v-model="deduction_begin" placeholder="请输入扣量起订金额"></el-input>
        </div>
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-top:20px;" v-if="deduction === 1">
          <div style="width:120px;">扣量扣款比例 ：</div>
          <el-input style="width:200px;" v-model="deduction_price_percent" placeholder="请输入扣量扣款比例"></el-input>
        </div>
      </div>
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="deductionCancel()">取 消</el-button>
        <el-button type="primary" @click="deductionConfirm()">确 定</el-button>
      </span>
    </el-dialog>
    <el-drawer
      title="更改账号"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <edit-comp v-if="editDrawer" @close="handleClose" @success="handleDrawerSuccess" :id="editID" ref="newForm"></edit-comp>
    </el-drawer>

    <!-- 添加规则 -->
    <el-drawer
      title="添加账号"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <add-comp
        @close="handleClose"
        @success="handleDrawerSuccess"
        @clear="clearContent"
        ref="newForm"
        v-if="addDrawer"
      ></add-comp>
    </el-drawer>
  </section>
</template>

<script>
  import { searchGroups,deleteGroup,updateComprehensive,updateMonth,getCode,updateDeduction} from "@/api/system.js";
  import { getProvince,getCity,getArea} from "@/api/public.js";

  import addComp from "./components/add.vue";
  import editComp from "./components/edit.vue";

  let page_name = 'system_group'
  export default {
    name: "App",
    components:{addComp,editComp},
    data() {
      return {
        admin_permission:[],
        height:0,
        monthOptions:[
          {
            label:"1星",
            value:1
          },
          {
            label:"2星",
            value:2
          },
          {
            label:"3星",
            value:3
          },
          {
            label:"4星",
            value:4
          },
          {
            label:"5星",
            value:5
          },
        ],
        provinceList:[],
        cityList:[],
        areaList:[],
        province_id:"",
        city_id:"",
        status: [
          {id:"",label:"全部"},
          {id:0,label:"企业旗下"},
          {id:1,label:"政府部门"},
        ],
        treeCurrentNode:"",
        code:'',
        params: {
          page: 1,
          page_count: 10,
          username:'',
          gender:'',
          mobile:'',
          type:'',
          nickname:'',
          month_score_sort:'',
          comprehensive_score_sort:''
        },
        table:[],
        rateColors:['#99A9BF', '#F7BA2A', '#FF9900'],
        month_score:0,
        comprehensive_score:0,
        service: [],
        editID: '',
        total: 0,
        groupID:'',
        outerVisible: false,
        innerVisible: false,
        dialogScoreVisible:false,
        dialogTotalScoreVisible:false,
        dialogCodeVisible:false,
        dialogDeductionVisible:false,//扣量弹窗
        deductionID:'',
        deductionShow:false,
        deduction:0,
        deduction_protect :30,
        deduction_percent :10,
        deduction_begin:0,
        deduction_price_percent:10,
        addDrawer: false,
        editDrawer: false,
        direction: "rtl",
        addTradeParams: {
          name: "",
          parent_id: 0
        },
        visible: false,
        scopeForm:{
          id:'',
          sort: ''
        }
      };
    },

    methods: {
      getTableData() {
        searchGroups(this.params).then(res=>{
          if(res.code === 200){
            this.table = res.data.data
            this.params.page = res.data.current_page
            this.params.page_count = res.data.per_page
            this.total = res.data.total
            console.log(this.table)
          }
        })
      },
      sortChange(e){
        if(e.prop === 'month_score'){
          if(e.order === 'ascending') this.params.month_score_sort = 'asc'
          if(e.order === 'descending') this.params.month_score_sort = 'desc'
          this.getTableData()
        }
        if(e.prop === 'comprehensive_score'){
          if(e.order === 'ascending') this.params.comprehensive_score_sort = 'asc'
          if(e.order === 'descending') this.params.comprehensive_score_sort = 'desc'
          this.getTableData()
        }
      },
      provinceClick(){
        let that = this
        this.provinceList.forEach(item => {
          if(item.id == that.params.province_id ){
            that.province_id = item.province_id
            console.log(that.province_id)
          }
        });
        getCity(that.province_id).then(res=>{
          if(res.code === 200){
            console.log(res.data)
            this.cityList = res.data
          }
        })
      },
      cityClick(){
        let that = this
        this.cityList.forEach(item => {
          if(item.id == that.params.city_id ){
            that.city_id = item.city_id
            console.log(that.city_id)
          }
        });
        getArea(that.city_id).then(res=>{
          if(res.code === 200){
            console.log(res.data)
            this.areaList = res.data
          }
        })
      },
      command(e,item){
        if(e === 'score'){
          this.dialogScoreVisible = true
          this.scoreID = item.id
          this.month_score = item.month_score
        }
        if(e === 'totalScore'){
          this.dialogTotalScoreVisible = true
          this.scoreID = item.id
          this.comprehensive_score = item.comprehensive_score
        }
        if(e === 'qrcode'){
          this.dialogCodeVisible = true
          getCode({group_id:item.id}).then(res=>{
            if(res.code === 200){
              this.code = res.data
            }
          })
        }
        if(e === 'deduction'){
          console.log(item)
          this.dialogDeductionVisible = true
          this.deductionID = item.id
          this.deduction = item.deduction
          if(this.deduction==1){
            this.deductionShow = true
          }else{
            this.deductionShow = false
          }
          this.deduction_protect = item.deduction_protect
          this.deduction_begin = item.deduction_begin
          this.deduction_percent = item.deduction_percent
          this.deduction_price_percent = item.deduction_price_percent
          // getCode({group_id:item.id}).then(res=>{
          //   if(res.code === 200){
          //     this.code = res.data
          //   }
          // })
        }
        if(e === 'block'){
          this.$confirm('此操作将拉黑此账号, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            
          }).catch(() => {
          });
        }
      },
      cancel(){
        this.dialogScoreVisible = false
        this.dialogTotalScoreVisible = false
      },
      confirm(){
        updateMonth(this.scoreID,{'month_score':this.month_score}).then(res=>{
          if(res.code === 200){
            console.log(1)
            this.$message({
                message: "修改成功",
                type: "success"
            });
            this.dialogScoreVisible = false
            this.getTableData()
          }
        })
      },
      deductionCancel(){
        this.dialogDeductionVisible = false
      },
      deductionConfirm(){
        let params = {
          'deduction':this.deduction,
          'deduction_protect':this.deduction_protect,
          'deduction_begin':this.deduction_begin,
          'deduction_percent':this.deduction_percent,
          'deduction_price_percent':this.deduction_price_percent
        }
        updateDeduction(this.deductionID,params).then(res=>{
          if(res.code === 200){
            console.log(1)
            this.$message({
                message: "修改成功",
                type: "success"
            });
            this.dialogDeductionVisible = false
            this.getTableData()
          }
        })
      },
      deductionChange(e){
        console.log(e)
        if(e == true){
          this.deduction = 1
        }else{
          this.deduction = 0
        }
      },
      totalConfirm(){
        updateComprehensive(this.scoreID,{'comprehensive_score':this.comprehensive_score}).then(res=>{
          if(res.code === 200){
            console.log(1)
            this.$message({
                message: "修改成功",
                type: "success"
            });
            this.dialogTotalScoreVisible = false
            this.getTableData()
          }
        })
      },
      writeScope(id){
        console.log(id)
        this.scopeForm.id = id
        let tmp = [];
        this.service.forEach((value,key)=>{
          tmp[key] = value
          if (id === value.id){
            this.scopeForm.sort = parseInt(value.sort)
            tmp[key].write_scope = true
          }
        })
        this.service = tmp;
      },

      scopeChange(){
        editData(this.scopeForm.id,{sort:this.scopeForm.sort}).then(res=>{
          if (res.code === 200){
            this.searchSet()
          }
        })
      },

      //选择TREE
      showTreeWorker(id, pid) {
          this.params.type = id;
          this.getTableData();
      },

      handleDelete(id) {
        this.$confirm('确认拉黑此企业吗?')
          .then(_ => {
            deleteGroup(id).then(res=>{
                this.getTableData()
                this.$notify({
                  title: '成功',
                  message: '已拉黑',
                  type: 'success'
                });
              })
          })
          .catch(_ => {});
      },
      // 打开添加
      handleOpenAdd() {
        this.addDrawer = true;
      },
      // 关闭添加/修改
      handleClose() {
        this.$confirm("确认关闭？")
          .then((_) => {
            this.addDrawer = false;
            this.editDrawer = false;
            this.dialogScoreVisible = false
            this.dialogTotalScoreVisible = false
            this.dialogCodeVisible = false
            this.dialogDeductionVisible = false
          })
          .catch((_) => {});
      },

      editTable(id){
        this.editID = id
        this.editDrawer = true
      },
      // 添加/修改成功
      handleDrawerSuccess() {
        this.getTableData()
        this.addDrawer = false;
        this.editDrawer = false;
        this.searchSet()
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 清空子组件
      clearContent() {
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 分页
      prevpage() {
        this.params.page -= 1;
        this.getTableData();
      },
      nextpage() {
        this.params.page += 1;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.params.page = val;
        this.getTableData();
      },
    },
    mounted() {
      this.admin_permission = this.$store.state.permission.permissionArr[page_name]
      
      this.groupID = this.$store.state.user.userData.group_id
      this.getTableData();
      if(document.body.clientHeight&&document.documentElement.clientHeight)
      {
        var clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
      }
      else
      {
        var clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
      }
      this.height = clientHeight-82-60-40-20-40-50
      getProvince().then(res=>{
        if(res.code === 200){
          console.log(res.data)
          this.provinceList = res.data
        }
      })
    }
  };
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;

    .table {
      margin-top: 24px !important;
    }

    .toolbar {
      background: #fff;
      width: 100%;
      padding: 16px 32px 26px 0;
    }
  }

  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }

  .oneLineCls{
	  text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     -webkit-line-clamp: 1;
     line-clamp: 1;
     -webkit-box-orient: vertical;
  }
</style>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    padding-right: 8px;
  }
  .el-tree-node__expand-icon.is-leaf{
    color: #c0c4cc;
  }
</style>
