import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = ``

export function getList(data = {}) {
  return axios.request({
    url: `${url}/temples`,
    method: "get",
    params: data,
  })
}
export function getTypeList(data = {}) {
  return axios.request({
    url: `${url}/store/types/select`,
    method: "get",
    params: data,
  })
}
export function searchTypeList(data = {}) {
  return axios.request({
    url: `${url}/store/types`,
    method: "get",
    params: data,
  })
}
export function getListById(id,data = {}) {
  return axios.request({
    url: `${url}/store/${id}`,
    method: "get",
    params: data,
  })
}
export function getTypeById(id,data = {}) {
  return axios.request({
    url: `${url}/store/type/${id}`,
    method: "get",
    params: data,
  })
}
export function getListSelect(data = {}) {
  return axios.request({
    url: `${url}/stores/select`,
    method: "get",
    params: data,
  })
}
export function getTypeSelect(data = {}) {
  return axios.request({
    url: `${url}/store/types/select`,
    method: "get",
    params: data,
  })
}
export function deleteData(id,data) {
  return axios.request({
    url: `${url}/store/${id}`,
    method: "delete",
    params:data,
  })
}
export function deleteType(id,data) {
  return axios.request({
    url: `${url}/store/type/${id}`,
    method: "delete",
    params:data,
  })
}
export function addData(data) {
  return axios.request({
    url: `${url}/store`,
    method: "post",
    data: data,
  })
}
export function addType(data) {
  return axios.request({
    url: `${url}/store/type`,
    method: "post",
    data: data,
  })
}
export function editData(id, data) {
  return axios.request({
    url: `${url}/store/${id}`,
    method: "put",
    data: data,
  })
}
export function editType(id, data) {
  return axios.request({
    url: `${url}/store/type/${id}`,
    method: "put",
    data: data,
  })
}
export function editPrice(id, data) {
  return axios.request({
    url: `${url}/store/set/${id}`,
    method: "put",
    data: data,
  })
}

export function editDevided(id, data) {
  return axios.request({
    url: `${url}/store/devided/${id}`,
    method: "put",
    data: data,
  })
}
export function storeAppointment(id, data) {
  return axios.request({
    url: `${url}/store/appointment/${id}`,
    method: "put",
    data: data,
  })
}
export function storeRefuse(id, data) {
  return axios.request({
    url: `${url}/store/refuse/${id}`,
    method: "put",
    data: data,
  })
}
export function getHistoryList(data = {}) {
  return axios.request({
    url: `${url}/store/history`,
    method: "get",
    params: data,
  })
}

export function storePriceAppointment(id, data) {
  return axios.request({
    url: `${url}/store/appointment/price/${id}`,
    method: "put",
    data: data,
  })
}
export function storePriceRefuse(id, data) {
  return axios.request({
    url: `${url}/store/refuse/price/${id}`,
    method: "put",
    data: data,
  })
}
export function updateDeduction(id, data={}){
  return axios.request({
    url:`${url}/store/deduction/${id}`,
    method: "put",
    data,
  })
}
export function getCash(data = {}) {
  return axios.request({
    url: `${url}/stores/cash/out`,
    method: "get",
    params: data,
  })
}

export function getAdmins(data = {}) {
  return axios.request({
    url: `${url}/store/admins`,
    method: "get",
    params: data,
  })
}
//提现同意
export function cashAgree(id, data={}){
  return axios.request({
    url:`${url}/stores/cash/out/appointment/${id}`,
    method: "put",
    data,
  })
}
//提现拒绝
export function cashRefuse(id, data={}){
  return axios.request({
    url:`${url}/stores/cash/out/refuse/${id}`,
    method: "put",
    data,
  })
}
export function getMedia(id,data = {}) {
  return axios.request({
    url: `${url}/store/promotion/${id}`,
    method: "get",
    params: data,
  })
}

export function editMedia(id, data={}){
  return axios.request({
    url:`${url}/store/promotion/${id}`,
    method: "put",
    data,
  })
}
export function getSceneList(data = {}) {
  return axios.request({
    url: `${url}/halls`,
    method: "get",
    params: data,
  })
}