<template>
  <section style="padding:0 5%;">
    <el-container style="height: 100%;">
      <el-aside width="200px" style="height:100%">
        <el-form
        ref="form"
        label-position="top"
        status-icon
        :model="form"
        :rules="rules"
        label-width="100px"
        style="width:100%;"
        >
          <el-form-item label="权限标识" prop="permission_id" required>
            <el-input v-model="form.permission_id" placeholder="请输入菜单项的ID,不可重复"></el-input>
          </el-form-item>

          <el-form-item label="权限名称" prop="permission_name" required>
            <el-input v-model="form.permission_name" placeholder="请输入菜单名称"></el-input>
          </el-form-item>

          <el-form-item label="备注" prop="icon" >
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入备注"
              v-model="form.comment">
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-button @click="close('form')">取消</el-button>
            <el-button type="primary" @click="submit('form')" :plain="true">立即更新</el-button>
          </el-form-item>
        </el-form>
      </el-aside>
      <el-container style=" height:calc(100vh - 90px);"> 
        <el-main style="margin-left: 20px;border-left:1px solid rgba(0,0,0,0.1)">
            <div v-for="(value,key) in menus">
              <div style="width:100%" v-if="value.parent_id === 0">
                <div style="font-weight:bolder;font-size: 20px;">{{value.name}}
                  <el-checkbox style="margin-left:10px;" 
                  v-if="value.url" 
                  :indeterminate="permission_select[value.menu_id] && (permission_select[value.menu_id].length === value.handle_arr.length)" 
                  @change="handleCheckAllChange($event,value.menu_id,value.handle_arr)">
                    全选 
                  </el-checkbox>
                </div>
                <div style="font-size:12px;">{{value.comment}}</div>
                <div style="width:100%;display:inline-flex;margin-top:16px;margin-bottom:20px;" v-if="value.handle_arr.length>0">
                  
                  <el-checkbox-group v-model="permission_select[value.menu_id]" @change="handleCheckedpermissionsChange($event,value.menu_id,)">
                    <el-checkbox 
                    v-for="(v,k) in value.handle_arr" 
                    :label="v" :key="value.menu_id+v" 
                    >
                      <span v-if="v==='look'">查看/搜索数据</span>
                      <span v-if="v==='add'">增加数据</span>
                      <span v-if="v==='update'">更改数据</span>
                      <span v-if="v==='deleted'">删除/拉黑数据</span>
                      <span v-if="v==='examine'">审核权限</span>
                    </el-checkbox>
                </el-checkbox-group>
                 
                </div>
              </div>
              <div style="width:100%;margin-top:10px;margin-left:20px;" v-if="value.parent_id > 0">
                <div style="font-weight:bolder;font-size: 16px;">
                  {{value.name}}
                  <el-checkbox style="margin-left:10px;" 
                  v-if="value.handle_arr.length>0" 
                  :indeterminate="permission_select[value.menu_id] && (permission_select[value.menu_id].length === value.handle_arr.length)" 
                  @change="handleCheckAllChange($event,value.menu_id,value.handle_arr)">全选</el-checkbox>
                </div>
                <div style="font-size:12px;">{{value.comment}}</div>
                <div style="width:100%;display:inline-flex;margin-top:16px;margin-bottom:20px;" v-if="value.handle_arr.length>0">
                  <el-checkbox-group v-model="permission_select[value.menu_id]"  @change="handleCheckedpermissionsChange($event,value.menu_id)">
                    <el-checkbox v-for="(v,k) in value.handle_arr" :label="v" :key="value.menu_id+v">
                      <span v-if="v==='look'">查看/搜索数据</span>
                      <span v-if="v==='add'">增加数据</span>
                      <span v-if="v==='update'">更改数据</span>
                      <span v-if="v==='deleted'">删除/拉黑数据</span>
                      <span v-if="v==='examine'">审核权限</span>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
        </el-main>
      </el-container>
    </el-container>
    
  </section>
</template>

<script>
  let _this;
  import {getPermission,updatePermission,getMenus} from "@/api/system.js";
  let form = {
          permission_id: "",
          permission_name: "",
          comment: '',
          permission_menu:[],
        }
  export default {
    props: {
      id: Number
    },
    data() {
      return {
        form: form,
        menus:[],
        rules: {
          permission_id: [
            {required: true, message: "请输入权限标识,不可重复", trigger: "blur"},
          ],
          permission_name: [
            {required: true, message: "请输入权限名称", trigger: "blur"},
          ]
        },
        permission_select:[]
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      // 初始化数据
      init() {
        getMenus().then(res=>{
          if(res.code === 200){
            this.menus = res.data
            let permission = {}
            res.data.forEach((value,key) => {
              if(value.handle_arr && value.handle_arr.length>0){
                permission[value['menu_id']] = []
              }
            });
            this.permission_select = permission
            getPermission(this.id).then(res=>{
              if (res.code === 200){
                this.form = res.data
                console.log(res.data.permission_menu)
                this.permission_select = res.data.permission_menu ? res.data.permission_menu : permission
                console.log(this.permission_select)
                this.$nextTick(function () {
                  this.$forceUpdate()
                })
              }
            })
          }
        })
        
      },

      handleCheckAllChange(event,menu_id,arr){
        arr = JSON.parse(JSON.stringify(arr))
        this.permission_select[menu_id] = []
        if(event){
          this.permission_select[menu_id] = arr
        }
        this.$nextTick(function () {
          this.$forceUpdate()
        })
      },

      handleCheckedpermissionsChange(event,menu_id){
        this.$nextTick(function () {
          this.$forceUpdate()
        })
      },
     
      close() {
        _this.$emit("close");
      },
      submit(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            let data = this.form
            data['permission_menu'] = JSON.parse(JSON.stringify(this.permission_select))
            updatePermission(this.id,data).then(res => { 
              if (res.code === 200) {
                _this.$message({
                  message: "更新成功",
                  type: "success"
                });
                // this.form = window.form
                setTimeout(() => {
                  _this.$emit("success");
                }, 1000);
              }
            })
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }

  .icon-dialog{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-dialog-list{
      box-sizing: border-box;
      padding: 20px 10px;
      width: 16.66%;
      font-size: 32px;
      text-align: center;
      border:1px solid #dbdbdb;
    }
    .icon-dialog-list:hover{
      color:#409eff;
      border-color:#409eff;
    }
  }
  .icon-name{
    margin-top:10px;
    font-size: 12px;
  }
  
</style>
<style>
  .el-dialog__body{
    max-height: 60vh;
    overflow-y: auto;
  }
</style>
