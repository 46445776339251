<template>
  <div class="login">
    <div class="login-box">
      <el-image class="logo" src="https://cdn.cxc-group.com/admin/logo.png" fit="contain"></el-image>
      <div class="admin">
        <el-tabs v-model="activeName" :stretch="true">
          <el-tab-pane label="账号登录" name="username">
            <el-input placeholder="账户" prefix-icon="el-icon-user" v-model="params.username" class="input"  @keypress.native.enter="onEnterPress"></el-input>
            <el-input placeholder="密码" prefix-icon="el-icon-lock" v-model="params.password" class="input" @keypress.native.enter="onEnterPress" show-password  autocomplete="off" clearable></el-input>
            <el-button class="btn" @click="login">登录</el-button>
            <div class="register" v-if="isRegister" @click="registerDialog=true">若无账号，请注册管理员</div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog title="注册账号" status-icon :visible.sync="registerDialog" width="380px">
        <el-form :model="registerForm" :rules="registerRule" ref="registerForm" label-position="top"> 
          <el-form-item label="账号" prop="username" required>
            <el-input v-model="registerForm.username" placeholder="请输入账号" clearable></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" required>
            <el-input placeholder="请输入密码" v-model="registerForm.password" show-password  autocomplete="off" clearable></el-input>
          </el-form-item>
          <el-form-item label="重复密码" prop="confirm" required>
            <el-input placeholder="请输入密码" v-model="registerForm.confirm" show-password  autocomplete="off" clearable></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="registerDialog = false">取 消</el-button>
          <el-button type="primary" @click="register">注 册</el-button>
        </div>
      </el-dialog>
      <p class="tips">Copyright &copy; 2024杭州众地智能科技服务有限公司出品</p>
  </div>
</template>

<script>
import { login, getInited, register } from "@/api/public.js";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.registerForm.confirm !== '') {
          this.$refs.registerForm.validateField('confirm');
          callback()
        }
        // 长度为8-18位, 密码必须由字母、数字组成，区分大小写
        var pattern = new RegExp(/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]{8,18}$/)
        if(!pattern.test(value)){
          callback(new Error(' 密码必须由字母、数字组成，长度为8-18位'));
        }
        callback();
      }
    };
    var validateConfirm = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      activeName:'username',
      params:{
        username:'',
        user:'',
        password:'',
      },
      isRegister:0,
      registerDialog:false,
      registerForm:{
        username:'',
        password:'',
        confirm:''
      },
      registerRule:{
        username:[
          {required:true,message:'请输入账号',trigger:'blur'}
        ],
        password:[
          {required:true,message:'请输入密码',trigger:'blur'},
          {validator:validatePass, trigger:'blur'}
        ],
        confirm:[
          {required:true,message:'请输入重复密码',trigger:'blur'},
          {validator:validateConfirm, trigger:'blur'},
        ]
      }
    };
  },

  components: {},

  computed: {},

  methods: {
     onEnterPress(e){
      if(e.keyCode===13){
        this.login();
      }
    },
    login(){
      if(this.params.username === ''){
        return this.$message.error("请输入账号")
      }
      if(this.params.password === ''){
        return this.$message.error("请输入密码")
      }
      login(this.params)
       .then((res)=>{
         if(res.code===200){
           localStorage.setItem('userAccount',this.params.username);
           this.$store.commit("user/set",res.data.user)
           this.$store.commit("menu/set",res.data.menu)
           this.$store.commit("permission/set",res.data.permission)
           localStorage.setItem('token',res.data.token);
           console.log(res.data.token)
           this.$emit('goHome',true);
         }
       })
    },
    register(){
      this.$refs['registerForm'].validate((valid) => {
          if (valid) {
            let params = this.registerForm
            register(params).then(res=>{
              if (res.code === 200){
                localStorage.setItem('userAccount',this.params.username);
                this.$store.commit("user/set",res.data.user)
                this.$store.commit("menu/set",res.data.menu)
                this.$store.commit("permission/set",res.data.permission)
                localStorage.setItem('token',res.data.token);
                this.$emit('goHome',true);
              }
            })
          } else {
            this.$message.error("请输入完整")
            return false;
          }
        });
    }
  },
  mounted(){
    getInited().then(res=>{
      if(res.code === 200){
        this.isRegister = res.data ? 0 : 1;
      }
    })
  }
};
</script>
<style lang="scss" scoped>
  .login {
    background: url(https://cdn.cxc-group.com/admin/admin-login-back.png);
    background-size: 100% 100%;
    width: 100vw;
    height: 100vh;
    // padding:100px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .login-box{
      max-width:764px;
      width: 100%;
      height:100vh;
      position:fixed;
      right:0;
      top:0;
      background: rgba(0,0,0,0.65);
      .logo{
        width:198px;
        height:76px;
        margin-top:20%;
        margin-left:37%;
      }
      .admin{
        margin:10% auto;
        width:447px;
      }
      
    }
    .input {
      width: 100%;
      margin-top: 30px;
      display: block;
    }
    .btn {
      background: #FFC14B;
      width: 100%;
      border-radius: 3px;
      margin-top: 30px;
      color: #000;
      font-weight: bold;
    }
  }
  .register{
    color:#1890ff;
    text-align: right;
    width: 368px;
    margin-top: 20px;
    text-decoration: underline;
  }
  .tips{
    position: absolute;
    bottom:30px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    width:100%;
  }
</style>
