<template>
    <div class="box">
        <div class="content">
            <div class="title">全部消息 <span>({{total}})</span></div>
            <div class="all">
                <div class="message" v-for="(value,key) in list" :key="key" :style="{'color':value.status===0 ?'#409EFF':'#888'}">
                    <div class="word">
                        <div class="circle" v-if="value.status===0"></div>
                        {{ value.word }}
                    </div>
                    <span>去查看<i class="el-icon-arrow-right"></i></span>
                </div>
            </div>
           
            
        </div>
    </div>
</template>

<script>
let _this;
import {editPassword} from "@/api/system.js";
export default{
    data(){
        return{
            list:[
                {
                    word:"adaddsadasds",
                    status:0
                },
                {
                    word:"啥大萨达撒打算大萨达",
                    status:0
                },
                {
                    word:"润体乳非官方的股份苟富贵",
                    status:1
                },
            ],
            total:"50"
        }
    },
    mounted(){

    },
    methods:{
    }
}
</script>
<style lang="scss" scoped>
.box{
    .content{
        width:100%;
        height:100vh;
        margin:10px auto;
        padding-left:30px;
        background-color: #fff;
        padding-top:30px;
        border-radius: 10px;
        .title{
            font-size:16px;
            font-weight: 600;
            span{
                color:#ccc;
                font-size:12px;
            }
        }
        .all{
            margin-top:20px;
            margin-left:20px;
            width:90%;
            .message{
                height:40px;
                line-height:40px;
                font-size:14px;
                padding:0 10px;
                border-bottom: 1px solid #ccc;
                display: flex;
                justify-content: space-between;
                .word{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .circle{
                        width:5px;
                        height:5px;
                        background-color: red;
                        border-radius: 5px;
                        margin-right: 10px;
                    }
                }
                span{
                    font-size:12px;
                    color:#999;
                }
            }
        }
        .footer{
            display: flex;
            justify-content: flex-end;
            margin:10px auto;
        }
    }
}
</style>
