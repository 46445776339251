<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-form-item label="账号" prop="phone" required>
        <el-input v-model="form.phone" placeholder="请输入手机号做为登录账号"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="特殊名（法号）" prop="buddhist_name">
        <el-input v-model="form.buddhist_name" placeholder="请输入特殊名（法号）"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender" required>
        <el-radio v-model="form.gender" :label="0">男</el-radio>
        <el-radio v-model="form.gender" :label="1">女</el-radio>
      </el-form-item>
      
      <el-form-item label="寺庙" prop="temple_id" required>
        <el-select v-model="form.temple_id" placeholder="请选择需搜索寺庙" filterable clearable style="margin-left:10px;margin-top:10px;" @change="templeChange">
          <!-- <el-option label="请选择需搜索投放点">请选择需搜索投放点</el-option> -->
          <el-option
            v-for="item in templeList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投放点详细地址" prop="address" required>
        <el-select
          style="width: 100%"
          v-model="keywordResult"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          @change="keywordChange"
          :remote-method="remoteMethod"
          :loading="keywordLoading"
        >
          <el-option
            v-for="(value,key) in keywordAddress"
            :key="key"
            :label="value.title"
            :value="key"
          ></el-option>
        </el-select>
        <div v-if="keywordProvince!==''">{{keywordProvince}}</div>
      </el-form-item>

      <el-form-item label="角色" prop="role">
        <el-select v-model="form.role" placeholder="请选择需要绑定角色">
          <el-option
            v-for="(value,key) in roles"
            :key="key"
            :label="value.role_name"
            :value="value.role_id">
            <span style="float: left">{{ value.role_name }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      
      <el-form-item label="上传头像" required>
        <upload-qiniu @success="handlePicSuccess" @remove="handlePicRemove" :num="1" path="store" :multiple="true" :images="avatar"></upload-qiniu>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  import {addPlaceUser} from "@/api/users.js";
  import {tencentAddressKeyword,areaDetailCode,getTemples} from "@/api/public.js";
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  export default {
    components: {UploadQiniu},
    data() {
      return {
        form: {
          phone: "",
          name: "",
          buddhist_name:"",
          temple_id:"",
          gender: 0,
          avatar:'',
          province_id: "",
          city_id: "",
          area_id: "",
          address: "",
          role:2,
        },

        templeList:[],
        roles:[
          {
            role_id:0,
            role_name:"负责人"
          },
          {
            role_id:1,
            role_name:"管理员"
          },
          {
            role_id:2,
            role_name:"成员"
          },
        ],
        avatar:[],
        keywordAddress: [],
        keywordLoading: false,
        keywordResult: "",
        keywordProvince:'',
        rules: {
          username: [
            {required: true, message: "请输入手机号做为登录账号", trigger: "blur"},
          ],
          nickname: [
            {required: true, message: "请输入姓名", trigger: "change"},
          ],
        },
        
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      // 初始化数据
      init() {
        getTemples().then(res=>{
          if(res.code === 200){
            console.log(res.data)
            this.templeList = res.data
          }
        })
      },
      
      // 上传图片删除、缓存、预览
      handlePicSuccess(e) {
        console.log(e)
        this.form.avatar = e[0];
        console.log(this.form.avatar)
      },
      handlePicRemove(e) {
        this.form.avatar = [];
      },
      
      keywordChange() {
        let data = this.keywordAddress[this.keywordResult];
        this.keywordProvince = data['address']
        this.form.address = data['address']
        this.form.lat = data["location"]["lat"];
        this.form.lng = data["location"]["lng"];
        areaDetailCode({area_id: data.adcode}).then(res => {
          if (res.code === 200) {
            this.form.province_id = res.data.province_id
            this.form.city_id = res.data.city_id
            this.form.area_id = res.data.area_id
          }
        })
      },
      remoteMethod(query) {
        if (query !== "") {
          this.keywordLoading = true;
          tencentAddressKeyword({keyword: query}).then(res => {
            this.keywordLoading = false;
            if (res.code === 200) {
              this.keywordAddress = res.data;
            }
          });
        } else {
          this.options = [];
        }
      },
      close() {
        _this.$emit("close");
      },
      submit(form) {
        console.log(this.form)
        this.$refs[form].validate(valid => {
          if (valid) {
            addPlaceUser(_this.form).then(res => {
              if (res.code === 200) {
                setTimeout(() => {
                  _this.$emit("success");
                  _this.$emit("close");
                }, 1000);
              }
            })
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }
</style>
