<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-form-item label="标识" prop="menu_id" required>
        <el-input v-model="form.menu_id" placeholder="请输入菜单项的ID,不可重复"></el-input>
      </el-form-item>

      <el-form-item label="显示名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入菜单名称"></el-input>
      </el-form-item>

      <el-form-item label="图标CLASS" prop="icon" >
        <el-input v-model="form.icon" placeholder="请选择菜单图标CSS样式类名" @focus="iconDialogVisible=true"></el-input>
      </el-form-item>

      <el-form-item label="页面URL" prop="url">
        <el-input v-model="form.url" placeholder="URL空代表是目录而不是叶子节点"></el-input>
      </el-form-item>
      
      <el-form-item label="序号" prop="sort">
        <el-input v-model="form.sort" placeholder="请输入菜单需要（越大越靠后）"></el-input>
      </el-form-item>
      
      <el-form-item label="父菜单标识" prop="parent_id">
        <el-input v-model="parent_name" placeholder="请输入父菜单标识" :disabled="true"></el-input>
      </el-form-item>

      <el-form-item label="菜单说明" prop="comment">
        <el-input v-model="form.comment" placeholder="菜单说明" :disabled="true"></el-input>
      </el-form-item>

      <el-form-item label="菜单说明" prop="comment">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="form.handle" @change="handleCheckedPermissionChange">
          <el-checkbox v-for="(value,key) in handles" :label="value.id" :key="key">{{value.name}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="是否启用" prop="enable">
        <el-switch
          v-model="form.enable"
          active-text="启用"
          inactive-text="不启用">
        </el-switch>
      </el-form-item>
      
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>

    <el-dialog
      width="60%"
      title="选择icon"
      :visible.sync="iconDialogVisible"
      append-to-body>
        <div class="icon-dialog">
          <div class="icon-dialog-list" v-for="(value,key) in icons" :key="key" @click="chooseIcon(value)">
            <i :class="value"></i>
            <p class="icon-name">{{value}}</p>
          </div>
        </div>
    </el-dialog>
  </section>
</template>

<script>
  let _this;
  import {getPermissions,addMenu} from "@/api/system.js";

  export default {
    props: {
      parent_name: {
        type:String,
        default: '',
      },
      parent_id:{
        type:Number,
        default:0
      }
    },
    data() {
      return {
        form: {
          menu_id: "",
          name: "",
          icon: '',
          url:'',
          sort:'',
          handle:[],
          enable:0,
          comment:'',
          parent_id:this.parent_id
        },

        checkAll: false,
        checkedHandle: [],
        handles: [
          {'id':'look','name':'查看/查询'},
          {'id':'add' , 'name':'增加/上传'},
          {'id':'update','name':'更改'},
          {'id':'deleted','name':'删除/拉黑'},
          {'id':'examine','name':'审核'},
        ],
        isIndeterminate: true,

        rules: {
          menu_id: [
            {required: true, message: "请输入菜单项的ID,不可重复", trigger: "blur"},
          ],
          name: [
            {required: true, message: "请输入菜单名称", trigger: "blur"},
          ]
        },
        iconDialogVisible:false,
        icons:[
          "el-icon-platform-eleme","el-icon-eleme","el-icon-delete-solid","el-icon-delete","el-icon-s-tools","el-icon-setting",
          "el-icon-user-solid","el-icon-user","el-icon-phone","el-icon-phone-outline","el-icon-more","el-icon-more-outline",
          "el-icon-star-on","el-icon-star-off","el-icon-s-goods","el-icon-goods","el-icon-warning","el-icon-warning-outline",
          "el-icon-question","el-icon-info","el-icon-remove","el-icon-circle-plus","el-icon-success","el-icon-error",
          "el-icon-zoom-in","el-icon-zoom-out","el-icon-remove-outline","el-icon-circle-plus-outline","el-icon-circle-check","el-icon-circle-close",
          "el-icon-s-help","el-icon-help","el-icon-minus","el-icon-plus","el-icon-check","el-icon-close",
          "el-icon-picture","el-icon-picture-outline","el-icon-picture-outline-round","el-icon-upload","el-icon-upload2","el-icon-download",
          "el-icon-camera-solid","el-icon-camera","el-icon-video-camera-solid","el-icon-video-camera","el-icon-message-solid","el-icon-bell",
          "el-icon-s-cooperation","el-icon-s-order","el-icon-s-platform","el-icon-s-fold","el-icon-s-unfold","el-icon-s-operation",
          "el-icon-s-promotion","el-icon-s-home","el-icon-s-release","el-icon-s-ticket","el-icon-s-management","el-icon-s-open",
          "el-icon-s-shop","el-icon-s-marketing","el-icon-s-flag","el-icon-s-comment","el-icon-s-finance","el-icon-s-claim",
          "el-icon-s-custom","el-icon-s-opportunity","el-icon-s-data","el-icon-s-check","el-icon-s-grid","el-icon-menu",
          "el-icon-share","el-icon-d-caret","el-icon-caret-left","el-icon-caret-right","el-icon-caret-bottom","el-icon-caret-top",
          "el-icon-bottom-left","el-icon-bottom-right","el-icon-back","el-icon-right","el-icon-bottom","el-icon-top",
          "el-icon-top-left","el-icon-top-right","el-icon-arrow-left","el-icon-arrow-right","el-icon-arrow-down","el-icon-arrow-up",
          "el-icon-d-arrow-left","el-icon-d-arrow-right","el-icon-video-pause","el-icon-video-play","el-icon-refresh","el-icon-refresh-right",
          "el-icon-refresh-left","el-icon-finished","el-icon-sort","el-icon-sort-up","el-icon-sort-up","el-icon-sort-down","el-icon-rank",
          "el-icon-loading","el-icon-view","el-icon-c-scale-to-original","el-icon-date","el-icon-edit","el-icon-edit-outline",
          "el-icon-folder","el-icon-folder-opened","el-icon-folder-add","el-icon-folder-remove","el-icon-folder-delete","el-icon-folder-checked",
          "el-icon-tickets","el-icon-document-remove","el-icon-document-delete","el-icon-document-copy","el-icon-document-checked","el-icon-document",
          "el-icon-document-add","el-icon-printer","el-icon-paperclip","el-icon-takeaway-box","el-icon-search","el-icon-monitor",
          "el-icon-attract","el-icon-mobile","el-icon-scissors","el-icon-umbrella","el-icon-headset","el-icon-brush",
          "el-icon-mouse","el-icon-coordinate","el-icon-magic-stick","el-icon-reading","el-icon-data-line","el-icon-data-board",
          "el-icon-pie-chart","el-icon-data-analysis","el-icon-collection-tag","el-icon-film","el-icon-suitcase","el-icon-suitcase-1",
          "el-icon-receiving","el-icon-collection","el-icon-files","el-icon-notebook-1","el-icon-notebook-2","el-icon-toilet-paper",
          "el-icon-office-building","el-icon-school","el-icon-table-lamp","el-icon-house","el-icon-no-smoking","el-icon-smoking",
          "el-icon-shopping-cart-full","el-icon-shopping-cart-1","el-icon-shopping-cart-2","el-icon-shopping-bag-1","el-icon-shopping-bag-2","el-icon-sold-out",
          "el-icon-sell","el-icon-present","el-icon-box","el-icon-bank-card","el-icon-money","el-icon-coin",
          "el-icon-wallet","el-icon-discount","el-icon-price-tag","el-icon-news","el-icon-guide","el-icon-male",
          "el-icon-female","el-icon-thumb","el-icon-cpu","el-icon-link","el-icon-connection","el-icon-open",
          "el-icon-turn-off","el-icon-set-up","el-icon-chat-round","el-icon-chat-line-round","el-icon-chat-square","el-icon-chat-dot-round",
          "el-icon-chat-dot-square","el-icon-chat-line-square","el-icon-message","el-icon-postcard","el-icon-position","el-icon-turn-off-microphone",
          "el-icon-microphone","el-icon-close-notification","el-icon-bangzhu","el-icon-time","el-icon-odometer","el-icon-crop",
          "el-icon-aim","el-icon-switch-button","el-icon-full-screen","el-icon-copy-document","el-icon-mic","el-icon-stopwatch",
          "el-icon-medal-1","el-icon-medal","el-icon-trophy","el-icon-trophy-1","el-icon-first-aid-kit","el-icon-discover",
          "el-icon-place","el-icon-location","el-icon-location-outline","el-icon-location-information","el-icon-add-location","el-icon-delete-location",
          "el-icon-map-location","el-icon-alarm-clock","el-icon-timer","el-icon-watch-1","el-icon-watch","el-icon-lock",
          "el-icon-unlock","el-icon-key","el-icon-service","el-icon-mobile-phone","el-icon-bicycle","el-icon-truck",
          "el-icon-ship","el-icon-basketball","el-icon-football","el-icon-soccer","el-icon-baseball","el-icon-wind-power",
          "el-icon-light-rain","el-icon-lightning","el-icon-heavy-rain","el-icon-sunrise","el-icon-sunrise-1","el-icon-sunset",
          "el-icon-sunny","el-icon-cloudy","el-icon-partly-cloudy","el-icon-cloudy-and-sunny","el-icon-moon","el-icon-moon-night",
          "el-icon-dish","el-icon-dish-1","el-icon-food","el-icon-chicken","el-icon-fork-spoon","el-icon-knife-fork",
          "el-icon-burger","el-icon-tableware","el-icon-sugar","el-icon-dessert","el-icon-ice-cream","el-icon-hot-water",
          "el-icon-water-cup","el-icon-coffee-cup","el-icon-cold-drink","el-icon-goblet","el-icon-goblet-full","el-icon-goblet-square",
          "el-icon-goblet-square-full","el-icon-refrigerator","el-icon-grape","el-icon-watermelon","el-icon-cherry","el-icon-apple",
          "el-icon-pear","el-icon-orange","el-icon-coffee","el-icon-ice-tea","el-icon-ice-drink","el-icon-milk-tea",
          "el-icon-potato-strips","el-icon-lollipop","el-icon-ice-cream-square","el-icon-ice-cream-round"
        ]
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
      console.log(this.parent_name)
    },

    methods: {
      // 初始化数据
      init() {
        // getPermissions().then(res=>{
        //   if(res.code === 200){
        //     let tmp = []
        //     res.data.forEach((value,key)=>{
        //       console.log(value)
        //       tmp.push({'permission_id':value.permission_id,'permission_name':value.permission_name})
        //     })
        //     this.permissions = tmp
        //   }
        // })
      },
      chooseIcon(name){
        this.form.icon = name
        this.iconDialogVisible=false
      },

      handleCheckAllChange(val) {
        if(val){
          let tmp = []
          this.handles.forEach((value,key)=>{
            tmp.push(value.id)
          })
          this.form.handle = tmp
        }else{
          this.form.handle = []
        }
        this.isIndeterminate = false;
      },
      handleCheckedPermissionChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.handles.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.handles.length;
      },

      parentChangeFun(e){
        this.parents.forEach((value,key)=>{
          if(parseInt(value.id) === parseInt(e)){
            _this.form.type = value.type
            _this.form.status = value.status
          }
        })
      },
      close() {
        _this.$emit("close");
      },
      submit(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            addMenu(_this.form).then(res => { 
              if (res.code === 200) {
                _this.$message({
                  message: "添加成功",
                  type: "success"
                });
                this.form = {
                  menu_id: "",
                  name: "",
                  icon: '',
                  url:'',
                  sort:'',
                  permission:[],
                  enable:0,
                  parent_id:this.parent_id
                }
                setTimeout(() => {
                  _this.$emit("success");
                }, 1000);
              }
            })
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }

  .icon-dialog{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-dialog-list{
      box-sizing: border-box;
      padding: 20px 10px;
      width: 16.66%;
      font-size: 32px;
      text-align: center;
      border:1px solid #dbdbdb;
    }
    .icon-dialog-list:hover{
      color:#409eff;
      border-color:#409eff;
    }
  }
  .icon-name{
    margin-top:10px;
    font-size: 12px;
  }
  
</style>
<style>
  .el-dialog__body{
    max-height: 60vh;
    overflow-y: auto;
  }
</style>
