import router from '@/router/index'
import VueRouter from "vue-router";

const state = {
    menuArr: []
}
const mutations = {
    add: (state:any,view: any)=> {
        state.menuArr.push(view)
    },
    delete: (state: any, view: any)=> {
        let index = state.menuArr.indexOf(view)
        if (index > -1) {
            state.menuArr.splice(index, 1)
        }
    },
    set: (state: any, view: any)=> {
        let menu_list = []
        Object.keys(view).map((key) => [view[key], key]).forEach(([value,key]) => {
            menu_list.push(value)
        });
        
        // view.forEach((value,key)=>{
        //     menu_list.push(value)
        // })
        state.menuArr = menu_list
    }
}

export default {
    namespaced: true,  // 这个是命名空间，用来区分不同的模块，我是根据文件的名字来区分
    state,
    mutations,
};
