import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = ``

export function getList(data = {}) {
  return axios.request({
    url: `${url}/devices`,
    method: "get",
    params: data,
  })
}

export function getListSelect(data = {}) {
  return axios.request({
    url: `${url}/devices/select`,
    method: "get",
    params: data,
  })
}
export function getDataById(id,params={}){
  return axios.request({
    url: `${url}/device/${id}`,
    method: "get",
    params,
  })
}

export function deleteData(id,data) {
  return axios.request({
    url: `${url}/device/${id}`,
    method: "delete",
    params:data,
  })
}
export function addData(data) {
  return axios.request({
    url: `${url}/device`,
    method: "post",
    data: data,
  })
}
export function editData(id, data) {
  return axios.request({
    url: `${url}/device/${id}`,
    method: "put",
    data: data,
  })
}
export function searchTypeList(data = {}) {
  return axios.request({
    url: `${url}/device/types`,
    method: "get",
    params: data,
  })
}
export function getTypeById(id,data = {}) {
  return axios.request({
    url: `${url}/device/type/${id}`,
    method: "get",
    params: data,
  })
}
export function getTypeSelect(data = {}) {
  return axios.request({
    url: `${url}/device/types/select`,
    method: "get",
    params: data,
  })
}
export function addType(data) {
  return axios.request({
    url: `${url}/device/type`,
    method: "post",
    data: data,
  })
}
export function editType(id, data) {
  return axios.request({
    url: `${url}/device/type/${id}`,
    method: "put",
    data: data,
  })
}
export function deleteType(id,data) {
  return axios.request({
    url: `${url}/device/type/${id}`,
    method: "delete",
    params:data,
  })
}

export function editStatus(id, data) {
  return axios.request({
    url: `${url}/device/status/${id}`,
    method: "put",
    data: data,
  })
}
export function devicePosition(id,data = {}) {
  return axios.request({
    url: `${url}/device/position/${id}`,
    method: "get",
    params: data,
  })
}
export function deviceOut(id,data = {}) {
  return axios.request({
    url: `${url}/device/push/${id}`,
    method: "post",
    data: data,
  })
}
export function deviceCode(id,data = {}) {
  return axios.request({
    url: `${url}/device/code/${id}`,
    method: "get",
    params: data,
  })
}