
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";
@Component
export default class SearchOrderType extends Vue {
  @Prop({ default: false }) clear!: boolean;
  private orderType: string = "";
  private options: any = [
    {
      value: "0",
      label: "初始化",
    },
    {
      value: "1",
      label: "接单/派单",
    },
    {
      value: "2",
      label: "执行中",
    },
    {
      value: "3",
      label: "付款",
    },
      {
          value: "4",
          label: "结束",
      },
      {
          value: "9",
          label: "取消",
      },
  ];
  @Emit("getOrderType")
  sendOrderType(orderType: number) {}
  mounted() {
    if (this.clear === true) {
      this.orderType = "";
    }
  }
}
