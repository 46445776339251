import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = ``

export function getList(data = {}) {
  return axios.request({
    url: `${url}/customers`,
    method: "get",
    params: data,
  })
}

export function getListById(id,data = {}) {
  return axios.request({
    url: `${url}/customer/${id}`,
    method: "get",
    params: data,
  })
}
export function addData(data = {}){
  return axios.request({
    url: `${url}/customer`,
    method: "post",
    data: data,
  })
}
export function editData(id,data = {}){
  return axios.request({
    url: `${url}/customer/${id}`,
    method: "put",
    data: data,
  })
}

export function deleteData(id,data = {}) {
  return axios.request({
    url: `${url}/customer/${id}`,
    method: "delete",
    data: data,
  })
}
export function getTypeList(data = {}) {
  return axios.request({
    url: `${url}/customer/type`,
    method: "get",
    params: data,
  })
}

export function getTypeById(id,data = {}) {
  return axios.request({
    url: `${url}/customer/type/${id}`,
    method: "get",
    params: data,
  })
}

export function addType(data = {}){
  return axios.request({
    url: `${url}/customer/type`,
    method: "post",
    data: data,
  })
}

export function deleteType(id,data = {}) {
  return axios.request({
    url: `${url}/customer/type/${id}`,
    method: "delete",
    data: data,
  })
}

export function getStatusList(data = {}) {
  return axios.request({
    url: `${url}/customer/status`,
    method: "get",
    params: data,
  })
}

export function getStatusById(id,data = {}) {
  return axios.request({
    url: `${url}/customer/status/${id}`,
    method: "get",
    params: data,
  })
}

export function addStatus(data = {}){
  return axios.request({
    url: `${url}/customer/status`,
    method: "post",
    data: data,
  })
}

export function deleteStatus(id,data = {}) {
  return axios.request({
    url: `${url}/customer/status/${id}`,
    method: "delete",
    data: data,
  })
}

export function getOriginList(data = {}) {
  return axios.request({
    url: `${url}/customer/origin`,
    method: "get",
    params: data,
  })
}

export function getOriginById(id,data = {}) {
  return axios.request({
    url: `${url}/customer/origin/${id}`,
    method: "get",
    params: data,
  })
}

export function addOrigin(data = {}){
  return axios.request({
    url: `${url}/customer/origin`,
    method: "post",
    data: data,
  })
}

export function deleteOrigin(id,data = {}) {
  return axios.request({
    url: `${url}/customer/origin/${id}`,
    method: "delete",
    data: data,
  })
}

export function getMaturityList(data = {}) {
  return axios.request({
    url: `${url}/customer/maturity`,
    method: "get",
    params: data,
  })
}

export function getMaturityById(id,data = {}) {
  return axios.request({
    url: `${url}/customer/maturity/${id}`,
    method: "get",
    params: data,
  })
}

export function addMaturity(data = {}){
  return axios.request({
    url: `${url}/customer/maturity`,
    method: "post",
    data: data,
  })
}

export function deleteMaturity(id,data = {}) {
  return axios.request({
    url: `${url}/customer/maturity/${id}`,
    method: "delete",
    data: data,
  })
}


export function getSectorList(data = {}) {
  return axios.request({
    url: `${url}/customer/sector`,
    method: "get",
    params: data,
  })
}

export function getSectorById(id,data = {}) {
  return axios.request({
    url: `${url}/customer/sector/${id}`,
    method: "get",
    params: data,
  })
}

export function addSector(data = {}){
  return axios.request({
    url: `${url}/customer/sector`,
    method: "post",
    data: data,
  })
}

export function deleteSector(id,data = {}) {
  return axios.request({
    url: `${url}/customer/sector/${id}`,
    method: "delete",
    data: data,
  })
}

export function getTagList(data = {}) {
  return axios.request({
    url: `${url}/customer/tag`,
    method: "get",
    params: data,
  })
}

export function getTagById(id,data = {}) {
  return axios.request({
    url: `${url}/customer/tag/${id}`,
    method: "get",
    params: data,
  })
}

export function addTag(data = {}){
  return axios.request({
    url: `${url}/customer/tag`,
    method: "post",
    data: data,
  })
}

export function deleteTag(id,data = {}) {
  return axios.request({
    url: `${url}/customer/tag/${id}`,
    method: "delete",
    data: data,
  })
}

export function getWealthList(data = {}) {
  return axios.request({
    url: `${url}/customer/wealth`,
    method: "get",
    params: data,
  })
}

export function getWealthById(id,data = {}) {
  return axios.request({
    url: `${url}/customer/wealth/${id}`,
    method: "get",
    params: data,
  })
}

export function addWealth(data = {}){
  return axios.request({
    url: `${url}/customer/wealth`,
    method: "post",
    data: data,
  })
}

export function deleteWealth(id,data = {}) {
  return axios.request({
    url: `${url}/customer/wealth/${id}`,
    method: "delete",
    data: data,
  })
}

export function getCharacterList(data = {}) {
  return axios.request({
    url: `${url}/customer/character`,
    method: "get",
    params: data,
  })
}

export function getCharacterById(id,data = {}) {
  return axios.request({
    url: `${url}/customer/character/${id}`,
    method: "get",
    params: data,
  })
}

export function addCharacter(data = {}){
  return axios.request({
    url: `${url}/customer/character`,
    method: "post",
    data: data,
  })
}

export function deleteCharacter(id,data = {}) {
  return axios.request({
    url: `${url}/customer/character/${id}`,
    method: "delete",
    data: data,
  })
}

export function getMissions($id, $params={}){
  return axios.request({
    url: `${url}/customer/mission`,
    method: "get",
    params: params,
  })
}

export function getMissionById($id, $params={}){
  return axios.request({
    url: `${url}/customer/mission/${id}`,
    method: "get",
    params: params,
  })
}

export function getMissionByCustomId($id, $params={}){
  return axios.request({
    url: `${url}/customer/mission/user/${id}`,
    method: "get",
    params: params,
  })
}

export function addMission(data = {}){
  return axios.request({
    url: `${url}/customer/mission`,
    method: "post",
    data: data,
  })
}

export function updateMission(id,data = {}){
  return axios.request({
    url: `${url}/customer/mission/${id}`,
    method: "put",
    data: data,
  })
}

export function deleteMission(id,data = {}) {
  return axios.request({
    url: `${url}/customer/mission/${id}`,
    method: "delete",
    data: data,
  })
}





