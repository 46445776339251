import axios, {
    ResponseData
} from './base.ts'
import {
    AxiosPromise
} from 'axios'

const url = 'report'
export function getOrderHourReport(data = {}) {
    return axios.request({
        url: `${url}/order/hour`,
        method: "get",
        params: data,
    })
}

export function getOrderMonthReport(data = {}) {
    return axios.request({
        url: `${url}/order/month`,
        method: "get",
        params: data,
    })
}

export function getOrderYearReport(data = {}) {
    return axios.request({
        url: `${url}/order/year`,
        method: "get",
        params: data,
    })
}
