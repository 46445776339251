import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = ``

export function getList(data = {}) {
  return axios.request({
    url: `${url}/agents`,
    method: "get",
    params: data,
  })
}
export function getTypeList(data = {}) {
  return axios.request({
    url: `${url}/agent/types/select`,
    method: "get",
    params: data,
  })
}
export function getListById(id,data = {}) {
  return axios.request({
    url: `${url}/agent/${id}`,
    method: "get",
    params: data,
  })
}
export function getTypeById(id,data = {}) {
  return axios.request({
    url: `${url}/store/type/${id}`,
    method: "get",
    params: data,
  })
}
export function getAgentListSelect(data = {}) {
  return axios.request({
    url: `${url}/agents/select`,
    method: "get",
    params: data,
  })
}
export function deleteData(id,data) {
  return axios.request({
    url: `${url}/agent/${id}`,
    method: "delete",
    params:data,
  })
}
export function addData(data) {
  return axios.request({
    url: `${url}/agent`,
    method: "post",
    data: data,
  })
}
export function editData(id, data) {
  return axios.request({
    url: `${url}/agent/${id}`,
    method: "put",
    data: data,
  })
}
export function editPrice(id, data) {
  return axios.request({
    url: `${url}/agent/devided/${id}`,
    method: "put",
    data: data,
  })
}
export function agentAppointment(id, data) {
  return axios.request({
    url: `${url}/agent/appointment/${id}`,
    method: "put",
    data: data,
  })
}
export function agentRefuse(id, data) {
  return axios.request({
    url: `${url}/agent/refuse/${id}`,
    method: "put",
    data: data,
  })
}

export function getUnboundList(data = {}) {
  return axios.request({
    url: `${url}/device/unbound`,
    method: "get",
    params: data,
  })
}
export function shareDevice(id,data) {
  return axios.request({
    url: `${url}/agent/distribution/${id}`,
    method: "post",
    data: data,
  })
}
export function updateDeduction(id, data={}){
  return axios.request({
    url:`${url}/agent/deduction/${id}`,
    method: "put",
    data,
  })
}
export function getCash(data = {}) {
  return axios.request({
    url: `${url}/agents/cash/out`,
    method: "get",
    params: data,
  })
}

//提现同意
export function cashAgree(id, data={}){
  return axios.request({
    url:`${url}/agents/cash/out/appointment/${id}`,
    method: "put",
    data,
  })
}
//提现拒绝
export function cashRefuse(id, data={}){
  return axios.request({
    url:`${url}/agents/cash/out/refuse/${id}`,
    method: "put",
    data,
  })
}