<template>
    <div class="box" v-loading="loading">
        <div class="search" style="margin: 0 auto; width:50%;">
            <el-input placeholder="请输入充电宝号" v-model="search" class="input-with-select">
                <el-button slot="append" icon="el-icon-search" @click="searchSlot"></el-button>
            </el-input>
        </div>
        <div class="details" :style="{'width':deviceData.battery_total>=32?'500px':'300px'}">
            <div class="name">便民充电箱</div>
            <div v-for="(value,key) in batteryDetail" :key="key">
                <div v-for="(v,k) in value" :key="k" :class="{'flashing-border': (searchResult && searchResult[0] === key && searchResult[1] === k)}" style="width:100px;height:30px;border-radius: 10px;border:1px solid #ccc;margin-top:10px;" @click="deviceOut(v.rl_slot)">
                    <el-tooltip
                        effect="dark"
                        placement="top-start"
                        :content="v.rl_pbid.toString()" v-if="v.rl_pbid">
                        <el-progress :show-text="false" :stroke-width="5" :percentage="(v.rl_lvl>4?4:v.rl_lvl)*25" style="margin-top:12px;width:50px;margin-left:25px;"></el-progress>
                    </el-tooltip>
                    <div v-else  style="width:98px;height:28px;border-radius: 10px;background-color:#eee" text-color="#fff"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let _this;
import {getDataById,devicePosition,getTypeById,deviceOut} from "@/api/device.js";
export default {
    props:['id'],
    data(){
        return{
            deviceData:[],
            deviceDetail:[],
            batterys:[],
            batteryDetail:[],
            loading: false,
            search:"",
            searchResult:[],
        }
    },
    mounted(){
        _this = this
        this.init()
        window.addEventListener('onWSMessage', this.getSocketData)
    },
    methods:{
        // 初始化数据
        init() {
            getDataById(this.id).then(res=>{
                if (res.code === 200){
                    this.deviceData = res.data
                    this.batterys = this.generateArray(res.data.battery_total)
                    console.log(this.batterys)
                }
            })
            devicePosition(this.id).then(res=>{
                if (res.code === 200){
                    this.deviceDetail = res.data
                    console.log(res.data)
                }
            })
        },
        deviceOut(e){
            this.$confirm("确认弹出该充电宝吗？")
                .then(() => {
                    deviceOut(this.id,{'slot':e}).then(res=>{
                        if(res.code === 200){
                            console.log('chenggong')
                            this.loading = true
                        }
                    })
                })
                .catch(() => {});
            
        },
        getSocketData(e){
            if(e.detail.event === 'inventory' && e.detail.code === 200){
                this.deviceDetail = e.detail.data
                this.batteryDetail= this.batterys.reduce((acc, innerArray) => {
                    return acc.concat([innerArray.flatMap(slotNumber => e.detail.data.slot.find(obj => obj.rl_slot === slotNumber))]);
                }, []);
                console.log(this.batteryDetail)

            }
            if(e.detail.event === 'force_power'){
                console.log(e.detail)
                if(e.detail.code === 200){
                    this.loading = false
                    this.$message({
                        message: '已弹出',
                        type: 'success'
                    });
                    this.init()
                }
            }
        },
        searchSlot(){
            let search_text = this.search;
            let result = this.batteryDetail.find((value, key) => {
                return value.some((v, k) => {
                    return v.rl_pbid === search_text;
                });
            });

            if (result) {
                let key = this.batteryDetail.indexOf(result);
                let k = result.findIndex((v) => v.rl_pbid === search_text);
                this.searchResult = [key, k];
                setTimeout(() => {
                    this.searchResult = [];
                }, 6000);
            } else {
                this.$message.error('未找到充电宝');
            }
        },
        generateArray(n) {
            let arr = [];
            if (n >= 32) {
                // Generate 1 to n array
                arr = Array.from({ length: n }, (_, i) => i + 1);
                // Split array into 4 equal parts
                const chunkSize = Math.ceil(n / 4);
                arr = Array.from({ length: 4 }, (_, i) => arr.slice(i * chunkSize, (i + 1) * chunkSize));
            } else {
                // Split array into 2 equal parts
                const chunkSize = Math.ceil(n / 2);
                arr = Array.from({ length: 2 }, (_, i) => Array.from({ length: chunkSize }, (_, j) => j + i * chunkSize + 1));
            }

            // Reverse arrays with even keys
            for (let i = 0; i < arr.length; i++) {
                // if (i % 2 === 0) {
                arr[i] = arr[i].reverse();
                // }
            }
            
            return arr;
        }
    }
};
</script>
<style lang="scss" scoped>
.box{
    .title{
        font-size:16px;
    }
    .details{
        display: flex;
        justify-content: space-around;
        margin:20px auto;
        border:1px solid #ccc;
        border-radius: 30px;
        padding:60px 0 30px 0;
        position: relative;
        .name{
            font-size:20px;
            position: absolute;
            left:30px;
            top:20px;
        }
    }
}

.flashing-border {
    animation: flashing 1s ease-in-out infinite alternate;
}

@keyframes flashing {
    0% {
        box-shadow: 0 0 0 #ccc;
        border:1px solid #ccc;
    }
    100% {
        box-shadow: 0 0 20px rgba(245, 108, 108, 0.6);
        border:1px solid #F56C6C;
    }
}
</style>
  