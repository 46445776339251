<template>
    <div class="map-content">
        <div class="map-left" id="mapContent">

        </div>
    </div>
</template>

<script>
import {getListSelect} from "@/api/temple.js"
export default {
    props:{
        
    },
    data() {
        return {
            temples:[],
            geometries:[],
            map_size:5,
            center_point_lat:34.11856816068578,
            center_point_lng:108.01399123678493
        };
    },

    mounted(){
        let that = this
        function initMap() {
            //初始化地图
            var geometries = that.temples
            var drawContainer = document.getElementById('mapContent');
            var center = new TMap.LatLng(that.center_point_lat, that.center_point_lng);//设置中心点坐标
            var map = new TMap.Map(drawContainer, {
                zoom:that.map_size, //设置地图缩放级别
                pitch: 40,
                center: center, //设置地图中心点坐标
                mapStyleId: "style1", //个性化样式
                draggable: true,
                scrollable: true,
            });

            // 创建点聚合实例
            var markerCluster = new TMap.MarkerCluster({
                id: 'cluster',
                map: map,
                enableDefaultStyle: true, // 启用默认样式
                minimumClusterSize: 2, // 形成聚合簇的最小个数
                enableDefaultStyle: true,
                geometries: geometries,
                zoomOnClick: false, // 点击簇时放大至簇内点分离
                gridSize: 60, // 聚合算法的可聚合距离
                averageCenter: false, // 每个聚和簇的中心是否应该是聚类中所有标记的平均值
                maxZoom: 8 // 采用聚合策略的最大缩放级别
            });

            markerCluster.on("click", (evt)=>{
                console.log(evt)
                if(evt.cluster.geometries.length>1){ // 聚合数量>0 放大地图
                    map.easeTo({zoom:that.map_size++,center:new TMap.LatLng(evt.cluster.center.lat, evt.cluster.center.lng)},{duration:2000})
                }else{ // 聚合数量是1 弹出框
                    var infoWindow = new TMap.InfoWindow({
                        map: map,
                        position: new TMap.LatLng(evt.cluster.center.lat, evt.cluster.center.lng),
                        zIndex: 10, //信息窗的z-index值
                        offset: { x: -3, y: -35 }, //向上偏移35像素坐标，向左偏移3像素坐标
                        content:"<div>"+
                            (evt.cluster.geometries[0].image?"<img style='width:200px;' src='"+evt.cluster.geometries[0].image+"' />" : "")+
                            "<p style='font-size:12px;'>"+evt.cluster.geometries[0].name+"</p>"+
                            "</div>"
                    })
                }
                return false
            })
        }
        getListSelect().then(res=>{
            if(res.code === 200){
                console.log(res.data)
                res.data.forEach((value,key)=>{
                    if(parseInt(value.deleted) === 0){
                        let tmp = {
                            position:new TMap.LatLng(value.lat,value.lng),
                            name:value.name,
                            image:value.image,
                            type:value.religious_type
                        }
                        this.temples.push(tmp)
                    }
                })
                initMap()
            }
        })
    },

    methods: {
        
    }
};
</script>

<style scoped lang="scss">
    @import "@/static/default";
    .map-content{
        width: 100%;
        box-sizing:border-box;
        display:inline-flex;
        .map-left{
            width: 100%;
            height: 600px;
            flex-shrink:1;
        }

    }
    .clusterBubble {
      border-radius: 50%;
      color: #fff;
      font-weight: 500;
      text-align: center;
      opacity: 0.88;
      background-image: linear-gradient(139deg, #4294FF 0%, #295BFF 100%);
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.20);
      position: absolute;
      top: 0px;
      left: 0px;
    }
</style>