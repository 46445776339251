
    import {Component, Prop, Vue, Emit, Watch} from 'vue-property-decorator';
    // @ts-ignore
    import {getProvince, getCity, getArea} from "@/api/public"
    let that;
    @Component
    export default class districts extends Vue {
        @Prop({default: "请选择地区"}) placeholder!: string
        @Prop({default: 3}) level: number
        @Prop() value:any
        @Prop({default:false}) showAll:boolean
        private pcaValue:any = []
        private dis_provinces:Array<any> = []
        private dis_cities:Array<any> = []
        private dis_areas:Array<any> = []
        private dis_province:any = ''
        private dis_province_str:string = '全国'
        private dis_city:any = ''
        private dis_city_str:string = ''
        private dis_area:any = ''
        private dis_area_str:string = ''
        private dis_show_area:boolean = false
        private dis_show_all_city:boolean = false

        private special_province = ['北京市','天津市','重庆市']

        public mounted(): void {
            this.pcaValue = this.value
            that = this
            getProvince().then(res=>{
                if (res.code === 200) this.dis_provinces = res.data
                this.$forceUpdate()
            })
            if(this.level === 3) this.dis_show_area = true
        }

        @Watch("value", {immediate: true, deep: true})
        private watchMenuData(newValue: any, oldValue: any) {
            this.dis_province = newValue[0]
            this.dis_provinces.forEach((value,key)=>{
                if(value.id === newValue[0]){
                    this.dis_province_str = value.full_name
                    if(this.special_province.indexOf(value.full_name)>-1){
                        if(this.level === 2) this.dis_show_area = true
                        if(this.showAll) this.dis_show_all_city = false
                    }else{
                        if(this.level === 2) this.dis_show_area = false
                        if(this.showAll) this.dis_show_all_city = true
                    }
                    getCity(value.province_id).then(res=>{
                        if(res.code === 200) {
                            this.dis_cities = res.data
                            this.dis_city = newValue[1]
                            res.data.forEach((v,k)=>{
                                if(v.id === newValue[1]){
                                    this.dis_city_str = v.full_name
                                    getArea(v.city_id).then(res=>{
                                        if(res.code === 200) {
                                            this.dis_areas = res.data
                                            this.dis_area = newValue[2]
                                            res.data.forEach((value,key)=>{
                                                if(value.id === newValue[2]){
                                                    this.dis_area_str = value.full_name
                                                }
                                            })
                                        }
                                        this.$forceUpdate()
                                    })
                                }
                            })
                        }
                        this.$forceUpdate()
                    })
                    return ;
                }
            })
        }

        public provinceChange(e){
            this.dis_city = ''
            this.dis_city_str = ''
            this.dis_area = ''
            this.dis_area_str = ''
            if(e === 0){
                this.dis_province_str = '全国'
                this.dis_cities = [];
                this.dis_areas = [];
                this.handlePCAChoose()
            }else{
                this.dis_provinces.forEach((value,key)=>{
                    if(value.id === e){
                        this.dis_province_str = value.full_name
                        if(this.special_province.indexOf(value.full_name)>-1){
                            if(this.level === 2) this.dis_show_area = true
                            if(this.showAll) this.dis_show_all_city = false
                        }else{
                            if(this.level === 2) this.dis_show_area = false
                            if(this.showAll) this.dis_show_all_city = true
                        }
                        getCity(value.province_id).then(res=>{
                            if(res.code === 200) {
                                this.dis_cities = res.data
                            }
                            this.$forceUpdate()
                        })
                        return ;
                    }
                })
            }
        }

        public cityChange(e){
            this.dis_area = ''
            this.dis_area_str = ''
            if(e === 0){
                this.dis_city_str = '全省'
                this.dis_areas = [];
                this.handlePCAChoose()
            }else{
                this.dis_cities.forEach((value,key)=>{
                    if(value.id === e){
                        this.dis_city_str = value.full_name
                        if(!this.dis_show_area) this.handlePCAChoose()
                        getArea(value.city_id).then(res=>{
                            if(res.code === 200) {
                                this.dis_areas = res.data
                            }
                            this.$forceUpdate()
                        })
                        return ;
                    }
                })
            }
        }

        public areaChange(e){
            if(e === 0){
                this.dis_city_str = '全市'
                this.handlePCAChoose()
            }else{
                this.dis_areas.forEach((value,key)=>{
                    if(value.id === e){
                        this.dis_area_str = value.full_name
                        this.handlePCAChoose()
                        return;
                    }
                })
            }
        }

        @Emit('choose',)
        public handlePCAChoose() {
            return {
                province_code: this.dis_province,
                province : this.dis_province_str,
                city_code: this.dis_city,
                city : this.dis_city_str,
                area_code: this.dis_area,
                area : this.dis_area_str
            }
        }
    }

