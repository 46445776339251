<template>
  <section style="padding:0 5%; display:inline-flex;width:100%">
    <div class="left">
      <el-descriptions direction="vertical" :column="4" border>
        <el-descriptions-item label="客户名">{{data.name}}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{data.phone}}</el-descriptions-item>
        <el-descriptions-item label="性别">{{data.gender === 0 ? '男' : '女'}}</el-descriptions-item>
        <el-descriptions-item label="等级">
          <el-rate
            v-model="data.level"
            disabled
            show-score
            text-color="#ff9900"
            score-template="{value}">
          </el-rate>
        </el-descriptions-item>
        <el-descriptions-item label="邮箱">{{data.email ? data.email : '-'}}</el-descriptions-item>
        <el-descriptions-item label="公司">{{data.company ? data.company : '-'}}</el-descriptions-item>
        <el-descriptions-item label="负责人">
          <span v-if="data.manager_id === 0">-</span>
          <el-tag v-else>{{data.manager.name}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="归属代理">
          <span v-if="data.agent_id === 0">-</span>
          <el-tag v-else>{{data.agent.name}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="省市区" :span="2">
          {{data.province_id>0 ? data.province.name : ''}} {{data.city_id>0 ? data.city.name : ''}} {{data.area_id>0 ? data.area.name : ''}}
        </el-descriptions-item>
        <el-descriptions-item label="详细地址" :span="2">{{data.address ? data.address : '-'}}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions direction="vertical" :column="4" border style="margin-top:20px;">
        <el-descriptions-item label="客户类别">{{data.type>0 ? data.type_name.name : '-'}}</el-descriptions-item>
        <el-descriptions-item label="客户状态">{{data.status>0 ? data.status_name.name : '-'}}</el-descriptions-item>
        <el-descriptions-item label="客户来源">{{data.origin>0 ? data.origin_name.name : '-'}}</el-descriptions-item>
        <el-descriptions-item label="成熟度">{{data.maturity>0 ? data.maturity_name.name : '-'}}</el-descriptions-item>
        <el-descriptions-item label="人物标签">{{data.tag>0 ? data.tag_name.name : '-'}}</el-descriptions-item>
        <el-descriptions-item label="财富标签">{{data.wealth>0 ? data.wealth_name.name : '-'}}</el-descriptions-item>
        <el-descriptions-item label="性格标签">{{data.character>0 ? data.character_name.name : '-'}}</el-descriptions-item>
        <el-descriptions-item label="所属行业">{{data.sector>0 ? data.sector_name.name : '-'}}</el-descriptions-item>

        <el-descriptions-item label="介绍">{{data.info}}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="right">
      <div class="right-top">
        <div class="right-top-title">追踪记录</div>
        <div class="right-top-button" @click="addMissionHandle"><i class="el-icon-plus"></i> 添加</div>
      </div>
    </div>

    <el-dialog title="添加追踪" :visible.sync="dialogAddMission" append-to-body>
      <div>
        <div class="add_mission_form_list"></div>
        <div class="add_mission_form_list"></div>
        <div class="add_mission_form_list"></div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogAddMission = false">取 消</el-button>
        <el-button type="primary" @click="dialogAddMission = false">确 定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>

  let _this;
  let id = 0;
  import { getListById,getMissionByCustomId,addMission } from "@/api/customer.js";
  import { getGroups} from "@/api/system.js";
export default {
    props: {
      id: Number
    },
    data() {
      return {
        data:[],
        missions:[],
        dialogAddMission:false,
        formLabelWidth:'120px',
        form:{
          title:'',
          customer_id:0,
          participant:[],
          collaborators:[],
          is_coordination:0,
          meeting_time:'',
          start_time:'',
          end_time:'',
          content:'',
          purpose:'',
          comment:''
        }
      };
    },

    computed: {
    },

    mounted() {
      _this = this;
      _this.groupID = _this.$store.state.user.userData.group_id
      _this.init()
      _this.getMissions()
    },

    methods: {
      // 初始化数据
      init() {
        getListById(this.id).then(res=>{
          if(res.code === 200){
            this.data = res.data
          }
        })
      },
      getMissions(){
        getMissionByCustomId(this.id).then(res=>{
          if(res.code === 200){
            this.missions = res.data
          }
        })
      },
      addMissionHandle(){
        this.dialogAddMission = true
      },
      close() {
        _this.$emit("close");
      },
    }
  };
</script>

<style lang="scss" scoped>
  .left{
    width: 500px;
    flex-shrink: 0;
  }
  .right{
    width: 100%;
    flex-shrink: 1;
    margin-left: 20px;
    border: 1px solid #EBEEF5;
    &-top{
      width:100%;
      color: #909399;
      background: #fafafa;
      display:inline-flex;
      justify-content:space-between;
      padding: 12px 10px;
      box-sizing: border-box;
      font-weight: 400;
      line-height: 1.5;
      text-align: left;
      border-spacing: 2px;
      font-size: 14px;
      border-bottom: 1px solid #EBEEF5;
      &-button{
        cursor:default;
      }
    }
  }
</style>