<template>
  <section>
    <div class="container">
      <div class="worker-tooltips" style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start">
        <div class="search-box"><h1>素材库</h1></div>
        <div class="control-box">
          <el-select v-model="params.temple_id" placeholder="请选择场所" clearable style="margin-left:10px;width:150px;margin-top: 10px;">
            <el-option :value="0" label="官方"></el-option>
            <el-option
              v-for="(value,key) in temple"
              filterable
              :key="key"
              :label="value.name"
              :value="value.id">
            </el-option>
          </el-select>
          <el-select v-model="params.religious_type" placeholder="请选择分类" clearable style="margin-left:10px;width:150px;margin-top: 10px;">
            <el-option
              v-for="(value,key) in religiousTypeList"
              :key="key"
              :label="value.label"
              :value="value.value">
              <div style="width:100%;display:inline-flex;align-items:center;">
                <el-image style="width:20px;height: 20px;" :src="value.icon"></el-image>
                <span style="margin-left: 10px;">{{value.label}}</span>
              </div>
            </el-option>
          </el-select>
          <el-select v-model="params.status" placeholder="请选择状态" clearable style="margin-left:10px;width:150px;margin-top: 10px;">
            <el-option
              v-for="(value,key) in statusList"
              :key="key"
              :label="value.label"
              :value="value.value">
            </el-option>
          </el-select>
          <el-select v-model="params.is_share" placeholder="请选择共享状态" clearable style="margin-left:10px;width:150px;margin-top: 10px;">
            <el-option
              v-for="(value,key) in shareList"
              :key="key"
              :label="value.label"
              :value="value.value">
            </el-option>
          </el-select>
          <el-button icon="el-icon-search" style="margin-left:10px;margin-top: 10px;">搜索</el-button>
          <el-button icon="el-icon-plus" type="primary" style="margin-right:10px;margin-top: 10px;" v-if="admin_permission.indexOf('add')>-1">添加素材</el-button>
        </div>
      </div>

      <div class="library-content" ref="libraryContent" style="margin-top: 20px;">
        <el-tabs v-model="params.file_type">
          <el-tab-pane label="图片" name="0">
            <div class="image-box box">
              <waterfall :col="col" :gutterWidth="4" :data="list" :lazyDistance="200">
                <template>
                  <div class="library-list" v-for="(value,key) in list">
                    <img style="width:100%" :src="value.src"></img>
                  </div>
                </template>
              </waterfall>
            </div>
          </el-tab-pane>
          <el-tab-pane label="书籍" name="1">
            <div class="book-box box">
              
            </div>
          </el-tab-pane>
          <el-tab-pane label="音乐" name="2">
            <div class="music-box box">
              
            </div>
          </el-tab-pane>
          <el-tab-pane label="视频" name="3">
            <div class="video-box box">
              
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </section>
</template>

<script>
let page_name="library"
import {getListSelect} from "@/api/temple.js"
export default {
  name: "library",
  data() {
    return {
      admin_permission:[],
      col:0,
      date: [],
      temple:[],
      fileTypeList:[
        {value:0,label:'图片'},
        {value:1,label:'书籍'},
        {value:2,label:'音乐'},
        {value:3,label:'视频'},
      ],
      religiousTypeList:[
        {value:0,label:'佛家',icon:'https://cdn.cxc-group.com/manage/fo-festival-icon.png'},
        {value:1,label:'道家',icon:'https://cdn.cxc-group.com/manage/dao-festival-icon.png'},
        {value:2,label:'传统',icon:'https://cdn.cxc-group.com/manage/normal-festival-icon.png'},
      ],
      statusList:[
        {value:0,label:'下架'},
        {value:1,label:'上架'},
      ],
      shareList:[
        {value:0,label:'独立'},
        {value:1,label:'共享'},
      ],
      list:[
        {id:1,src:"https://img1.baidu.com/it/u=3889466524,1327489668&fm=253&fmt=auto&app=138&f=JPEG",name:"元宵汤圆国风国潮矢量故宫传统文化插画背景",type:2,temple_name:'官方'},
        {id:2,src:"https://pic4.zhimg.com/v2-27b00d01d2cbcdbfd600abb3d02281d7_r.jpg",name:"传统文化囚牛壁纸",type:2,temple_name:'官方'},
        {id:3,src:"https://img0.baidu.com/it/u=1277821593,318833320&fm=253&fmt=auto&app=138&f=JPEG",name:"国风手机壁纸传统文化《道藏·上清骨髓灵文鬼律》记载：北极驱邪院印的多种规格制式。睡得安稳，行的端正",type:1,temple_name:'官方'},
        {id:5,src:"https://img2.baidu.com/it/u=1417102897,1864319839&fm=253&fmt=auto&app=138&f=JPEG",name:"观世音佛像",type:0,temple_name:'官方'},
        {id:6,src:"https://img.zcool.cn/community/012c725d5d0178a8012187f4e9c2fc.jpg?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1280,limit_1/sharpen,100",name:"观音",type:0,temple_name:'官方'},
        {id:7,src:"https://img2.baidu.com/it/u=3847484273,3544946149&fm=253&fmt=auto&app=138&f=JPEG",name:"佛佑众生",type:0,temple_name:'官方'},
        {id:8,src:"https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201504%2F07%2F20150407H5014_uXH8z.thumb.400_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1725762991&t=2d9cc25e700898d8e55f24430d84a824",name:"佛说生死",type:0,temple_name:'官方'},
        {id:9,src:"https://img0.baidu.com/it/u=2255508329,326416167&fm=253&fmt=auto&app=138&f=JPEG",name:"我心向佛",type:0,temple_name:'官方'},
        {id:10,src:"https://pica.zhimg.com/v2-8defe9bd56743b9fe937f122fafa100f_r.jpg?source=1940ef5c",name:"道",type:1,temple_name:'官方'},
        {id:11,src:"https://img2.baidu.com/it/u=3549995625,2520681505&fm=253&fmt=auto&app=138&f=JPEG",name:"道符壁纸",type:1,temple_name:'官方'},
        {id:12,src:"https://img0.baidu.com/it/u=3826993468,469012222&fm=253&fmt=auto&app=138&f=JPEG",name:"乾为天",type:1,temple_name:'官方'},
        {id:13,src:"https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimage109.360doc.com%2FDownloadImg%2F2019%2F01%2F1810%2F152273576_4_20190118103055994&refer=http%3A%2F%2Fimage109.360doc.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1725763335&t=ed2e60f872ceb5522636d66514e7ab02",name:"动善时",type:0,temple_name:'官方'},
        {id:14,src:"https://e0.ifengimg.com/06/2018/1119/01F2767B52D9BF13D1947B5678E387F14F307815_size140_w1920_h1080.jpg",name:"八卦",type:1,temple_name:'官方'},
      ],
      params:{
        temple_id:'',
        file_type:0,
        religious_type:'',
        status:'',
        is_share:''
      }
    };
  },

  mounted(){
    let that = this
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    let width = this.$refs.libraryContent.offsetWidth
    this.getCol(width)
    window.onresize = () => {
      return (()=>{
        this.getCol(this.$refs.libraryContent.offsetWidth)
      })()
    }
    this.getSelectOptions()
  },

  methods: {
    getSelectOptions(){
      this.getTemple()
    },
    getCol(width) {
      if (width > 1580) {
        this.col = 5;
      } else if (1024 < width < 1580) {
        this.col = 4;
      } else if (768 < width < 1024) {
        this.col = 2;
      }
    },
    getTemple() {
      getListSelect().then(res=>{
        if(res.code === 200){
          this.temple = res.data
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/static/default";

.container {
  background-color: #fff !important;
  padding: 0 32px;
  overflow-y:overlay;
  padding-bottom: 20px;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  .table {
    margin-top: 24px !important;
  }
  .toolbar {
    background: #fff;
    width: calc(100% + 64px);
    padding: 16px 32px 26px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .word{
      margin-right:50px;
      font-size:14px;
      span{
        font-size:18px;
      }
    }
  }
  .search-box{
    margin-top:20px;
  }
  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    padding-top:10px;
    &-input{
      width: 150px;
    }
  }
}
.box{
  width: 100%;
  min-height: 590px;
}
</style>
<style scoped>
::-webkit-scrollbar {
    display: none;
}
</style>