import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = ``

export function getList(data = {}) {
  return axios.request({
    url: `${url}/users`,
    method: "get",
    params: data,
  })
}
export function getSubscribeList(id,params = {}) {
  return axios.request({
    url: `${url}/user/subscribe/${id}`,
    method: "get",
    params,
  })
}
export function getPlaceList(data = {}) {
  return axios.request({
    url: `${url}/assistant/users`,
    method: "get",
    params: data,
  })
}
export function getListSelect(data = {}) {
  return axios.request({
    url: `${url}/users/select`,
    method: "get",
    params: data,
  })
}
export function getDataById(id,params={}){
  return axios.request({
    url: `${url}/user/${id}`,
    method: "get",
    params,
  })
}

export function deleteData(id,data) {
  return axios.request({
    url: `${url}/user/${id}`,
    method: "delete",
    params:data,
  })
}
export function editData(id, data) {
  return axios.request({
    url: `${url}/user/${id}`,
    method: "put",
    data: data,
  })
}

export function getPlaceUserById(id,params={}){
  return axios.request({
    url: `${url}/assistant/user/${id}`,
    method: "get",
    params,
  })
}
export function addPlaceUser(data) {
  return axios.request({
    url: `${url}/assistant/user`,
    method: "post",
    data: data,
  })
}
export function editPlaceUser(id, data) {
  return axios.request({
    url: `${url}/assistant/user/${id}`,
    method: "put",
    data: data,
  })
}
export function deletePlaceUser(id,data) {
  return axios.request({
    url: `${url}/assistant/user/${id}`,
    method: "delete",
    params:data,
  })
}

export function getPlaceUserAuthById(id,params={}){
  return axios.request({
    url: `${url}/assistant/user/auth/${id}`,
    method: "get",
    params,
  })
}
export function passPlaceUserAuthById(id, data) {
  return axios.request({
    url: `${url}/assistant/user/auth/  
    ${id}`,
    method: "put",
    data: data,
  })
}

