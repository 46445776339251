<template>
    <div class="new-event-box">
        <div class="new-event-empty" v-if="list.length === 0">
            <el-empty :image-size="70" description="暂无事件"></el-empty>
        </div>
        <div class="new-event-content" v-else>
            <div class="new-event-list">

            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        
    },
    data() {
        return {
            list:[
                {id:1,title:'《缘帮手》账号申请',type:0,tag:'缘帮手',content:""}
            ]
        };
    },

    mounted(){
        
    },

    methods: {
        
    }
};
</script>

<style scoped lang="scss">
    @import "@/static/default";
    .time-order-box{
        width:100%;
        height: 400px;
        .time-order-charts{
            width:100%;
            height: 400px;
        }
    }
</style>
<style>

</style>