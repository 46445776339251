<template>
  <section>
    <div class="container">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>订单列表</h1></div>
          <div class="control-box">
            <el-select v-model="params.temple_id" placeholder="请选择需搜索寺庙" filterable clearable style="margin-left:10px;margin-top:10px;" @change="templeChange">
              <!-- <el-option label="请选择需搜索投放点">请选择需搜索投放点</el-option> -->
              <el-option
                v-for="item in templeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.group_id"  placeholder="请选择需搜索行善类型" filterable clearable style="margin-left:10px;margin-top:10px;" v-if="params.temple_id!=''" >
              <!-- <el-option label="请选择需搜索公司">请选择需搜索公司</el-option> -->
              <el-option
                v-for="item in groupList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <!-- <el-input class="control-box-input" v-model="params.phone" style="margin-left:10px;margin-top:10px;" placeholder="请输入手机号" clearable></el-input> -->
            <!-- <el-input class="control-box-input" v-model="params.price" style="margin-left:10px;margin-top:10px;" type="number" placeholder="请输入金额" clearable></el-input> -->
            <el-button icon="el-icon-search" @click="searchData" style="margin-left:10px;margin-top: 10px;">搜索</el-button>
            <el-button icon="el-icon-plus" type="primary" style="margin-left:10px;margin-top: 10px;" @click="handleOpenAdd">添加</el-button>
          </div>
        </div>
      <!--订单列表-->  


      <el-table :data="table" class="table" width="1000">
        <!-- 数据展示区 -->
        <el-table-column label="寺庙" prop="temple_name"  align="center" width="140" fixed="left">
        </el-table-column>
        <el-table-column
          label="行善类型"
          prop="name"
          align="center"
        ></el-table-column>
        <el-table-column
          label="金额设置列表"
          prop="prices"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              <el-tag effect="plain" size="small" v-for="(value,index) in scope.row.prices" :key="index">{{value}}元</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="created_at"
          align="center"
        ></el-table-column>
        <el-table-column
          label="更新时间"
          prop="updated_at"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="100" fixed="right">
          <template slot-scope="scope">
            <div

            >
              <el-link style="color: #409eff;" @click="editTable(scope.row.id)">
                <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                  <i class="el-icon-edit" style="font-size:16px;"></i>
                </el-tooltip>
              </el-link>
              <el-link style="color: #409eff;margin-left:10px;" @click="handleDelete(scope.row.id)" >
                <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
                <i class="el-icon-delete-solid" style="font-size:16px;"></i>
                </el-tooltip>
              </el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col class="toolbar">
        <div class="word">订单总额:  <span>{{total_amount}}</span></div>
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page.sync="params.page"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float: right;flex-shrink: 1;"
        ></el-pagination>
      </el-col>
    </div>
    
    <!-- 添加 -->
    <el-drawer
      title="添加"
      :visible.sync="addDrawer"
      :before-close="handleClose"
    >
      <add-comp
        @close="handleClose"
        @success="handleDrawerSuccess"
        @clear="clearContent"
        ref="newForm"
        v-if="addDrawer"
      ></add-comp>
    </el-drawer>
    <el-drawer
      title="更改信息"
      :visible.sync="editDrawer"
      :before-close="handleClose"
    >
      <edit-comp v-if="editDrawer" @close="handleClose" @success="handleDrawerSuccess" :id="editID" ref="newForm"></edit-comp>
    </el-drawer>
  </section>
</template>

<script>
// import { getCascader } from "@/api/provider.js";
import { editData,deleteData,getOtherBless,getOtherSelect} from "@/api/order.js";
import { getTemples,getBless} from "@/api/public.js";
import addComp from "./components/add.vue";
import editComp from "./components/edit.vue";

let page_name = 'order_list'
export default {
  name: "App",
  components:{addComp,editComp},
  data() {
    return {
      admin_permission:[],
      reasonShow:false,
      payShow:false,
      editDrawer: false,
      addDrawer:false,
      orderDetailShow:false,
      orderDetail:'',
      reason:'',
      end_time:'',//订单结束时间
      activeName:'',
      outerVisible:false,
      params: {
        page:1,
        temple_id:'',
      },
      formPrice:{id:0,price:''},
      priceVisible:false,
      statusVisible:false,
      formStatus:{id:0,status:''},
      cancelId:'',
      payId:'',//扣款订单id
      cascaderOpthion:[],
      cascaderValue:'',
      table: [],
      total: 0,
      total_amount:0,
      groupList:[],
      templeList:[],
      blessList:[],
      agentList:[],
      groupID:'',
      showBack: false,
      orderType:[
        {
          value: 0,
          label: "已付款",
        },
        {
          value: 1,
          label: "已结束",
        },
        {
          value: 2,
          label: "进行中",
        },
      ],
    };
  },
  methods: {
    // 获取订单列表
    getTable() {
      getOtherBless(this.params).then((res) => {
        if (res.code === 200) {
          console.log(res.data.data)
          this.table = res.data.data
          this.total = res.data.total;
          this.total_amount = res.data.total_amount
        }
      });
    },
    searchData(){
      this.params.page=1
      console.log(this.params)
      this.getTable()
    },
    // 打开添加
    handleOpenAdd() {
      this.addDrawer = true;
    },
    editTable(id){
      this.editID = id
      this.editDrawer = true
    },
    // 关闭添加/修改
    handleClose() {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.addDrawer = false;
          this.editDrawer = false;
          this.$refs.newForm.$refs.form.resetFields();//清空子组件的内容
        })
        .catch((_) => {});
    },
    handleDrawerSuccess(){
      this.addDrawer = false;
      this.editDrawer = false;
      this.getTable();
      this.$refs.newForm.$refs.form.resetFields();//清空子组件的内容
    },
    // 查询
    templeChange(e){
      console.log(e)
      getOtherSelect({'temple_id':e}).then(res=>{
        if(res.code === 200){
          this.groupList = res.data
        }
      })
    },
    // 删除订单
    handleDelete(index, row) {
      this.$confirm("确认删除？")
        .then((_) => {
          deleteOrder(row.id, { deleted: 1 })
            .then((data) => {
              if (data.code === 200) {
                this.getOrderInfo();
              }
            })
            .catch((error) => {});
        })
        .catch((_) => {});
    },

    // 分页
    prevpage() {
      this.params.page -= 1;
      this.getTable()
    },
    nextpage() {
      this.params.page += 1;
      this.getTable()
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getTable()
    },
  },
  mounted() {
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    this.getTable();
    this.groupID = this.$store.state.user.userData.group_id
    
    getTemples().then(res=>{
      if(res.code === 200){
        console.log(res.data)
        this.templeList = res.data
      }
    })
    getBless().then(res=>{
      if(res.code === 200){
        console.log(res)
        this.blessList = res.data
      }
    })
    // getAgentListSelect().then(res=>{
    //   if(res.code === 200){
    //     this.agentList = res.data
    //   }
    // })
  },
};
</script>

<style scoped lang="scss">
@import "@/static/default";
.temple {
  width: 100%;
  background-color: white;
  padding: 10px 10px;
  // padding-left: 50px;
}
.order {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.container {
  background-color: #fff !important;
  padding: 0 32px;
  .table {
    margin-top: 24px !important;
  }
  .toolbar {
    background: #fff;
    width: calc(100% + 64px);
    padding: 16px 32px 26px 0;
    margin-left: -32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .word{
      margin-right:50px;
      font-size:14px;
      span{
        font-size:18px;
      }
    }
  }
  .search-box{
    margin-top:20px;
    padding-left:20px;
  }
  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    padding-left:20px;
    padding-top:10px;
    &-input{
      width: 150px;
    }
  }
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
<style>
  .el-table .warning-row {
    background: rgb(250, 236, 216);
  }

  .el-table .success-row {
    background: rgb(225, 243, 216);
  }

  .el-table .danger-row {
    background: rgb(253, 226, 226);;
  }

  .el-table .info-row {
    background: rgb(233, 233, 235);
  }
</style>
