
    import {Component, Prop, Vue, Emit, Watch} from 'vue-property-decorator';
    @Component
    export default class SearchType extends Vue {
      private type: string = ''
      private options: any=[
        {
          value:'0',
          label:'整包'
        },{
          value:'1',
          label:'wgt包',
        }
      ]

      @Emit('getType')
       sendType(type: string) {}
    }
