<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-form-item label="设备号" prop="serial_number" v-if="groupID>1">
        <el-input v-model="form.serial_number" placeholder="请输入设备号,不可重复"  disabled></el-input>
      </el-form-item>
      <el-form-item label="设备号" prop="serial_number" required v-else>
        <el-input v-model="form.serial_number" placeholder="请输入设备号,不可重复"></el-input>
      </el-form-item>

      <el-form-item label="类型" prop="type_id" required v-if="groupID<=1">
        <el-select v-model="form.type_id" filterable placeholder="请选择类型" clearable>
          <el-option label="请选择类型" :value="0">请选择类型</el-option>
          <el-option
            v-for="(value,key) in typeList"
            :key="value.id"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="公司" prop="group_id" v-if="groupID<=1">
        <el-select v-model="form.group_id" filterable placeholder="请选择公司" clearable @clear="groupChange">
          <el-option label="请选择公司" :value="0">请绑定公司</el-option>
          <el-option
            v-for="(value,key) in companyList"
            :key="value.id"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投放点" prop="store_id">
        <el-select v-model="form.store_id" filterable placeholder="请选择投放点" clearable @clear="storeChange">
          <el-option label="请选择投放点" :value="0">请选择投放点</el-option>
          <el-option
            v-for="(value,key) in storeList"
            :key="value.id"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代理商" prop="agent_id">
        <el-select v-model="form.agent_id" filterable placeholder="请选择代理商" clearable @clear="agentChange">
          <el-option label="请选择代理商" :value="0">请选择代理商</el-option>
          <el-option
            v-for="(value,key) in agents_select"
            :key="value.id"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>

      
      <el-form-item label="初始配宝数" prop="battery_init" >
        <el-input-number v-model="form.battery_init" :min="0" :max="100" label="请输入初始配宝数"></el-input-number>
      </el-form-item>
      <el-form-item label="仓口数" prop="battery_total" >
        <el-input-number v-model="form.battery_total" :min="0" :max="100" label="请输入仓口数"></el-input-number>
      </el-form-item>
      <el-form-item label="开发商" prop="protocol_type" >
        <el-select v-model="form.protocol_type" filterable placeholder="请选择开发商" clearable @clear="handleAgentClear">
          <el-option label="请选择开发商" :value="0">请选择代理商</el-option>
          <el-option
            v-for="(value,key) in protocol_list"
            :key="value.id"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="快递号" prop="express"  v-if="groupID<=1">
        <el-input v-model="form.express" placeholder="请输入快递号"></el-input>
      </el-form-item>

      <el-form-item label="快递状态" prop="express" v-if="groupID<=1">
        

        <el-radio-group v-model="form.express_status" size="small">
          <el-radio :label="0">未发送</el-radio>
          <el-radio :label="1">已发送</el-radio>
          <el-radio :label="2">已收货</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  import {editData,getDataById,getTypeSelect} from "@/api/device.js";
  import {getListSelect} from "@/api/store.js"
  import {getAgentListSelect} from "@/api/agent.js"
  import {getGroups} from "@/api/system.js"
  let form = {
    serial_number: "",
    type: 0,
    store_id: 0,
    agent_id:0,
    group_id: 0,
    type_id:0,
    battery_count:0,
    battery_init:0,
    battery_total:0,
    express:'',
    protocol_type:'',
    express_status:0
  }
  export default {
    props: {
      id: Number,
      groupID:Number
    },
    data() {
      return {
        form: form,
        rules: {
          serial_number: [
            {required: true, message: "SN不能为空,不可重复", trigger: "blur"},
          ],
          type: [
            {required: true, message: "类型不能为空", trigger: "blur"},
          ]
        },
        companyList:[],
        typeList:[],
        storeList:[],
        agents:[],
        protocol_list:[
          {
            id:0,
            name:"PDRL"
          },
          {
            id:1,
            name:"PDZD"
          },
          {
            id:2,
            name:"PDHX"
          },
        ],
        agents_select:[]
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      // 初始化数据
      init() {
        getDataById(this.id).then(res=>{
          if (res.code === 200){
            this.form = res.data
            console.log(this.form)
          }
        })

        getAgentListSelect().then(res=>{
          if(res.code === 200){
            this.agents_select = res.data
          }
        })
        getTypeSelect().then(res=>{
           _this = this;
          if(res.code === 200){
            _this.typeList = res.data
            _this.$forceUpdate();
            console.log(_this.typeList)
          }
        })
        getListSelect().then(res=>{
           _this = this;
          if(res.code === 200){
            _this.storeList = res.data
            _this.$forceUpdate();
            console.log(_this.storeList)
          }
        })
        getGroups().then(res=>{
           _this = this;
          if(res.code === 200){
            _this.companyList = res.data
            _this.$forceUpdate();
          }
        })
      },
      groupChange(){
        this.form.group_id = 1
        console.log(this.form.group_id)
      },
      storeChange(){
        this.form.store_id = 0
        console.log(this.form.store_id)
      },
      agentChange(){
        this.form.agent_id = 0
        console.log(this.form.agent_id)
      },
      close() {
        _this.$emit("close");
      },
      submit(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            editData(this.id,_this.form).then(res => { 
              if (res.code === 200) {
                _this.$message({
                  message: "更新成功",
                  type: "success"
                });
                _this.$emit("success");
              }
                this.form = form
            })
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }

  .icon-dialog{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-dialog-list{
      box-sizing: border-box;
      padding: 20px 10px;
      width: 16.66%;
      font-size: 32px;
      text-align: center;
      border:1px solid #dbdbdb;
    }
    .icon-dialog-list:hover{
      color:#409eff;
      border-color:#409eff;
    }
  }
  .icon-name{
    margin-top:10px;
    font-size: 12px;
  }
  
</style>
<style>
  .el-dialog__body{
    max-height: 60vh;
    overflow-y: auto;
  }
</style>
