
    import {Component, Prop, Vue, Emit, Watch} from 'vue-property-decorator';
    @Component
    export default class SearchRole extends Vue {
      private role: string = ''
      private options: any=[
        {
          value:'1',
          label:'小师傅'
        },{
          value:'2',
          label:'居士',
        },{
          value:'0',
          label:'方丈',
        }
      ]

      @Emit('getRole')
       sendRole(role:number){}
    }
