<template>
    <div class="box">
        <div class="content">

            <el-form :model="dataForm" label-width="100px" :rules="dataFormRules" ref="dataForm" label-position="right">
                <el-form-item label="旧密码:" prop="password">
                <el-input v-model="dataForm.password" type="password" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="新密码:" prop="new_password" label-width="100px">
                <el-input v-model="dataForm.new_password" type="password" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码:" prop="checkpassword" label-width="100px">
                <el-input v-model="dataForm.checkpassword" type="password" auto-complete="off"></el-input>
                </el-form-item>
            </el-form>
            <div class="footer">
                <el-button type="primary" @click.native="handleUpdataPw('dataForm')">确认</el-button>
                <el-button type="danger">取消</el-button>
            </div>
        </div>
    </div>
</template>

<script>
let _this;
import {editPassword} from "@/api/system.js";
export default{
    data(){
         var checkNewPw = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请再次输入密码'));
            }
            if (this.dataForm.new_password !== value) {
                callback(new Error("密码不一致"));
            } else {
                callback()
            }
        };
        return{
            dataForm: {
              password: '',
              new_password: '',
              checkpassword: ''
            },
            dataFormRules: {
              password: [{
                required: true,
                message: '请输入旧密码',
                trigger: 'blur'
              }],
              newpassword: [{
                required: true,
                message: '请输入新密码',
                trigger: 'blur'
              }],
              checkpassword: [{
                required: true,
                validator: checkNewPw,
                trigger: 'blur'
              }]
            }, 
            form:[]
        }
    },
    mounted(){

    },
    methods:{
        //修改密码，调用接口更改
        handleUpdataPw(dataForm) {
            this.$refs[dataForm].validate(valid => {
                if (valid) {
                    editPassword(this.dataForm).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            message: "修改成功",
                            type: "success"
                        });
                        localStorage.removeItem('userAccount');
                        localStorage.removeItem('token');
                        localStorage.removeItem('currentMenu');
                        this.$router.push({name:'home'});
                    }
                    })
                } else {
                    return false;
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.box{
    .content{
        width:500px;
        margin:50px auto;
        .footer{
            display: flex;
            justify-content: flex-end;
            margin:10px auto;
        }
    }
}
</style>
