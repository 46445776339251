<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      
        <el-form-item label="字典名称" prop="name" required>
          <el-input v-model="form.name" placeholder="请输入字典名称"></el-input>
        </el-form-item>
        <el-form-item label="场景说明" prop="command" required>
          <el-input v-model="form.command" placeholder="请输入场景说明"></el-input>
        </el-form-item>
        <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  import {editHallData,getHallListById} from "@/api/dictionaries.js";
  export default {
    components: {},
    props: {
      id: Number
    },
    data() {
      return {
        form: {},
        templeList:[],
        rules: {
          name: [
            {required: true, message: "请输入字典名称", trigger: "blur"},
          ],
          command: [
            {required: true, message: "请输入场景说明", trigger: "blur"},
          ],
        },
        
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      // 初始化数据
      init() {
        getHallListById(this.id).then(res=>{
          if(res.code === 200){
            // console.log(res.data)
            this.form = res.data
            this.keywordResult = res.data.address
            this.form.role = parseInt(res.data.role)
          }
        })
      },
      
      close() {
        _this.$emit("close");
      },
      submit(form) {
        console.log(this.form)
        this.$refs[form].validate(valid => {
          if (valid) {
            editHallData(this.id,_this.form).then(res => {
              if (res.code === 200) {
                setTimeout(() => {
                  _this.$emit("success");
                  _this.$emit("close");
                }, 1000);
              }
            })
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }
</style>
