<template>
    <div class="year-order-box">
        <div class="year-order-tool">

        </div>
        <div class="year-order-charts" id="yearCharts">

        </div>
    </div>
</template>
<script>
import {getOrderYearReport} from "@/api/report.js"
export default {
    props:{
        
    },
    data() {
        return {
            chart:'',
            dom:'',
            options:'',
            tooltip:{
                trigger: 'axis',
                axisPointer: {
                    // Use axis to trigger tooltip
                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                },
                formatter: function (params) {
                    console.log(params);
                    let string = ''
                    let amount = 0
                    let name = ''
                    let tmp = []
                    params.forEach((value,key)=>{
                        amount += value.value
                        name = value.name
                        tmp.push(
                            "<div style='display:inline-flex;align-items:center'>"+
                                "<div style='background-color:"+value.color+";width:5px;height:5px;border-radius:5px'></div>"+
                                "<span style='font-size: 12px;margin-left:10px;'>"+value.seriesName+"</span>"+
                                "<span style='font-size: 16px;font-weight:bold;margin-left:10px;'>"+Math.round(value.value*100)/100+"</span>"+
                            "</div><br/>"
                        )
                    })
                    string = name + "<br/>"+ "<div style='display:inline-flex;align-items:center;margin-bottom:10px;margin-top:10px;'>"+
                                "<span style='font-size: 12px;margin-left:10px;'>订单总额</span>"+
                                "<span style='font-size: 16px;font-weight:bold;margin-left:10px;'>"+Math.round(amount*100)/100+"</span>"+
                            "</div><br/>"+ 
                            tmp.join("")
                    return string;
                }
            },
            legend:{},
            grid:{
                top: '10%',
                left: '0%',
                right: '1%',
                bottom: '1%',
                containLabel: true
            },
            xAxis:{
                type: 'category',
                data: []
            },
            yAxis:{
                type: 'value'
            },
            series:[
                
            ],

        };
    },

    mounted(){
        this.dom = document.getElementById('yearCharts');
        this.chart = this.$echarts.init(this.dom);
        console.log(this.chart)
        this.getData()
    },

    methods: {
        init(){
            this.options = {
                tooltip:this.tooltip,
                legend:this.legend,
                grid:this.grid,
                xAxis:this.xAxis,
                yAxis:this.yAxis,
                series:this.series
            }
            this.chart.setOption(this.options)
        },
        getData(){
            getOrderYearReport(this.params).then(res=>{
                if(res.code === 200){
                    let series = [];
                    console.log(res.data.data.x_line)
                    this.xAxis.data = res.data.data.x_line
                    console.log(res.data.data.series)
                    for(let [key,value] of Object.entries(res.data.data.series)){
                        series.push({
                            name:key,
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: false
                            },
                            areaStyle: {},
                            emphasis: {
                                focus: 'series'
                            },
                            data: value
                        })
                    }
                    this.series = series
                    this.init()
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/static/default";
    .year-order-box{
        width:100%;
        height: 400px;
        .year-order-charts{
            width:100%;
            height: 400px;
        }
    }
</style>
<style>

</style>