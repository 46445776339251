<template>
  <section>
    <div class="container">
      <el-alert
        style="margin-bottom: 10px;"
        title="成功提示的文案"
        type="success"
        effect="dark">
      </el-alert>
      <div class="container-content">
        <div class="container-left">
          <!-- 数据概览 -->
          <div class="data-box">
            <div class="title">
              <div class="title-bar"></div>
              数据概览
              <span class="title-update-time">{{statistics.time}} 已更新</span>
              <i class="el-icon-refresh" @click="goStatistics"></i>
            </div>
            <div class="data-content">
              <div class="data-order">
                <div class="data-list">
                  <div class="data-list-title">今日收入(元)</div>
                  <div class="data-list-content">
                    <div class="data-list-content-number">{{statistics.order_today}}</div>
                    <div class="data-list-content-other">
                      <span>较昨日</span>
                      <i class="el-icon-caret-top" v-if="statistics.order_d_value>=0"></i>
                      <i class="el-icon-caret-bottom" v-else></i>
                      <span class="data-list-content-other-number">{{statistics.order_d_value}}</span>
                    </div>
                  </div>
                </div>
                <div class="data-list">
                  <div class="data-list-title">订单总额(元)</div>
                  <div class="data-list-content">
                    <div class="data-list-content-number">{{statistics.order_total}}</div>
                  </div>
                </div>
                <div class="data-list">
                  <div class="data-list-title">用户注册(人)</div>
                  <div class="data-list-content">
                    <div class="data-list-content-number">{{statistics.user_register}}</div>
                    <div class="data-list-content-other">
                      <span>今日注册</span>
                      <i class="el-icon-caret-top" v-if="statistics.today_register>=0"></i>
                      <i class="el-icon-caret-bottom" v-else></i>
                      <span class="data-list-content-other-number">{{statistics.today_register}}</span>
                    </div>
                  </div>
                </div>
                <div class="data-list">
                  <div class="data-list-title">活跃用户(人)</div>
                  <div class="data-list-content">
                    <div class="data-list-content-number">{{statistics.user_active}}</div>
                    <div class="data-list-content-other">
                      <span>较昨日</span>
                      <i class="el-icon-caret-top" v-if="statistics.active_d_value>=0"></i>
                      <i class="el-icon-caret-bottom" v-else></i>
                      <span class="data-list-content-other-number">{{statistics.active_d_value}}</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- 地图组件 -->
          <div class="map-box">
            <div class="title">
              <div class="title-bar"></div>
              分布图
              <span class="title-update-time">2024-08-09 15:53 已更新</span>
              <i class="el-icon-refresh"></i>
            </div>
            <div class="map-content">
              <map-component></map-component>
            </div>
          </div>

          <div class="order-time-box">
            <div class="title">
              <div class="title-bar"></div>
              实时报表
              <span class="title-update-time">2024-08-09 15:53 已更新</span>
              <i class="el-icon-refresh"></i>
            </div>
            <div class="content">
              <time-order-component></time-order-component>
            </div>
          </div>

          <div class="order-month-box">
            <div class="title">
              <div class="title-bar"></div>
              月度订单
              <span class="title-update-time">2024-08-09 15:53 已更新</span>
              <i class="el-icon-refresh"></i>
            </div>
            <div class="content">
              <month-order-component></month-order-component>
            </div>
          </div>

          <div class="order-year-box">
            <div class="title">
              <div class="title-bar"></div>
              年度报表
              <span class="title-update-time">2024-08-09 15:53 已更新</span>
              <i class="el-icon-refresh"></i>
            </div>
            <div class="content">
              <year-order-component></year-order-component>
            </div>
          </div>

          
        </div>
        <div class="container-right">
          <div class="event-box">
            <div class="event-title">
              <div class="title">
                <div class="title-bar"></div>
                最新事件
                <span class="title-update-time">2024-08-09 15:53 已更新</span>
                <i class="el-icon-refresh"></i>
              </div>
              <div class="event-more">更多<i class="el-icon-d-arrow-right" style="margin-left:5px;"></i></div>
            </div>
            <div class="content">
              <new-event-component></new-event-component>
            </div>
          </div>
          <!-- 日历组件 -->
          <div class="calendar-box">
            <calendar-component size="mini"></calendar-component>
          </div>
          
          <div class="hear-place-box">
            <div class="title">
              <div class="title-bar"></div>
              场所热度排名
              <span class="title-update-time">2024-08-09 15:53 已更新</span>
              <i class="el-icon-refresh"></i>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </section>
</template>

<script>
  let _this
  import calendarComponent from "@/views/components/calendar.vue"
  import mapComponent from "@/views/components/map.vue"
  import monthOrderComponent from "@/views/components/reports/monthOrder.vue"
  import yearOrderComponent from "@/views/components/reports/yearOrder.vue"
  import timeOrderComponent from "@/views/components/reports/timeOrder.vue"
  import newEventComponent from "@/views/components/newEvent.vue"
  import { getGroups} from "@/api/system.js";
  import {getOrderStatistics} from "@/api/public.js"
export default {
  name: "App",
  components:{
    calendarComponent,
    mapComponent,
    monthOrderComponent,
    yearOrderComponent,
    timeOrderComponent,
    newEventComponent
  },
  data() {
    return {
      statistics:{
        order_today:0,
        order_total:0,
        order_d_value:0,

        user_register:0,
        today_register:0,

        user_active:0,
        active_d_value:0,

        time:'',
      },
    }
  },

  mounted() {
    _this = this
    this.groupID = this.$store.state.user.userData.group_id
    this.goStatistics()
  },

  methods: {
    goStatistics(){
      getOrderStatistics().then(res=>{
      if(res.code === 200){
        this.statistics = res.data
        this.$forceUpdate()
      }
    })
    },
  }
};
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container{
    width: 100%;
    &-content{
      width: 100%;
      display:inline-flex;
      justify-content:space-between;
    }
    &-left{
      flex-shrink:1;
      width:70%;
      min-width: 900px;
      height:200px;
      display:flex;
      flex-wrap:wrap;
      box-sizing:border-box;
      .data-box{
        width: 100%;
        box-sizing:border-box;
        background-color:white;
        padding: 20px;
        border-radius: 10px;
        margin-bottom: 10px;
        .data-content{
          width: 100%;
          display:flex;
          flex-wrap:wrap;
          .data-order{
            width: 100%;
            display:inline-flex;
            justify-content:space-between;
          }
          .data-user{
            width: 480px;
            display:inline-flex;
            justify-content:space-between;
          }
        }
        .data-list{
          width: 100%;
          margin-bottom: 10px;
          &-title{
            width: 100%;
            font-size: 14px;
            font-family:sans-serif;
            color:rgba(0,0,0,0.45);
          }
          &-content{
            width: 100%;
            display:inline-flex;
            align-items:flex-end;
            margin-top: 5px;
            
            &-number{
              font-size:24px;
              font-weight:bold;
              font-family:sans-serif;
            }
            &-other{
              font-size: 12px;
              margin-left: 10px;
              color:rgba(0,0,0,0.45);
              i{
                margin-left:5px;
                margin-right: 5px;
              }
              .el-icon-caret-top{
                color:#c3272b;
              }
              .el-icon-caret-bottom{
                color:#67C23A;
              }
              &-number{
                font-weight:bold;
              }
            }
          }
        }
      }
      .map-box{
        width: 100%;
        margin-bottom: 10px;
        border-radius:10px;
        box-sizing:border-box;
        padding: 20px;
        background-color:rgb(40,46,57);
        .title{
          color:white;
          i{
            color:white;
          }
        }
        .title-update-time{
          color:white;
        }
      }
      .order-month-box{
        width:calc(50% - 5px);
        margin-bottom: 10px;
        background-color:white;
        border-radius:10px;
        box-sizing:border-box;
        padding: 20px;
      }
      .order-year-box{
        width:calc(50% - 5px);
        margin-bottom: 10px;
        margin-left:10px;
        background-color:white;
        border-radius:10px;
        box-sizing:border-box;
        padding: 20px;
      }
      .order-time-box{
        width:100%;
        margin-bottom: 10px;
        background-color:white;
        border-radius:10px;
        box-sizing:border-box;
        padding: 20px;
      }
    }
    &-right{
      width: 30%;
      min-width:400px;
      margin-left: 10px;
      box-sizing:border-box;
      .calendar-box{
        width: 100%;
        background-color:white;
        border-radius:10px;
        box-sizing:border-box;
        padding: 10px;
        margin-bottom: 10px;
      }
      .event-box{
        width: 100%;
        margin-bottom: 10px;
        background-color:white;
        border-radius:10px;
        box-sizing:border-box;
        padding: 20px;
        .event-title{
          width: 100%;
          display:inline-flex;
          align-items:center;
          justify-content:space-between;
          .event-more{
            flex-shrink:0;
            width: 100px;
            font-size: 12px;
            color:rgba(0,0,0,0.45);
            text-align:right;
            cursor:default;
            margin-bottom: 20px;
          }
        }
      }
      .hear-place-box{
        width: 100%;
        margin-bottom: 10px;
        background-color:white;
        border-radius:10px;
        box-sizing:border-box;
        padding: 20px;
      }
    }

    .title{
      width: 100%;
      display: inline-flex;
      align-items:center;
      font-size: 16px;
      margin-bottom: 20px;
      &-bar{
        width:3px;
        height: 16px;
        background-color:rgba(255, 193, 75, 1);
        border-radius:16px;
        margin-right: 10px;
      }
      &-update-time{
        font-size: 12px;
        margin-left: 10px;
        color:rgba(0,0,0,0.45);
      }
      i{
        color:rgba(0,0,0,0.45);
        margin-left: 10px;
      }
    }
  }
</style>