<template>
  <section style="height: 100%">
    <div class="container">
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>用户列表</h1></div>
          <div class="control-box" style="margin-top: 10px;">
            <el-input class="control-box-input" v-model="params.phone" placeholder="请输入手机号"></el-input>
            <el-button v-if="admin_permission.indexOf('look')>-1" icon="el-icon-search" @click="getTableData" style="margin-left: 10px;">搜索</el-button>
            <!-- <el-button v-if="admin_permission.indexOf('add')>-1" icon="el-icon-plus" type="primary" @click="handleOpenAdd">添加用户</el-button> -->
          </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-top:10px;">
          <el-tab-pane label="全部用户" name="all"></el-tab-pane>
          <el-tab-pane label="正常用户" name="default"></el-tab-pane>
          <el-tab-pane label="已拉黑用户" name="delete"></el-tab-pane>
        </el-tabs>
        <el-table
          :data="table"
          style="width: 100%;"
          tooltip-effect="dark"
          empty-text="暂无数据"
          class="table"
        >
          <el-table-column label="ID" prop="id" align="left" width="80"></el-table-column>
          <el-table-column label="手机号" prop="phone" align="center" width="150px"></el-table-column>
          <el-table-column prop="temple_id" label="场所" align="center" width="150px">
            <template slot-scope="scope">
              {{scope.row.temple_name}}
            </template>
          </el-table-column>
          <el-table-column label="姓名" prop="real_name" align="center" width="160px">
            <template slot-scope="scope">
              {{scope.row.real_name ? scope.row.real_name : '-'}}
            </template>
          </el-table-column>
          <el-table-column label="注册时间" prop="created_at" align="center"></el-table-column>
          <el-table-column label="是否拉黑" prop="deleted" align="center" width="160px">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.deleted==0">正常</el-tag>
              <el-tag type="info" v-else>已拉黑</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="100" align="center"  v-if="admin_permission.indexOf('update')>-1 || admin_permission.indexOf('deleted')>-1 || admin_permission.indexOf('examine')>-1">
            <template slot-scope="scope">
              <div style="width: 100%;">
                <el-link style="color: #409eff;" @click="handleDetail(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="查看详情" placement="top-start">
                    <i class="el-icon-view" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <!-- <el-link style="color: #409eff;" v-if="(scope.row.status !==2 || scope.row.status !==3) && admin_permission.indexOf('update')>-1" @click="editTable(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;margin-left:10px;" @click="handleDelete(scope.row.id)"  v-if="admin_permission.indexOf('deleted')>-1 && scope.row.role_id!=='admin'">
                  <el-tooltip class="item" effect="dark" content="拉黑" placement="top-start">
                  <i class="el-icon-delete-solid" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next,jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </div>

    <!-- 查看详情信息 -->
    <el-drawer title="用户详情" v-if="dialogTableVisible" :visible.sync="dialogTableVisible">
      <user-list :id="userID"></user-list>
    </el-drawer>

    <el-drawer
      title="更改用户"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <edit-comp v-if="editDrawer" @close="handleClose" @success="handleDrawerSuccess" :id="editID" ref="newForm"></edit-comp>
    </el-drawer>

    <!-- 添加规则 -->
    <!-- <el-drawer
      title="添加用户"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <add-comp
        @close="handleClose"
        @success="handleDrawerSuccess"
        @clear="clearContent"
        ref="newForm"
        v-if="addDrawer"
      ></add-comp>
    </el-drawer> -->
  </section>
</template>

<script>
  import { getList,deleteData} from "@/api/users.js";

  import addComp from "./components/add.vue";
  import editComp from "./components/edit.vue";
  import userList from "@/views/components/userList.vue";
  let page_name = 'users_list'
export default {
  name: "user_list",
  components:{addComp,editComp,userList},

  data() {
    return {
      activeName: 'all',
      admin_permission:[],
      dialogTableVisible:false,
      userID:"",
      status: [
        {id:"",label:"全部"},
        {id:0,label:"正常"},
        {id:1,label:"禁用"},
        {id:2,label:"审核中"},
        {id:3,label:"审核拒绝"},
      ],
      treeCurrentNode:"",

      params: {
        page: 1,
        page_count: 10,
        phone:'',
        start_time:'',
        end_time:'',
        temple_id:'',
        deleted:''
      },

      table:[],

      service: [],
      editID: '',
      total: 0,
      outerVisible: false,
      innerVisible: false,
      addDrawer: false,
      editDrawer: false,
      direction: "rtl",
      addTradeParams: {
        name: "",
        parent_id: 0
      },
      visible: false,
      scopeForm:{
        id:'',
        sort: ''
      }
    };
  },

  mounted() {
    this.getTableData();
    console.log(this.$store.state.permission.permissionArr)
    console.log(page_name)
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    console.log(this.admin_permission)
  },

  methods: {
    getTableData() {
      getList(this.params).then(res=>{
        if(res.code === 200){
          console.log(res.data)
          this.table = res.data.data
          this.params.page = res.data.current_page
          this.params.page_count = res.data.per_page
          this.total = res.data.total
          this.$forceUpdate()
        }
      })
    },
    handleClick(tab, event) {
      if(this.activeName === 'all'){
        this.params.deleted = ''
      }
      if(this.activeName === 'default') this.params.deleted = 0
      if(this.activeName === 'delete') this.params.deleted = 1
      this.getTableData()
    },
    handleDelete(id) {
      this.$confirm('此操作将拉黑此账号, 是否继续?')
        .then(_ => {
          deleteData(id).then(res=>{
              this.getTableData()
              this.$notify({
                title: '成功',
                message: '已拉黑',
                type: 'success'
              });
            })
        })
        .catch(_ => {});
    },
    handleDetail(e){
      console.log(e)
      this.userID=e
      this.dialogTableVisible = true
    },
    writeScope(id){
      console.log(id)
      this.scopeForm.id = id
      let tmp = [];
      this.service.forEach((value,key)=>{
        tmp[key] = value
        if (id === value.id){
          this.scopeForm.sort = parseInt(value.sort)
          tmp[key].write_scope = true
        }
      })
      this.service = tmp;
    },

    scopeChange(){
      editData(this.scopeForm.id,{sort:this.scopeForm.sort}).then(res=>{
        if (res.code === 200){
          this.searchSet()
        }
      })
    },

    //选择TREE
    showTreeWorker(id, pid) {
        this.params.status = id;
        this.getTableData();
    },

    // 打开添加
    handleOpenAdd() {
      this.addDrawer = true;
    },
    // 关闭添加/修改
    handleClose() {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.addDrawer = false;
          this.editDrawer = false;
        })
        .catch((_) => {});
    },

    editTable(id){
      this.editID = id
      this.editDrawer = true
    },
    // 添加/修改成功
    handleDrawerSuccess() {
      this.addDrawer = false;
      this.editDrawer = false;
      this.getTableData();
      this.$refs.newForm.$refs.form.resetFields();
    },
    // 清空子组件
    clearContent() {
      this.$refs.newForm.$refs.form.resetFields();
    },
    // 分页
    prevpage() {
      this.params.page -= 1;
      this.getTableData();
    },
    nextpage() {
      this.params.page += 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getTableData();
    },
  }
};
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;

    .table {
      margin-top: 0px !important;
    }

    .toolbar {
      background: #fff;
      width: 100%;
      padding: 16px 32px 26px 0;
    }
  }

  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
  .oneLineCls{
	  text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     -webkit-line-clamp: 1;
     line-clamp: 1;
     -webkit-box-orient: vertical;
  }
</style>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    padding-right: 8px;
  }
  .el-tree-node__expand-icon.is-leaf{
    color: #c0c4cc;
  }
</style>