<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
    <el-divider content-position="left">企业基本信息</el-divider>
      <el-form-item label="组织类型" prop="type" required>
        <el-radio-group v-model="form.type">
          <el-radio-button :label="0">企业旗下</el-radio-button>
          <el-radio-button :label="1">政府部门</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入名称,不可重复" clearable></el-input>
      </el-form-item>

      <el-form-item label="商户详细地址" prop="address" required>
        <el-select
          style="width: 100%"
          v-model="keywordResult"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          @change="keywordChange"
          :remote-method="remoteMethod"
          :loading="keywordLoading"
        >
          <el-option
            v-for="(value,key) in keywordAddress"
            :key="key"
            :label="value.title"
            :value="key"
          ></el-option>
        </el-select>
        <div v-if="keywordProvince!==''">{{keywordProvince}}</div>
      </el-form-item>

      <el-divider content-position="left">企业账号</el-divider>
      <el-form-item label="联系号码" prop="phone" required>
        <el-input v-model="form.phone" placeholder="请输入手机号，该手机号会做为企业账号,之后不可更改" clearable></el-input>
      </el-form-item>

      <el-divider content-position="left">企业其他信息</el-divider>

      <el-form-item label="注册资金" prop="registered_capital" v-if="form.type===0">
        <el-input v-model="form.registered_capital" type="number" :min="0" placeholder="请输入注册资金" clearable>
          <template slot="append">万元</template>
        </el-input>
      </el-form-item>

      <el-form-item label="工商注册时间" prop="registion_at" required v-if="form.type===0">
          <el-date-picker
            v-model="form.registion_at"
            type="date"
            placeholder="请选择工商注册时间"
            value-format="yyyy-MM-dd">
          </el-date-picker>
      </el-form-item>

      <el-form-item label="招商政策" prop="merchant_type">
        <el-radio-group v-model="form.merchant_type">
          <el-radio-button :label="0">无政策</el-radio-button>
          <el-radio-button :label="1">已有其他政策</el-radio-button>
          <el-radio-button :label="2">正在办理中</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="参股比例" prop="share_proportion" v-if="form.type===0">
        <el-input v-model="form.share_proportion" type="number" placeholder="请输入注册资金" clearable>
          <template slot="append">%</template>
        </el-input>
      </el-form-item>

      <el-form-item label="服务费" prop="service_fee" v-if="form.type===0">
        <el-input v-model="form.service_fee" type="number" placeholder="请输入服务费" clearable>
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  import {addGroup,getPermissions} from "@/api/system.js";
  let form = {
    name: "",
    province_id: 0,
    city_id:0,
    area_id:0,
    lat:0,
    lng:0,
    address:'',
    type:0,
    registered_capital:0,
    registion_at:'',
    share_proportion:0,
    merchant_type:0,
    phone:'',
    service_fee:''
  }
  import {tencentAddressKeyword,areaDetailCode} from "@/api/public.js";
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  export default {
    
    data() {
      const validatePhone = (rule, value, callback) => {
        const regExp = /^(0|86|17951)?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/
        if (!regExp.test(value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      }
      return {
        form: form,

        rules: {
          name: [
            {required: true, message: "请输入名称,不可重复", trigger: "blur"},
          ],
          address: [
            {required: true, message: "请输入公司地址", trigger: "blur"},
          ],
          phone: [
            {required: true, message: "请输入手机号，该手机号会做为企业账号", trigger: "blur"},
            {
              validator: validatePhone,
              trigger: 'change',
            }
          ],
          registion_at: [
            {required: true, message: "请选择工商注册时间", trigger: "change"},
          ],
        },

        keywordAddress: [],
        keywordLoading: false,
        keywordResult: "",
        keywordProvince:''
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      // 初始化数据
      init() {
        // getPermissions().then(res=>{
        //   if(res.code === 200){
        //     this.permission = res.data
        //   }
        // })
      },

      remoteMethod(query) {
        if (query !== "") {
          this.keywordLoading = true;
          tencentAddressKeyword({keyword: query}).then(res => {
            this.keywordLoading = false;
            if (res.code === 200) {
              this.keywordAddress = res.data;
            }
          });
        } else {
          this.options = [];
        }
      },

      keywordChange() {
        let data = this.keywordAddress[this.keywordResult];
        this.keywordProvince = data['address']
        areaDetailCode({area_id: data.adcode}).then(res => {
          if (res.code === 200) {
            console.log(res.data)
            this.form.province_id = res.data.province_id
            this.form.city_id = res.data.city_id
            this.form.area_id = res.data.area_id
            this.form.lat = data["location"]["lat"];
            this.form.lng = data["location"]["lng"];
            this.form.address = data['address']
          }
        })
      },
     
      close() {
        _this.$emit("close");
      },
      submit(form) {
        _this.form.service_fee = parseInt(_this.form.service_fee)
        this.$refs[form].validate(valid => {
          if (valid) {
            addGroup(_this.form).then(res => { 
              if (res.code === 200) {
                _this.$message({
                  message: "添加成功",
                  type: "success"
                });
                setTimeout(() => {
                  _this.$emit("success");
                }, 1000);
              }
            })
          } else {
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }

  .icon-dialog{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-dialog-list{
      box-sizing: border-box;
      padding: 20px 10px;
      width: 16.66%;
      font-size: 32px;
      text-align: center;
      border:1px solid #dbdbdb;
    }
    .icon-dialog-list:hover{
      color:#409eff;
      border-color:#409eff;
    }
  }
  .icon-name{
    margin-top:10px;
    font-size: 12px;
  }
  
</style>
<style>
  .el-dialog__body{
    max-height: 60vh;
    overflow-y: auto;
  }
</style>
