<template>
  <section style="height: 100%">
    <div class="container">
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>设备列表</h1></div>
          <div class="control-box" style="margin-top: 10px;" v-if="admin_permission.indexOf('look')>-1 || admin_permission.indexOf('add')>-1">
            <el-input class="control-box-input" v-model="params.serial_number" style="width:200px;margin-right:10px;margin-top: 10px;" placeholder="请输入搜索设备号" clearable></el-input>
            <el-select v-model="params.store_id" placeholder="请选择或输入投放点" style="margin-right:10px;margin-top: 10px;" clearable filterable>
              <el-option
                v-for="item in storeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.type" placeholder="请选择类型" clearable style="margin-right:10px;width:150px;margin-top: 10px;">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="params.group_id" placeholder="请选择公司" filterable clearable style="margin-right:10px;margin-top: 10px;" v-if="groupID===1">
              <el-option
                v-for="item in groupList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.is_init" placeholder="是否初始化" clearable style="margin-right:10px;width:150px;margin-top: 10px;">
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="params.online" placeholder="是否在线" clearable style="margin-right:10px;width:150px;margin-top: 10px;">
              <el-option
                v-for="item in onLineList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-button icon="el-icon-search" @click="getTableData" style="margin-right:10px;margin-top: 10px;">搜索</el-button>
            <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd" style="margin-right:10px;margin-top: 10px;" v-if="admin_permission.indexOf('add')>-1">添加设备</el-button>
          </div>
        </div>
      <!-- 轮播列表 -->
        <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-top:10px;">
          <el-tab-pane label="已投放" name="put"></el-tab-pane>
          <el-tab-pane label="仓库中" name="local"></el-tab-pane>
          <el-tab-pane label="已损坏" name="warn"></el-tab-pane>
          <el-tab-pane label="测试中" name="test"></el-tab-pane>
        </el-tabs>
        <el-table :data="tableData" style="width: 100%" class="table">
          <!-- 数据展示区 -->
          <el-table-column label="营业时间" prop="store_time" align="center" width="150px">
            <template slot-scope="scope">
              <span v-if="(scope.row.store_id === 0)">未绑定投放点</span>
              <span v-else>{{  scope.row.store.business_start_time}}至{{ scope.row.store.business_end_time }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="ID" prop="id" align="center" width="50px">
          </el-table-column> -->
          <el-table-column label="设备号" prop="serial_number" align="center" width="160px" fixed="left">
            <template slot-scope="scope">
              <div style="display: flex;justify-content: space-between;align-items: center;">
                <div v-if="scope.row.online===1" style="width:7px;height:7px;border-radius: 7px;background-color: #04944c;"></div>
                <div v-if="scope.row.online===0" style="width:7px;height:7px;border-radius: 7px;background-color: rgb(214, 12, 12);"></div>
                <span>{{ scope.row.serial_number }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="类型" prop="device_type" align="cnter" width="110px">
          </el-table-column>
          <el-table-column label="设备孔位" prop="battery_total" align="center" width="80px">
          </el-table-column>
          <el-table-column label="初配宝数" prop="battery_init" align="center" width="80px">
          </el-table-column>
          <el-table-column label="可用宝数" prop="battery_count" align="center" width="80px">
          </el-table-column>
          <el-table-column label="屏幕绑定" prop="permission" align="left" width="200px">
            <template slot-scope="scope">
              <span v-if="!scope.row.app">未激活终端APP</span>
              <el-popover trigger="hover" placement="top" v-else >
                <el-tag size="small" effect="plain">终端OAID:{{scope.row.app}}</el-tag>
                <div slot="reference" class="name-wrapper" >
                  <el-tag size="medium" effect="plain">终端OAID:{{ scope.row.app }}</el-tag>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="投放点" prop="comment" align="left" width="200px">
            <template slot-scope="scope">
              <span v-if="(scope.row.store_id === 0)">未绑定投放点</span>
              <el-popover trigger="hover" placement="top"  v-else>
                <el-descriptions class="margin-top" :title="scope.row.store.name" :column="1" :border="true">
                  <template slot="extra">
                    <el-button type="primary" size="small">查看详情</el-button>
                  </template>
                  <el-descriptions-item>
                    <template slot="label" :span="2">
                      <i class="el-icon-mobile-phone"></i>
                      联系号码
                    </template>
                    {{ scope.row.store.phone }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label" :span="2">
                      <i class="el-icon-office-building"></i>
                      联系地址
                    </template>
                    {{ scope.row.store.province_name }} {{ scope.row.store.city_name }} {{ scope.row.store.area_name }} {{ scope.row.store.address }}
                  </el-descriptions-item>
                </el-descriptions>
                <div slot="reference" class="name-wrapper" >
                  <el-tag size="medium" effect="plain">{{ scope.row.store.name }}</el-tag>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="连接次数" prop="connect" align="center" width="80px">
          </el-table-column>
          <el-table-column label="最近连接时间" prop="connect_time" align="center" width="160px">
          </el-table-column>
          <el-table-column label="开发商" prop="protocol_type" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.protocol_type===0">PDRL</span>
              <span v-if="scope.row.protocol_type===2">PDHX</span>
              <span v-if="scope.row.protocol_type===1">PDZD</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="是否在线" prop="online" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.online===1">在线</span>
              <span v-if="scope.row.online===0">不在线</span>
            </template>
          </el-table-column> -->
          <el-table-column label="所属公司" prop="group_id" align="left" width="200px">
            <template slot-scope="scope">
              <span v-if="(scope.row.group_id === 0)">未绑定公司</span>
              <span v-else>{{scope.row.group_name}}</span>
            </template>
          </el-table-column>
          <el-table-column label="所属代理商" prop="agent_id" align="left" width="150px">
            <template slot-scope="scope">
              <span v-if="(scope.row.agent_id === 0)">未绑定代理商</span>
              <span v-else>{{scope.row.agent.name}}</span>
            </template>
          </el-table-column>
          <el-table-column label="电话号码" prop="agent_id" align="left" width="120px">
            <template slot-scope="scope">
              <span v-if="(scope.row.agent_id === 0)">未绑定代理商</span>
              <span v-else>{{scope.row.agent.phone}}</span>
            </template>
          </el-table-column>
          <el-table-column label="所属省份" prop="province" align="left" width="160px">
            <template slot-scope="scope">
              {{scope.row.store.province_name}}
            </template>
          </el-table-column>
          <el-table-column label="所属城市" prop="city" align="left" width="160px">
            <template slot-scope="scope">
              {{scope.row.store.city_name}}
            </template>
          </el-table-column>
          <!-- <el-table-column label="所属公司" prop="company" align="left" width="160px">
          </el-table-column> -->
          <!-- <el-table-column label="设备号" prop="serial_number" align="left">
          </el-table-column> -->
          <el-table-column label="操作" fixed="right" align="center" width="150">
            <template slot-scope="scope">
              <div
                style="
                    width: 100%;
                    display: inline-flex;
                    justify-content: space-around;
                  "
              >
                <el-link style="color: #409eff;" @click="handleDetail(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="查看详情" placement="top-start">
                    <i class="el-icon-view" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;margin-left:10px;margin-right:10px;" @click="handleEdit(scope.row)" v-if="admin_permission.indexOf('update')>-1">
                  <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                
                <el-link style="color: #409eff;" @click="handleDelete(scope.row.id)" v-if="admin_permission.indexOf('deleted')>-1">
                  <el-tooltip class="item" effect="dark" content="刪除" placement="top-start">
                  <i class="el-icon-delete" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-dropdown @command="command($event,scope.row)">
                  <span class="el-dropdown-link" style="color: #409eff">
                    <el-tooltip class="item" effect="dark" content="更多操作" placement="top-start">
                      <i class="el-icon-arrow-down" style="font-size:16px;"></i>
                    </el-tooltip>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="edit">修改状态</el-dropdown-item>
                    <el-dropdown-item command="out" v-if="scope.row.online===1">强制弹出</el-dropdown-item>
                    <el-dropdown-item command="out" v-if="scope.row.online===0" disabled>强制弹出</el-dropdown-item>
                    <el-dropdown-item command="code">显示二维码</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                
              
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next,jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-main>

      
      <!-- 查看详情信息 -->
      <el-dialog title="设备详情" v-if="dialogTableVisible" :visible.sync="dialogTableVisible">
        <device-list :id="deviceID"></device-list>
      </el-dialog>
      <el-dialog title="设备状态" v-if="dialogStatusVisible" :visible.sync="dialogStatusVisible" width="30%">
        <el-select v-model="deviceStatus" placeholder="设备状态" clearable style="margin-right:10px;width:80%;margin-top: 10px;">
          <el-option
            v-for="item in deviceStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <div style="display: flex;justify-content: space-around;width:200px;margin:30px auto 10px auto;">
          <el-button>取消</el-button>
          <el-button type="primary" @click="confirm">确定</el-button>
        </div>
        
      </el-dialog>
      
      <el-dialog title="二维码" v-if="dialogCodeVisible" :visible.sync="dialogCodeVisible" width="300px">
        <div>
          <div style="margin: 0 auto;display: flex;justify-content: center;">
            <img style="width: 150px;height:150px;" :src="code">
          </div>
          <div style="margin: 20px auto 0 auto;display: flex;justify-content: center;">
            <el-button type="primary" @click="codeSubmit">下载二维码</el-button>
          </div>
        </div>
      </el-dialog>
      <!-- 强制弹出 -->
      <el-dialog :title="'弹出设备'+outSerial" v-if="dialogOutVisible" :visible.sync="dialogOutVisible">
        <device-out :id="outId"></device-out>
      </el-dialog>
      <!-- 添加活动信息 -->
      <el-drawer
        title="添加设备"
        :visible.sync="addDrawer"
        :direction="direction"
        :before-close="handleClose"
        
      >
        <add
          @close="handleClose"
          @success="handleDrawerSuccess"
          ref="newForm"
        ></add>
      </el-drawer>

      <!-- 修改活动信息 -->
      <el-drawer
        title="更改设备"
        :visible.sync="editDrawer"
        :direction="direction"
        :before-close="handleClose"
      >
        <edit
          ref="newForm"
          v-if="editDrawer"
          @close="handleClose"
          @success="handleDrawerSuccess"
          :id="editParam.id"
          :groupID="groupID"
        ></edit>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import {getList,deleteData,updateData,editStatus,deviceCode} from '@/api/device.js'
import {searchGroups} from "@/api/system.js";
import {getListSelect} from "@/api/store.js";
import {QueryTheInventory} from '@/api/websocket/index'
import ToolsBox from "@/components/tool/Index.vue";
import deviceList from "@/views/components/deviceList.vue";
import deviceOut from "@/views/components/deviceOut.vue";
import edit from "./components/edit.vue";
import add from "./components/add.vue";

let page_name = 'device_management'
export default {
  name: "App",
  components: { ToolsBox,edit,add,deviceList,deviceOut },
  data() {
    return {
      admin_permission:[],
      height:0,
      toolsConfig: [],
      dialogTableVisible:false,
      dialogStatusVisible:false,
      dialogOutVisible:false,
      dialogCodeVisible:false,
      codeID:'',
      code:'',
      deviceID:'',
      activeName:'',
      groupList:[],
      groupID:'',
      typeList:[
        {
          value: 0,
          label: '立式'
        }, {
          value: 1,
          label: '座机'
        }
      ],
      statusList:[
        {
          value: 0,
          label: '否'
        }, {
          value: 1,
          label: '是'
        },
      ],
      onLineList:[
        {
          value: 0,
          label: '否'
        }, {
          value: 1,
          label: '是'
        },
      ],
      deviceStatusList:[
        {
          value: 0,
          label: '测试中'
        }, {
          value: 1,
          label: '仓库中'
        },{
          value: 2,
          label: '已投放'
        },{
          value: 3,
          label: '已损坏'
        },
      ],
      statusId:'',
      storeList:[],
      outId:[],
      outSerial:'',
      //搜索条件
      params: {
        page:1,
        page_count: 10,
        type:'',//类型
        group_id:'',
        status:2,
        store_name:"",
        name:"",
        is_init:"",
        online:''
      },
      deviceStatus:'',
      // 查询数据
      tableData: [
        
      ],
      total: 0,

      // 添加组件传参
      addDrawer: false,
      addParam:{

      },

      // 更新组件传参
      editDrawer: false,
      editParam: {
        id:0
      },

      direction: "rtl",
    };
  },
  websocketonmessage(e){ //数据接收
      const redata = JSON.parse(e.data);
      if(redata.code === 200){
        console.log(redata.data)
        if(redata.event === 'new_order'){
          console.log(redata.data)
          this.$notify.info({
            title: '有新的订单',
            dangerouslyUseHTMLString: true,
            message: '<div style="width: 100%"><strong>'+redata.data.service_name+'</strong><br/><span>'+redata.data.province_name+' '+redata.data.city_name+' '+redata.data.area_name+' '+redata.data.address+'</span></div>'
          });
        }
      }
    },
  mounted() {
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    this.getTableData();
    this.groupID = this.$store.state.user.userData.group_id
    getListSelect().then(res=>{
      if(res.code === 200){
        console.log(res.data)
        this.storeList = res.data
      }
    })
    searchGroups().then(res=>{
      if(res.code === 200){
        console.log(res.data.data)
        this.groupList = res.data.data
      }
    })
    if(document.body.clientHeight&&document.documentElement.clientHeight)
    {
      var clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
    }
    else
    {
      var clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
    }
    console.log(clientHeight)
    this.height = clientHeight-82-60-40-20-20-40
    console.log(this.height)
    // this.socketApi.getSock(this.getConfigResult);
  },
  methods: {
    getTableData(){
      getList(this.params).then(res=>{
        if (res.code === 200){
          console.log(res.data.data)
          this.tableData = res.data.data
          this.params.page = res.data.current_page
          this.params.page_count = res.data.per_page
          this.total = res.data.total
        }
      })
    },
    handleClick(tab, event) {
      if(this.activeName === 'put'){
        this.params.status = 2
        this.params.page = 1
      }
      if(this.activeName === 'local'){
        this.params.status = 1
        this.params.page = 1
      }
      if(this.activeName === 'warn'){
        this.params.status = 3
        this.params.page = 1
      }
      if(this.activeName === 'test'){
        this.params.status = 0
        this.params.page = 1
      }
      this.getTableData()
    },
    handleDetail(e){
      console.log(e)
      this.deviceID=e
      this.dialogTableVisible = true
    },

    // 删除轮播
    handleDelete(id) {
      this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
        .then(_ => {
          deleteData(id).then(res=>{
            this.getTableData()
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
            });
          })
        })
        .catch(_ => {});
    },
    
    command(e,item){
        console.log(e)
        console.log(item)
      if(e === 'edit'){
        this.statusId = item.id
        this.dialogStatusVisible =true
        
      }
      if(e === 'out'){
        this.outId = item.id
        this.outSerial = item.serial_number
        console.log(this.outId)
        this.dialogOutVisible =true
        
      }
      if(e === 'code'){
        this.codeID = item.id
        console.log(this.codeID)
        this.dialogCodeVisible =true
        deviceCode(this.codeID).then(res=>{
          if(res.code===200){
            console.log(res.data)
            this.code = res.data
          }
        })
      }
    },
    confirm(){
      editStatus(this.statusId,{status:this.deviceStatus}).then(res=>{
        if(res.code===200){
          this.getTableData()
          this.$notify({
            title: '成功',
            message: '已修改',
            type: 'success'
          });
          
          this.dialogStatusVisible =false
        }
        })
    },
    getConfigResult(res){
      console.log(res.data)
    },

    handleOpenAdd() {
      this.addDrawer = true;
    },
    handleEdit(row) {
      this.editParam = {
        id : row.id,
      }
      this.editDrawer = true
    },
    handleClose() {
      this.$confirm("确认关闭？")
        .then(() => {
          this.addDrawer = false;
          this.editDrawer = false;
          this.$refs.newForm.$refs.form.resetFields(); //清空子组件的内容
        })
        .catch(() => {});
    },
    handleDrawerSuccess(){
      this.addDrawer = false;
      this.editDrawer = false;
      this.getTableData();
      this.$refs.newForm.$refs.form.resetFields();//清空子组件的内容
  },
    // 分页
    prevpage() {
      this.params.page -= 1;
      this.getTableData();
    },
    nextpage() {
      this.params.page += 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getTableData();
    },
    //二维码下载
    dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    },
      
    downloadFile(url,name='wWw_2JiYi_Com'){
        var a = document.createElement("a")
        a.setAttribute("href",url)
        a.setAttribute("download",name)
        a.setAttribute("target","_blank")
        let clickEvent = document.createEvent("MouseEvents");
        clickEvent.initEvent("click", true, true);  
        a.dispatchEvent(clickEvent);
    },
      
    downloadFileByBase64(base64,name){
        var myBlob = this.dataURLtoBlob(base64)
        var myUrl = URL.createObjectURL(myBlob)
        this.downloadFile(myUrl,name)
    },
    codeSubmit(){
      this.downloadFileByBase64(this.code);
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/static/default";
.banner {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;
  .table {
    margin-top: 24px !important;
  }
  .toolbar {
    background: #fff;
    width: calc(100% + 64px);
    padding: 16px 32px 26px 0;
    margin-left: -32px;
  }
  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
