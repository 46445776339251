
    import {Component, Prop, Vue, Emit, Watch} from 'vue-property-decorator';
    @Component
    export default class SearchFkStatus extends Vue {
      private options: any = [
        {
          id:0,
          name:'正常',
        },{
          id:1,
          name:'异常'
        }
      ]
      private value: string=''
      @Emit('getFkStatus')
      sendFkStatus(value: number) {}
    }
