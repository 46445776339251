<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-form-item label="字典名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入字典名称"></el-input>
      </el-form-item>
      <el-form-item label="场景说明" prop="command" required>
        <el-input v-model="form.command" placeholder="请输入场景说明"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  import {addHallData} from "@/api/dictionaries.js";
  export default {
    components: {},
    data() {
      return {
        form: {
          name: "",
          command: "",
        },

        templeList:[],
        rules: {
          name: [
            {required: true, message: "请输入字典名称", trigger: "blur"},
          ],
          command: [
            {required: true, message: "请输入场景说明", trigger: "blur"},
          ],
        },
        
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      // 初始化数据
      init() {
      },
      
      close() {
        _this.$emit("close");
      },
      submit(form) {
        console.log(this.form)
        this.$refs[form].validate(valid => {
          if (valid) {
            addHallData(_this.form).then(res => {
              if (res.code === 200) {
                setTimeout(() => {
                  _this.$emit("success");
                  _this.$emit("close");
                }, 1000);
              }
            })
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>

  .el-radio-group {
    text-align: left !important;
  }
</style>
