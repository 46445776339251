<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
    <el-form-item label="订阅名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入订阅名称,不可重复"></el-input>
      </el-form-item>

      <el-form-item label="订阅类型" prop="type" required>
        <el-select v-model="form.type" filterable placeholder="请选择订阅类型" clearable>
          <el-option label="请选择订阅类型" :value="''">请选择订阅类型</el-option>
          <el-option
            v-for="(value,key) in typeList"
            :key="value.value"
            :label="value.label"
            :value="value.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订阅单位" prop="unit" >
        <el-radio-group v-model="form.unit" size="small">
          <el-radio :label="0">天</el-radio>
          <el-radio :label="1">月</el-radio>
          <el-radio :label="2">年</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="订阅时间" prop="number" >
        <el-input-number v-model="form.number" :min="0" :max="100" label="请输入仓口数"></el-input-number>
      </el-form-item>

      <el-form-item label="备注" prop="command" >
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入备注"
          v-model="form.command">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  let form = {
    name: "",
    type: '',
    unit: 0,
    number:0,
    command:''
  }
  import {addSubscribeData} from "@/api/dictionaries.js";
export default {
    data() {
      return {
        form: form,
        rules: {
          name: [
            {required: true, message: "订阅名称不能为空,不可重复", trigger: "blur"},
          ],
          type: [
            {required: true, message: "订阅类型不能为空", trigger: "blur"},
          ]
        },
        typeList:[
          {value:0, label:'产品订阅'},
          {value:1, label:'消息订阅'},
        ],
      };
    },
    computed: {},
    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      // 初始化数据
      init() {
        
      },
     
      close() {
        _this.$emit("close");
      },
      submit(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            addSubscribeData(_this.form).then(res => { 
              if (res.code === 200) {
                _this.$message({
                  message: "添加成功",
                  type: "success"
                });
                  _this.$emit("success");
              }
                this.form = form
            })
          } else {
            return false;
          }
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }

  .icon-dialog{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-dialog-list{
      box-sizing: border-box;
      padding: 20px 10px;
      width: 16.66%;
      font-size: 32px;
      text-align: center;
      border:1px solid #dbdbdb;
    }
    .icon-dialog-list:hover{
      color:#409eff;
      border-color:#409eff;
    }
  }
  .icon-name{
    margin-top:10px;
    font-size: 12px;
  }
  
</style>
<style>
  .el-dialog__body{
    max-height: 60vh;
    overflow-y: auto;
  }
</style>