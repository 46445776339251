<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-divider content-position="left">客户信息</el-divider>
      <el-form-item label="客户名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入客户名称"></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="phone">
        <el-input placeholder="请输入客户联系方式" v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input placeholder="请输入邮箱" v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item label="公司" prop="company">
        <el-input placeholder="请输入公司" v-model="form.company"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-select v-model="form.gender" placeholder="性别" clearable style="margin-left:10px;width:150px;margin-top:10px;">
          <el-option
            v-for="item in genderOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类别" prop="type">
        <el-select v-model="form.type" placeholder="类别" clearable style="margin-left:10px;width:150px;margin-top:10px;">
          <el-option
            v-for="item in typeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="type">
        <el-select v-model="form.status" placeholder="状态" clearable style="margin-left:10px;width:150px;margin-top:10px;">
          <el-option
            v-for="item in statusOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="等级" prop="type">
        <el-input v-model="form.level" placeholder="请输入等级（1-5）"></el-input>
      </el-form-item>
      <el-form-item label="来源" prop="type">
        <el-select v-model="form.origin" placeholder="客户来源" filterable clearable style="margin-left:10px;margin-top:10px;">
          <el-option
            v-for="item in originOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="合作倾向" prop="type">
        <el-select v-model="form.maturity" placeholder="合作倾向" style="margin-left:10px;width:150px;margin-top:10px;">
          <el-option
            v-for="item in maturityOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="行业" prop="type">
        <el-select v-model="form.sector" placeholder="行业" style="margin-left:10px;width:150px;margin-top:10px;" clearable @change="provinceClick()">
          <el-option
            v-for="item in sectorOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="人物标签" prop="tag">
        <el-select v-model="form.tag" placeholder="人物标签" style="margin-left:10px;width:150px;margin-top:10px;" clearable @change="provinceClick()">
          <el-option
            v-for="item in tagOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="财富" prop="wealth">
        <el-select v-model="form.wealth" placeholder="财富" style="margin-left:10px;width:150px;margin-top:10px;" clearable @change="provinceClick()">
          <el-option
            v-for="item in wealthOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="性格" prop="character">
        <el-select v-model="form.character" placeholder="性格" style="margin-left:10px;width:150px;margin-top:10px;" clearable @change="provinceClick()">
          <el-option
            v-for="item in characterOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="省市区" prop="type">
        <el-select v-model="form.province_id" placeholder="请选择省" style="margin-left:10px;width:150px;margin-top:10px;" clearable @change="provinceClick()">
          <el-option
            v-for="item in provinceList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="form.city_id" placeholder="请选择市" clearable style="margin-left:10px;width:150px;margin-top:10px;" @change="cityClick()">
          <el-option
            v-for="item in cityList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="form.area_id" placeholder="请选择地区" clearable style="margin-left:10px;width:150px;margin-top:10px;" @change="areaClick()">
          <el-option
            v-for="item in areaList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="info">
        <el-input v-model="form.info" placeholder="请输入备注"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="addData('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>

  let _this;
  let id = 0;
  import {getTypeSelect} from "@/api/store.js";
  import { addData,getTypeList,getStatusList,getOriginList,getMaturityList,getSectorList,getWealthList,getTagList,getCharacterList} from "@/api/customer.js";
  import { getProvince,getCity,getArea} from "@/api/public.js";
  import { getGroups} from "@/api/system.js";
  import {getAgentListSelect} from "@/api/agent.js"
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  import DistrictsCascader from "@/components/tool/lib/districts-cascader/Index.vue";

  import Picture from '@/components/tool/lib/upload/index.vue';

  export default {
    props: {
      row: Object,
      editId: Number
    },
    components: {DistrictsCascader, UploadQiniu, Picture},
    data() {
      const validatePhone = (rule, value, callback) => {
        const regExp = /^(0|86|17951)?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/
        if (!regExp.test(value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      }
      return {
        typeOptions: [],
        statusOptions: [],//状态
        typeOptions:[],//类别
        originOptions:[],//客户来源
        maturityOptions:[],//合作倾向
        sectorOptions:[],//行业
        tagOptions:[],//人物标签
        wealthOptions:[],//财富
        characterOptions:[],//性格
        genderOptions:[
          {
            id:0,
            name:'男'
          },
          {
            id:1,
            name:'女'
          },
        ],
        toolsConfig: ["districts"],
        provinceList:[],
        cityList:[],
        areaList:[],
        province_id:"",
        city_id:"",
        form: {
          name: "",
          phone: "",
          gender:0,
          company:'',
          email:'',
          province_id: "",
          city_id: "",
          area_id: "",
          sector: "",
          maturity:'',
          origin:'',
          type: '',
          status:'',
          level:0,
          tag:'',
          wealth:'',
          character:'',
          info:''
        },
        
        value: [],
        time: '',
        show: true,
        placeholder: "",
        id: "",
        rules: {
          "name": [
            {required: true, message: "请输入客户名称", trigger: "blur"},
          ],
          // "phone": [
          //   {required: true, message: "请输入手机号", trigger: "blur"},
          //   {
          //     validator: validatePhone,
          //     trigger: 'change',
          //   }
          // ],
        },
        innerVisible:false
      };
    },

    computed: {
    },

    mounted() {
      _this = this;
      _this.groupID = _this.$store.state.user.userData.group_id
      _this.init();

    },

    methods: {
      // 初始化数据
      init() {
        getTypeList().then(res=>{
          if(res.code === 200){
            this.typeOptions = res.data
            console.log(this.typeOptions)
          }
        })
        getProvince().then(res=>{
          if(res.code === 200){
            this.provinceList = res.data
          }
        })
        //状态数组
        getStatusList().then(res=>{
          if(res.code === 200){
            this.statusOptions = res.data
            console.log(this.statusOptions)
          }
        })
        //客户来源数组
        getOriginList().then(res=>{
          if(res.code === 200){
            this.originOptions = res.data
            console.log(this.originOptions)
          }
        })
        //合作倾向数组
        getMaturityList().then(res=>{
          if(res.code === 200){
            this.maturityOptions = res.data
            console.log(this.maturityOptions)
          }
        })
        //行业数组
        getSectorList().then(res=>{
          if(res.code === 200){
            this.sectorOptions = res.data
            console.log(this.sectorOptions)
          }
        })
        //人物标签数组
        getTagList().then(res=>{
          if(res.code === 200){
            this.tagOptions = res.data
            console.log(this.tagOptions)
          }
        })
        //财富数组
        getWealthList().then(res=>{
          if(res.code === 200){
            this.wealthOptions = res.data
            console.log(this.wealthOptions)
          }
        })
        //性格数组
        getCharacterList().then(res=>{
          if(res.code === 200){
            this.characterOptions = res.data
            console.log(this.characterOptions)
          }
        })
      },
      
      provinceClick(){
        let that = this
        this.provinceList.forEach(item => {
          if(item.id == that.form.province_id ){
            that.province_id = item.province_id
            console.log(that.province_id)
          }
        });
        getCity(that.province_id).then(res=>{
          if(res.code === 200){
            console.log(res.data)
            this.cityList = res.data
          }
        })
      },
      cityClick(){
        let that = this
        this.cityList.forEach(item => {
          if(item.id == that.form.city_id ){
            that.city_id = item.city_id
            console.log(that.city_id)
          }
        });
        getArea(that.city_id).then(res=>{
          if(res.code === 200){
            console.log(res.data)
            this.areaList = res.data
          }
        })
      },


      close() {
        _this.$emit("close");
      },

      addData(form) {
        this.form.level = parseInt(this.form.level)
        this.$refs[form].validate(valid => {
          if (valid) {
            addData( _this.form).then((res) => {
              if (res.code === 200) {
                _this.$message({
                  message: "添加成功",
                  type: "success"
                });
                setTimeout(() => {
                  _this.$emit("success");
                }, 1000);
              }
            });
            this.form = form
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      },
      addServiceData(){
        this.form.service_data.push(JSON.parse(JSON.stringify(this.service_data)));
        this.serviceCascaderValue = []
        this.innerVisible = false
      },
      // 选择省市区
      handleDistrictsChoose(e) {
        _this.form.province_id = e.province_code;
        _this.form.city_id = e.city_code;
        _this.form.area_id = e.area_code;
      },
      handleAreaDistrictsChoose(e){
        this.service_data.service_province = e.province_code
        this.service_data.service_province_str = e.province
        this.service_data.service_city = e.city_code
        this.service_data.service_city_str = e.city
        this.service_data.service_area = e.area_code
        this.service_data.service_area_str = e.area
      },
      // 上传营业执照删除、缓存、预览
      handleLicenseSuccess(e) {
        console.log(e)
        this.form.license_image = e[0];
      },
      handleLicenseRemove(e) {
        this.form.license_image = "";
      },
      // 上传图片删除、缓存、预览
      handlePicSuccess(e) {
        console.log(e)
        this.form.store_images = e;
      },
      handlePicRemove(e) {
        this.form.store_images = [];
      },

      removeServiceData(key){
        console.log(key)
        let object = JSON.parse(JSON.stringify(this.form.service_data))
        console.log(object)
        if(object.length ===1){
          object = []
        }else{
          object.splice(key,1)
        }
        this.form.service_data = object
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }

  .el-select-dropdown__item{
    height:auto !important;
  }
</style>
