<template>
    <div>
        <el-descriptions direction="vertical" :column="4" border>
            <el-descriptions-item label="姓名">{{userData.name}}</el-descriptions-item>
            <el-descriptions-item label="法号">{{userData.buddhist_name}}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{userData.phone}}</el-descriptions-item>
            <el-descriptions-item label="性别">
                <div v-if="userData.gender == 0">男</div>
                <div v-if="userData.gender == 1">女</div>
            </el-descriptions-item>
            <el-descriptions-item label="寺院">{{userData.temple_name}}</el-descriptions-item>
            <el-descriptions-item label="地址">{{userData.address}}</el-descriptions-item>
            <el-descriptions-item label="注册时间">{{userData.created_at}}</el-descriptions-item>
            <el-descriptions-item label="头像">
                <el-image
                    style="width: 100px;height:100px;"
                    fit="contain"
                    :src="userData.avatar"
                    alt
                ></el-image>
            </el-descriptions-item>
        </el-descriptions>
    </div>

  </template>
  
  <script>
    let _this;
    import {getPlaceUserById} from "@/api/users.js";
    export default {
        props:['id'],
        data(){
            return{
                userData:[],
            }
        },
        mounted(){
            this.init()
            
        },
        methods:{
            // 初始化数据
            init() {
                getPlaceUserById(this.id).then(res=>{
                    if (res.code === 200){
                        this.userData = res.data
                    }
                })
            },
        }
    };
  </script>
  <style lang="scss" scoped>
  .title{
    
  }
  </style>
  