<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      label-width="100px"
      style="width:100%;"
    >
      <el-form-item label="名称：" prop="name" >
        <el-input v-model="form.name" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="状态：" prop="status" >
        <el-radio v-model="form.status" :label="0">下架</el-radio>
        <el-radio v-model="form.status" :label="1">上架</el-radio>
      </el-form-item>
      <el-form-item label="价格：" prop="prices" >
        <el-checkbox-group v-model="form.prices">
          <el-checkbox-button v-for="(value,index) in priceList" border :label="value" :key="index">{{value}}</el-checkbox-button>
        </el-checkbox-group>
        <el-button icon="el-icon-plus" type="primary" plain style="margin-top: 10px;" @click="handleOpenAdd">添加</el-button>
      </el-form-item>
      <el-form-item label="介绍：" prop="intro" >
        <el-input v-model="form.intro" placeholder="请输入介绍"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  import {editOther,getOtherById} from "@/api/order.js";
  export default {
    props: {
      id: Number
    },
    data() {
      return {
        form: {
          intro: "",
          name: "",
          status: "",
          prices: [],
        },
        priceList:[],
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      // 初始化数据
      init() {
        getOtherById(this.id).then(res=>{
          if(res.code === 200){
            this.form = res.data
            this.priceList = res.data.prices
            console.log(this.form)
          }
        })
      },
      handleOpenAdd(){
        // this.priceShow = true
        this.$prompt('请添加新的价格', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          console.log(parseInt(value))
          this.form.prices.push(value)
          console.log(this.form.prices)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });       
        });
      },
      close() {
        _this.$emit("close");
      },
      submit(form) {
        this.form.prices = JSON.stringify(this.form.prices)
        this.$refs[form].validate(valid => {
          if (valid) {
            editOther(this.id,_this.form).then(res => {
              if (res.code === 200) {
                _this.$message({
                  message: "修改成功",
                  type: "success"
                });
                setTimeout(() => {
                  _this.$emit("success");
                }, 1000);
              }
            })
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }
</style>
