<template>
  <section>
    <div class="container">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>订单列表</h1></div>
          <div class="control-box">
            <el-input class="control-box-input" v-model="params.keyword" style="width:250px;margin-left:10px;margin-top: 10px;" placeholder="请输入用户姓名/手机号/订单号" clearable></el-input>
            <el-select v-model="params.temple_id" placeholder="请选择需搜索寺庙" filterable clearable style="margin-left:10px;margin-top:10px;">
              <!-- <el-option label="请选择需搜索投放点">请选择需搜索投放点</el-option> -->
              <el-option
                v-for="item in templeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.type" placeholder="请选择订单类型" filterable clearable style="margin-left:10px;margin-top:10px;">
              <el-option
                v-for="item in orderType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="params.bless_id" v-if="params.type === 1" placeholder="请选择需搜索供奉类型" multiple filterable clearable style="margin-left:10px;margin-top:10px;">
              <!-- <el-option label="请选择需搜索供奉类型">请选择需搜索供奉类型</el-option> -->
              <el-option
                v-for="item in blessList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-date-picker
              v-model="params.start_time"
              type="date" style="margin-left:10px;margin-top:10px;"
              format="yyyy-MM-dd 日"
              value-format="yyyy-MM-dd"
              placeholder="请选择开始日期">
            </el-date-picker>
            <el-date-picker
              v-model="params.end_time"
              type="date" style="margin-left:10px;margin-top:10px;"
              format="yyyy-MM-dd 日"
              value-format="yyyy-MM-dd"
              placeholder="请选择结束日期">
            </el-date-picker>
            <el-button icon="el-icon-search" @click="searchData" style="margin-left:10px;margin-top: 10px;">搜索</el-button>
          </div>
        </div>
      <!--订单列表-->
      <el-table :data="table" class="table" width="1000">
        <!-- 数据展示区 -->
        <el-table-column
          label="订单类型"
          prop="type_name"
          align="left"
          width="140"
          fixed="left"
        >
          <template slot-scope="scope">
            <el-tag color="#507AFC" style="color:white;" v-if="scope.row.type === 0">{{scope.row.type_name}}</el-tag>
            <el-tag color="#FF720E" style="color:white;" v-if="scope.row.type === 1">{{scope.row.type_name}}</el-tag>
            <el-tag color="#F92C1E" style="color:white;" v-if="scope.row.type === 2">{{scope.row.type_name}}</el-tag>
            <el-tag color="#F92C1E" style="color:white;" v-if="scope.row.type === 3">{{scope.row.type_name}}</el-tag>
            <el-tag color="#62D42C" style="color:white;" v-if="scope.row.type === 4">{{scope.row.type_name}}</el-tag>
            <el-tag color="#507AFC" style="color:white;" v-if="scope.row.type === 5">{{scope.row.type_name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="场所" prop="temple_name"  align="left">
          <template slot-scope="scope">
            <span>{{scope.row.temple.name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="场景" prop="hall_name"  align="left">
          <template slot-scope="scope">
            <span v-if="scope.row.hall.name">{{scope.row.hall.name}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          label="订单明细"
          prop="amount"
          align="left"
          width="200"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.type === 0 || scope.row.type===5">{{scope.row.niche.name}} - {{scope.row.niche.time}}</span>
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover" v-if="scope.row.type === 1">
              <div class="bless-box">

              </div>
              <span slot="reference">{{scope.row.blesses_text}}</span>
            </el-popover>
            <span v-if="scope.row.type === 2">满堂红</span>
            <span v-if="scope.row.type === 3">{{scope.row.product.name}}-{{scope.row.product.time}}</span>
            <span v-if="scope.row.type === 4">{{scope.row.type_name}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="付款金额"
          prop="amount"
          align="left"
          width="140"
        ></el-table-column>
        <el-table-column
          label="创建时间"
          prop="updated_at"
          align="left"
        ></el-table-column>
        <el-table-column
          label="订单号"
          prop="serial"
          align="left"
        ></el-table-column>
        
        <el-table-column label="用户" prop="user_id" align="left" >
          <template slot-scope="scope">
            <div class="user-info">
              <el-image class="user-info-image" fit="cover" :src="scope.row.avatar"></el-image>
              <span>{{scope.row.real_name}}</span>
            </div>
          </template>
        </el-table-column>
       
        <el-table-column label="操作" align="center" width="100" fixed="right">
          <template slot-scope="scope">
            <div

            >
              <el-dropdown @command="command($event,scope.row)">
                  <span class="el-dropdown-link" style="color: #409eff">
                    更多操作
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="cancelOrder" v-if="admin_permission.indexOf('update')>-1" divided>取消订单</el-dropdown-item>
                  <el-dropdown-item command="remove" v-if="admin_permission.indexOf('deleted')>-1" divided>删除订单</el-dropdown-item>
                  <el-dropdown-item command="pay" divided>订单扣款</el-dropdown-item>
                  <el-dropdown-item command="getAli" v-if="scope.row.channel === 1">支付宝订单查询</el-dropdown-item>
                  <!-- <el-dropdown-item command="pay" v-if="scope.row.status===2&&scope.row.status===3" divided>订单扣款</el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>


      <el-dialog title="更改订单状态" width="500px" :visible.sync="statusVisible">
        <el-form :model="formStatus" label-position="top">
          <el-form-item label="订单状态" required>
            <el-select
              v-model="formStatus.status"
              clearable
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in orderType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="statusVisible = false" class="cancel-btn">取 消</el-button>
          <el-button type="primary" @click="changeStatus">更改</el-button>
        </div>
      </el-dialog>


      <el-dialog title="订单扣款" width="500px" :visible.sync="payShow">
        
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-top:20px;">
          <div>请选择订单结束时间：</div>
          <el-date-picker
            style="margin-left:10px"
            v-model="end_time"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择订单结束的日期时间">
          </el-date-picker>
        </div>
        
        <div slot="footer" class="dialog-footer">
          <el-button @click="payShow = false" class="cancel-btn">取 消</el-button>
          <el-button type="primary" @click="cancelPay()">确认</el-button>
        </div>
      </el-dialog>

      <!--分页-->
      <el-col class="toolbar">
        <div class="word">订单总额:  <span>{{total_amount}}</span></div>
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page.sync="params.page"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float: right;flex-shrink: 1;"
        ></el-pagination>
      </el-col>
    </div>
  </section>
</template>

<script>
// import { getCascader } from "@/api/provider.js";
import { getOrder, editData,deleteData,cancelData,getPay,syncAli,getAli,unfreezeAli} from "@/api/order.js";
import { getListSelect} from "@/api/store.js";
import { getGroups} from "@/api/system.js";
import { getTemples,getBless} from "@/api/public.js";

let page_name = 'order_list'
export default {
  name: "App",

  data() {
    return {
      admin_permission:[],
      reasonShow:false,
      payShow:false,
      orderDetailShow:false,
      orderDetail:'',
      reason:'',
      end_time:'',//订单结束时间
      activeName:'',
      outerVisible:false,
      params: {
        page:1,
        temple_id:'',
        hall_id:0,
        bless_id:[],
        project_id:[],
        other_bless_id:"",
        product_id:'',
        type:'',
        keyword:'',
        start_time:'',
        end_time:'',
      },
      formPrice:{id:0,price:''},
      priceVisible:false,
      statusVisible:false,
      formStatus:{id:0,status:''},
      cancelId:'',
      payId:'',//扣款订单id
      cascaderOpthion:[],
      cascaderValue:'',
      table: [],
      total: 0,
      total_amount:0,
      groupList:[],
      templeList:[],
      blessList:[],
      agentList:[],
      groupID:'',
      showBack: false,
      orderType:[
        {
          value: 0,
          label: "恭请",
        },
        {
          value: 1,
          label: "供奉",
        },
        {
          value: 2,
          label: "满堂红",
        },
        {
          value: 3,
          label: "祈福",
        },
        {
          value: 4,
          label: "行善",
        },
        {
          value: 5,
          label: "为亲人恭请",
        },
      ],
    };
  },

  mounted() {
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    this.getTable();
    this.groupID = this.$store.state.user.userData.group_id
    
    // getGroups().then(res=>{
    //   if(res.code === 200){
    //     this.groupList = res.data
    //   }
    // })
    getTemples().then(res=>{
      if(res.code === 200){
        console.log(res.data)
        this.templeList = res.data
      }
    })
    getBless().then(res=>{
      if(res.code === 200){
        console.log(res)
        this.blessList = res.data
      }
    })
    // getAgentListSelect().then(res=>{
    //   if(res.code === 200){
    //     this.agentList = res.data
    //   }
    // })
  },

  methods: {
    // 获取订单列表
    getTable() {
      getOrder(this.params).then((res) => {
        if (res.code === 200) {
          console.log(res.data.data)
          this.table = res.data.data
          this.total = res.data.total;
          this.total_amount = res.data.total_amount
        }
      });
    },
    handleClick(tab, event) {
      if(this.activeName === 'default'){
        this.params.order_type = 0
        this.params.page = 1
      }
      if(this.activeName === 'cash'){
        this.params.order_type = 1
        this.params.page = 1
      }
      this.getTable()
    },
    searchData(){
      this.params.page=1
      this.params.bless_id=JSON.stringify(this.params.bless_id)
      console.log(this.params)
      getOrder(this.params).then((res) => {
        if (res.code === 200) {
          console.log(res.data.data)
          this.table = res.data.data
          this.total = res.data.total;
          this.total_amount = res.data.total_amount
          this.params.bless_id = JSON.parse(this.params.bless_id)
        }
      });
    },
    command(e,row){
      if(e === 'cancelOrder'){
        this.cancelId = row.id
        console.log(row)
        this.reasonShow = true
      }
      if(e === 'status'){
        this.formStatus.id = row.id
        this.formStatus.status = row.status
        this.statusVisible = true
      }
      if(e === 'pay'){
        this.payId = row.id
        console.log(row)
        this.payShow = true
      }
      if(e === 'syncAli'){
        syncAli(row.id,{}).then((res) =>{
          if(res.code === 200){
            this.$message({
              message: res.msg,
              type:'success'
            })
            this.getTable()
          }
        })
      }
      if(e === 'getAli'){
        this.orderDetailShow = true
        getAli(row.id,{}).then(res=>{
          if(res.code === 200){
            console.log(res);
            this.orderDetail = res.data
            this.orderDetail['order'] = row
          }
        })
      }
      if(e === 'remove'){
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
          .then(_ => {
            deleteData(row.id).then(res=>{
              this.getTable()
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              });
            })
          })
          .catch(_ => {});
        }
    },
    changeStatus(){
      editData(this.formStatus.id,{'status':this.formStatus.status}).then(res=>{
        if (res.code === 200){
          this.getTable()
          this.$message({
            type: 'success',
            message: '更改成功!'
          });
          this.statusVisible = false
        }
      })
    },
    //取消订单确认
    cancelReason(){
      console.log(this.reason)
      cancelData(this.cancelId,{'reason':this.reason}).then(res=>{
        if (res.code === 200){
          this.getTable()
          this.$message({
            type: 'success',
            message: '已取消!'
          });
          this.reasonShow = false
        }
      })
    },
    //扣款订单确认
    cancelPay(){
      console.log(this.end_time)
      if(!this.end_time){
        this.$message({
          type: 'error',
          message: '请选择时间'
        });
      }else{
        getPay({'id':this.payId,'end_time':this.end_time}).then(res=>{
          if (res.code === 200){
            this.getTable()
            this.$message({
              type: 'success',
              message: '已发起扣款!'
            });
            this.payShow = false
          }
        })
      }
      
    },
    handleBack() {
      this.showBack = false;
      this.params = {};
      this.getTable();
    },

    // 删除订单
    handleDelete(index, row) {
      this.$confirm("确认删除？")
        .then((_) => {
          deleteOrder(row.id, { deleted: 1 })
            .then((data) => {
              if (data.code === 200) {
                this.getOrderInfo();
              }
            })
            .catch((error) => {});
        })
        .catch((_) => {});
    },

    // 分页
    prevpage() {
      this.params.page -= 1;
      this.getTable()
    },
    nextpage() {
      this.params.page += 1;
      this.getTable()
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getTable()
    },
  }
};
</script>

<style scoped lang="scss">
@import "@/static/default";
.temple {
  width: 100%;
  background-color: white;
  padding: 10px 10px;
  // padding-left: 50px;
}
.order {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.container {
  background-color: #fff !important;
  padding: 0 32px;
  .table {
    margin-top: 24px !important;
  }
  .toolbar {
    background: #fff;
    width: calc(100% + 64px);
    padding: 16px 32px 26px 0;
    margin-left: -32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .word{
      margin-right:50px;
      font-size:14px;
      span{
        font-size:18px;
      }
    }
  }
  .search-box{
    margin-top:20px;
    padding-left:20px;
  }
  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    padding-left:20px;
    padding-top:10px;
    &-input{
      width: 150px;
    }
  }
}
.user-info{
  width: 100%;
  display:inline-flex;
  align-items:center;
  span{
    margin-left: 10px;
  }
  &-image{
    width:30px;
    height: 30px;
    border-radius:30px;
    overflow:hidden;
    flex-shrink:0;
  }
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
<style>
  .el-table .warning-row {
    background: rgb(250, 236, 216);
  }

  .el-table .success-row {
    background: rgb(225, 243, 216);
  }

  .el-table .danger-row {
    background: rgb(253, 226, 226);;
  }

  .el-table .info-row {
    background: rgb(233, 233, 235);
  }
</style>