import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = ``

export function getHallList(data = {}) {
  return axios.request({
    url: `${url}/dictionaries/halls`,
    method: "get",
    params: data,
  })
}
export function getHallListById(id,data = {}) {
  return axios.request({
    url: `${url}/dictionaries/hall/${id}`,
    method: "get",
    params: data,
  })
}

export function getHallListSelect(data = {}) {
  return axios.request({
    url: `${url}/dictionaries/hall/select`,
    method: "get",
    params: data,
  })
}

export function changeHallStatus(id,data) {
  return axios.request({
    url: `${url}/dictionaries/hall/${id}`,
    method: "delete",
    params:data,
  })
}

export function addHallData(data) {
  return axios.request({
    url: `${url}/dictionaries/hall`,
    method: "post",
    data: data,
  })
}

export function editHallData(id, data) {
  return axios.request({
    url: `${url}/dictionaries/hall/${id}`,
    method: "put",
    data: data,
  })
}

export function deleteHallData(id,data={}) {
  return axios.request({
    url: `${url}/dictionaries/hall/${id}`,
    method: "delete",
    data: data,
  })
}

export function getSubscribes(data = {}) {
  return axios.request({
    url: `${url}/dictionaries/subscribes`,
    method: "get",
    params: data,
  })
}
export function getSubscribeById(id,data = {}) {
  return axios.request({
    url: `${url}/dictionaries/subscribe/${id}`,
    method: "get",
    params: data,
  })
}

export function addSubscribeData(data) {
  return axios.request({
    url: `${url}/dictionaries/subscribe`,
    method: "post",
    data: data,
  })
}

export function editSubscribeData(id, data) {
  return axios.request({
    url: `${url}/dictionaries/subscribe/${id}`,
    method: "put",
    data: data,
  })
}
export function deleteSubscribeData(id,data={}) {
  return axios.request({
    url: `${url}/dictionaries/subscribe/${id}`,
    method: "delete",
    data: data,
  })
}



