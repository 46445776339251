<template>
    <div class="box">
        <div class="content">
            <div class="title">代理商小程序推广码：</div>
            <el-image class="img" fit="contain" :src="code"></el-image>
        </div>
    </div>
</template>

<script>
let _this;
import {getCode} from "@/api/system.js";
export default{
    data(){
        return{
            code:'',
            groupID:''
        }
    },
    mounted(){
        this.groupID = this.$store.state.user.userData.group_id
        getCode({group_id:this.groupID}).then(res=>{
            if(res.code === 200){
                this.code = res.data
            }
        })
    },
    methods:{
    }
}
</script>
<style lang="scss" scoped>
.box{
    .content{
        width:80%;
        margin:50px auto;
        .title{
            font-size:24px;
        }
        .img{
            margin-top: 20px;
            margin-left: 20px;
            width:40%;
        }
    }
}
</style>
