<template>
  <section style="padding:0 5%;">
    <el-form  
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-form-item label="行善名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入行善名称"></el-input>
      </el-form-item>
      <el-form-item label="请选择寺庙" prop="temple_id" required>
        <el-select v-model="form.temple_id" placeholder="请选择寺庙" filterable clearable style="margin-left:10px;margin-top:10px;">
          <!-- <el-option label="请选择需搜索投放点">请选择需搜索投放点</el-option> -->
          <el-option
            v-for="item in templeList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="status" required>
        <el-radio v-model="form.status" :label="0">下架</el-radio>
        <el-radio v-model="form.status" :label="1">上架</el-radio>
      </el-form-item>
      <el-form-item label="价格：" prop="prices" >
        <el-checkbox-group v-model="form.prices">
          <el-checkbox-button v-for="(value,index) in priceList" :label="value" :key="index">{{value}}</el-checkbox-button>
        </el-checkbox-group>
        <el-button icon="el-icon-plus" type="primary" plain style="margin-top: 10px;" @click="handleOpenAdd">添加</el-button>
      </el-form-item>
      <el-form-item label="介绍" prop="intro">
        <el-input v-model="form.intro" placeholder="请输入介绍"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  import {addOther} from "@/api/order.js";
  import { getTemples} from "@/api/public.js";
  export default {
    data() {
      let that = this;
      return {
        form: {
          name: "",
          status: 0,
          temple_id:'',
          intro:"",
          prices:[],
        },
        templeList:[],
        priceList:[],
      }
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
      // this.form.group_id = this.$store.state.user.userData.group_id
    },

    methods: {
      // 初始化数据
      init() {
        getTemples().then(res=>{
          if(res.code === 200){
            console.log(res.data)
            this.templeList = res.data
          }
        })
      },
      handleOpenAdd(){
        // this.priceShow = true
        this.$prompt('请添加新的价格', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          console.log(parseInt(value))
          this.form.prices.push(parseInt(value))
          this.priceList.push(parseInt(value))
          console.log(this.form.prices)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });       
        });
      },
      close() {
        _this.$emit("close");
      },
      submit(form) {
        console.log(this.form)
        this.form.prices = JSON.stringify(this.form.prices)
        this.$refs[form].validate(valid => {
          if (valid) {
            addOther(_this.form).then(res => {
              if (res.code === 200) {
                setTimeout(() => {
                  _this.$emit("success");
                  _this.$emit("close");
                }, 1000);
              }
            })
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .editor-box{
      width: 800px;    // 配置编辑器宽度
      height: auto;
      margin: 0 40px 20px 0;
      border: 1px solid #dddddd;  // 编辑器外边框
  }
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }
</style>
